import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import ServiceSelectField from "./ServiceSelectField";


const releaseStatuses = (types) => [
    {value: false, label: types.home},
    {value: true, label: types.lab}]
;


const ResultTypeSelectField = ({labels: {label, types}, name, ...other}) => {
    return <ServiceSelectField name={name} optionmap={releaseStatuses(types)}
                               label={label} {...other}/>;
};


ResultTypeSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {
        labels:
            {
                label: labels.manageResults_labels.result_type,
                types: labels.manageResults_labels.result_types
            }
    }
};

export default translatable(mapTranslationsToProps)(ResultTypeSelectField);
