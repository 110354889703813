import {Field} from "redux-form";
import React from "react";
import PropTypes from 'prop-types';
import CheckBoxFieldComponent from "./components/CheckBoxFieldComponent";


const CheckBoxField = (props, {name, label}) => {
    return <Field name={name} component={CheckBoxFieldComponent} label={label} {...props} />;
}

CheckBoxField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
export default CheckBoxField