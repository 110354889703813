import React from 'react';
import { Link } from "react-router-dom";
import { translatable } from "react-multilingual";
import { compose } from "recompose";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { managePageStyles } from "../styles";

const styles = () => ({
  button: {
    textTransform: 'none',
  }
});

const Support = ({ classes, labels }) => {
  return (
    <Grid container className={classes.header} spacing={16}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          {labels.page_title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant={"outlined"}
          color={"primary"}
          component={Link}
          to={"/get-support/faq"}
          className={classes.button}
        >
          {labels.faq}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant={"outlined"}
          color={"primary"}
          component={Link}
          onClick={e => {
            e.preventDefault();
            window.location.href = 'mailto:calprosupport@svarlifescience.com';
          }}
          to={"#"}
          className={classes.button}
        >
          {labels.email}
        </Button>
      </Grid>
    </Grid>
  )
};

const mapTranslationsToProps = ({labels}) => ({ labels: labels.support });

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(
        (theme) => ({
            ...managePageStyles(theme),
            ...styles(theme),
        }),
        {withTheme: true},
    )
)(Support);
