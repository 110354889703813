import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = (props) => (
    <div>
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            onChange={response => props.input.onChange(response)}
        />
    </div>
);

export default Captcha;