import PropTypes from "prop-types";
import React from "react";
import {reduxForm} from "redux-form";
import Grid from "@material-ui/core/Grid/Grid";
import LanguageSelectField from "../../common/fields/selectFields/LanguageSelectField";
import {required} from "../../common/fieldValidators";
import Button from "@material-ui/core/Button/Button";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import {DOCUMENTS_TRANSLATION_SERVICE} from "../../common/services/availableServices";
import {translatable} from "react-multilingual";
import toastr from "toastr";


const styles = theme => ({
    root: {
        display: 'flex',
        minWidth: '60vh'
    },
    container: {
        width: '100%'
    },
    inputField: {
        width: '100%'
    },
    submitRow: {
        marginTop: 20
    }
});


const AddNewTranslationView = ({handleSubmit, classes, onCancel, onSuccess, documentVersion, translations, labels}) => {


    const submitHandler = (values) => {

        const translationForm = {
            ...values,
            versionId: documentVersion.id
        };


        DOCUMENTS_TRANSLATION_SERVICE.post(translationForm).then(response => {
            toastr.success(labels.success);
            onSuccess(response.data);
        }).catch(error => {
            console.log(error);
        });


    };


    return (
        <div className={classes.root}>
            <form noValidate autoComplete="off" className={classes.container}
                  onSubmit={handleSubmit(submitHandler.bind(this))}>
                <Grid container spacing={16} direction="column">

                    <Grid item md={12}>
                        <Typography variant="h5" color="primary">
                            {labels.title}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <LanguageSelectField name={'languageCode'}
                                             removeAll={translations}
                                             required
                                             className={classes.inputField}
                                             validate={[required]}/>
                    </Grid>


                    <Grid container spacing={16} justify="flex-end" className={classes.submitRow}>
                        <Grid item>
                            <Button onClick={onCancel}>{labels.cancel}</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color={'primary'} type={'submit'}>{labels.save}</Button>
                        </Grid>
                    </Grid>

                </Grid>


            </form>
        </div>

    )
};


AddNewTranslationView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    documentVersion: PropTypes.any.isRequired,
    translations: PropTypes.array,
    labels: PropTypes.any
};


const mapTranslationsToProps = (translations) => {
    return {labels: translations.labels.documents_section.add_translation};
};

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
    reduxForm({
        form: 'documentLanguageForm',
    })
)(AddNewTranslationView);

