import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import SelectField from "./SelectField";


const releaseStatuses = ["QC_SAMPLING", "QC_REVIEW", "QC_APPROVED", "RELEASED", "REJECTED"].map(item => {
    return {
        value: item,
        label: item
    }
});


const ReleaseStatusSelectField = ({label, name, ...other}) => {
    return <SelectField name={name} optionmap={releaseStatuses}
                        label={label} {...other}/>;
};



ReleaseStatusSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.manageBatches_labels.releaseStatus};
};

export default translatable(mapTranslationsToProps)(ReleaseStatusSelectField);