import Button from "@material-ui/core/Button/Button";
import toastr from "toastr";
import React from "react";
import PropTypes from 'prop-types';
import LoadingIndicator from "./LoadingIndicator";
import FileDownloaderService from "../services/FileDownloaderService";
import {constructErrorMessage} from "../FormUtils";
import PdfExportResultService from "../services/PdfExportResultService";

class ExportPdfButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        const {label, service, criteria, patientId} = this.props;

        const namedExportService = this.exportResultService(service, criteria, patientId, label);
        return <React.Fragment>

            {this.state.loading && <LoadingIndicator/>}
            <Button
                disabled={this.state.loading}
                variant="outlined"
                color="primary"
                onClick={namedExportService}
            >
                {label}
            </Button>
        </React.Fragment>
    }


    exportResultService = (service, criteria, patientId, label) => () => {
        this.setState({loading: true});

        service.getBlobWithCustomParams(criteria, patientId).then((response) => {
            FileDownloaderService.handleBlobResponse(response)
        }).catch(error => {
            console.error(error);
            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't export data.", error));
        }).finally(() => {
            this.setState({loading: false});
        });
    };

}


ExportPdfButton.propTypes = {
    criteria: PropTypes.any,
    service: PropTypes.instanceOf(PdfExportResultService),
    label: PropTypes.any
};

export default ExportPdfButton