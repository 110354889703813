import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import {COUNTRY_SERVICE} from '../../services/availableServices';
import ServiceSelectField from "../selectFields/ServiceSelectField";


const customMapperHandler = (values) => {
    return values.map(item => {
        return {
            value: item.code,
            label: item.name
        }
    });

};

const CountrySelectField = ({label, name, ...other}) => {
    return <ServiceSelectField name={name} service={COUNTRY_SERVICE} customMapper={customMapperHandler}
                               label={label} {...other}/>;
};


CountrySelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.select_field.country};
};

export default translatable(mapTranslationsToProps)(CountrySelectField);