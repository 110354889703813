import React from 'react'
import {Route} from 'react-router-dom'
import PermissionContainer from "../Auth/PermissionContainer";
import PageNotFound from "../common/components/PageNotFound";


const ProtectedRoute = ({ path, component, allowedPermissions, allowedRoles, exceptRoles }) => {

    return (
        <PermissionContainer
            allowedPermissions={allowedPermissions}
            allowedRoles={allowedRoles}
            exceptRoles={exceptRoles}
            alternativeComponent={<PageNotFound/>}
        >
            <Route path={path} component={component}/>
        </PermissionContainer>
    )


}


export default (ProtectedRoute)