import React from 'react';
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {loginRequest, logoutFailed, logoutRequest} from "../Auth/actions";
import {authenticationService} from "../index";
import PermissionContainer from "../Auth/PermissionContainer";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import {UserLabelComponent} from "./UserLabelComponent";


const LogComponent = ({labels, user, ...other}) => {

    const {loginRequest, loginFailed, logoutRequest, logoutFailed} = other;
    const {login, log_out} = labels;

    const logInHandler = () => {
        loginRequest();
        try {
            authenticationService.login()
        } catch (e) {
            loginFailed(e)
        }
    };

    const logOutHandler = () => {
        logoutRequest();
        try {
            authenticationService.logout()
        } catch (e) {
            logoutFailed(e.toString())
        }

    };

    return <React.Fragment>
        <PermissionContainer onlyForUnauthenticated={true}>
            <Button onClick={logInHandler}>
                {login}
            </Button>
        </PermissionContainer>
        <PermissionContainer onlyForAuthenticated={true}>
            <div>
                <UserLabelComponent user={user}/>
                <Button onClick={logOutHandler}>
                    {log_out}
                </Button>
            </div>
        </PermissionContainer>
    </React.Fragment>;

};


const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
};

const mapTranslationsToProps = ({labels}) => {
    return {labels: labels.log};
};
export default compose(connect(mapStateToProps, {
        loginRequest,
        logoutRequest,
        logoutFailed,
    }),
    translatable(mapTranslationsToProps)
)(LogComponent)


