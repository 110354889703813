import React from 'react';
import LoadingIndicator from "../common/components/LoadingIndicator";
import { authenticationService } from "../index";
import { connect } from "react-redux";
import { loginFailed, loginSuccess } from "./actions";
import history from "../history";
import AuthorizationService from "./AuthorizationService";
import toastr from "toastr";
import { Redirect } from "react-router-dom";

class LoginCallbackHandlerContainer extends React.Component {


    constructor(props) {
        super(props);
        this.state = {running: true};
        this.authenticationFailedHandler = this.authenticationFailedHandler.bind(this);
        this.authenticationSuccessHandler = this.authenticationSuccessHandler.bind(this)
    }


    async authenticationSuccessHandler(authenticationResult) {

        const authorizationService = new AuthorizationService();
        try {
            let user = await authorizationService.authorize(authenticationResult.accessToken);
            this.props.loginSuccess(user, authenticationResult);
        } catch (error) {
            this.authenticationFailedHandler(error)
        }

        this.setState({running: false});
    }

    authenticationFailedHandler(error) {
        console.error("Authentication failed: ", error);
        this.props.loginFailed("Authentication failed");
        toastr.error("Login failed");
      this.setState({ running : false });
    }


    componentDidMount() {
        if (this.props.isAuthenticated) {
            return history.goForward()
        }

        authenticationService.processAuthentication(this.authenticationSuccessHandler, this.authenticationFailedHandler);

    }

    render() {

        return (
            <div>
                <LoadingIndicator/>
                {
                    !this.state.running && <Redirect to="/accept_form"/>
                }
            </div>
        );
    }
}

function mapStateToProps({auth}) {

    const {isAuthenticated} = auth;

    return {isAuthenticated}

}

export default connect(mapStateToProps, {
    loginSuccess,
    loginFailed
})(LoginCallbackHandlerContainer)