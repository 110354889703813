import "babel-polyfill"
import React from 'react';
import ReactDOM, { render, unmountComponentAtNode } from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {unregister} from './registerServiceWorker';
import reducer from './reducers';
import './index.css';
import thunk from 'redux-thunk';
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";
import AuthenticationService from "./Auth/AuthenticationService";
import {persistReducer, persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import LoadingIndicator from "./common/components/LoadingIndicator";
import storage from 'redux-persist/lib/storage'
import BrowserRouter from "react-router-dom/BrowserRouter";
import {styles, withTheme} from "./styles"
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import OuterRouting from "./routing/OuterRouting";
import SimpleDialog from "./common/components/SimpleDialog";


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'locale'],
};


const persistedReducer = persistReducer(persistConfig, reducer);

const parent = document.createElement('div');
parent.id = 'react-promise-helper';
document.body.appendChild(parent);
const ThemedSimpleDialog = withTheme(SimpleDialog);

const renderReactPromise = (renderContent) => {
    const helperNode = document.createElement('div');
    parent.appendChild(helperNode);
    return new Promise((resolve, reject) => {
        render(renderContent(resolve, reject), helperNode);
    }).finally(() => {
        unmountComponentAtNode(helperNode);
        parent.removeChild(helperNode);
    });
};

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const authenticationService = new AuthenticationService();
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<LoadingIndicator/>} persistor={persistStore(store)}>
            <MuiThemeProvider theme={styles}>
                <BrowserRouter
                    getUserConfirmation={(message, callback) => {
                        renderReactPromise((resolve, reject) => (
                            <ThemedSimpleDialog
                                title={message}
                                resolve={resolve}
                                reject={reject}
                                confirmText="Yes, I want to leave"
                                closeText="No, I want to publish changes"
                            />
                        )).then(() => {
                            callback(true);
                        }, () => {
                            callback(false);
                        });
                    }}
                >
                    <OuterRouting/>
                </BrowserRouter>
                </MuiThemeProvider>
        </PersistGate>
    </Provider>
    , document.getElementById('root'));
unregister();
