import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";


const TranslationSelectField = (props) => {
    let {label, translations, selectedTranslation, handleChange} = props;

    var selected = selectedTranslation === null ? '' : selectedTranslation

    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select
                value={selected}
                onChange={handleChange}
            >
                <MenuItem value={null}>NONE</MenuItem>
                {renderItems(translations)}

            </Select>
        </FormControl>)
};


function renderItems(items) {
    return items.map((key) => {
            return <MenuItem key={key.documentRepresentationId} value={key}>{key.languageName}</MenuItem>
        }
    );
}


TranslationSelectField.propTypes = {
    translations: PropTypes.array.isRequired,
    selectedTranslation: PropTypes.object,
    handleChange: PropTypes.func

};


const mapTranslationsToProps = (translations) => {
    return {label: translations.labels.select_field.language};
};
export default translatable(mapTranslationsToProps)(TranslationSelectField);