import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import { translatable } from 'react-multilingual';
import { compose } from 'recompose';
import ClassificationComponent from "../common/ClassificationComponent";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import toastr from "toastr";
import {
    PDF_EXPORT_RESULT_SERVICE,
    RESULT_SERVICE
} from "../../common/services/availableServices";
import HistoryGraph from "../common/Graphs/HistoryGraph";
import Utils from "../../common/Utils";
import { permission } from '../../Auth/permissions';
import PermissionContainer from '../../Auth/PermissionContainer';
import ExportPdfButton from "../../common/components/ExportPdfButton";

const styles = theme => ({
    root: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30,
       
    },
});

class PatientResultBasicView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            criteriaSpecificResult: {
                archived: false,
                specificResultId: this.props.resultId
            },
            criteriaAllResults: {
                archived: false
            },
        };

    }

    componentDidMount() {
        this.fetchResult(this.props.resultId);
    }


    fetchResult(id) {
        RESULT_SERVICE.get(id).then(response => {
            this.setState({
                result: response.data,
                loading: false
            });
        }).catch(error => {
            toastr.error(error.response.data.message)
        })
    }

    deleteResult(id) {
        RESULT_SERVICE.delete(id).then(() => {
          this.props.onSuccessButtonClick();
        }).catch(error => {
          toastr.error(error.response.data.message)
        })
    }

    render() {
        const {classes, labels, onCloseButtonClick} = this.props;
        const buttonLabels = labels.buttons;
        const specLabels = labels.result_basic_view;
        const {result, loading} = this.state;

        if (loading)
            return (<LoadingIndicator/>)

        return (
            <React.Fragment>
                <Grid container className={classes.root}>
                    <Grid container item xs={5}>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.concentration}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="h6" paragraph={true}>
                                {result.finalConcentration} mg/kg
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.classification}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <ClassificationComponent classification={result.overallResult}/>
                        </Grid>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.patient}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {result.patientInfo}
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.patientId}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {result.externalPatientId}
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.date}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {Utils.dateRenderer(result.date)}
                            </Typography>
                        </Grid>
                      <PermissionContainer
                        allowedPermissions={[ permission.patientResult_delete_clinic,
                                              permission.patientResult_delete_all ]}>
                        <Grid container>
                          <Grid item xs={4}>
                          </Grid>
                          <Grid item xs={8}>
                            <Button variant="contained"
                                    color="secondary"
                                    onClick={() => this.deleteResult(this.props.resultId)}>
                              {buttonLabels.delete}
                            </Button>
                          </Grid>
                        </Grid>
                      </PermissionContainer>

                      <PermissionContainer
                        allowedPermissions={[ permission.patientResult_view_all,
                                              permission.patientResult_view_clinic,
                                              permission.patientResult_view_your ]}>
                        <Grid container  style={{paddingTop: '16px'}}>
                          <Grid item xs={4}>
                          </Grid>
                          <Grid item xs={8} >
                              <ExportPdfButton service={PDF_EXPORT_RESULT_SERVICE}
                                               criteria={ this.state.criteriaSpecificResult }
                                               patientId={result.patientId}
                                               label={buttonLabels.export_this_result_to_pdf}/>
                          </Grid>
                        </Grid>
                          <Grid container  style={{paddingTop: '16px'}}>
                              <Grid item xs={4}>
                              </Grid>
                              <Grid item xs={8} >
                                  <ExportPdfButton service={PDF_EXPORT_RESULT_SERVICE}
                                                   criteria={ this.state.criteriaAllResults }
                                                   patientId={result.patientId}
                                                   label={buttonLabels.export_all_results_to_pdf}/>
                              </Grid>
                          </Grid>
                      </PermissionContainer>
                    </Grid>
                    <Grid container item xs={7}>

                        <HistoryGraph patientId={result.patientId} resultId={result.id}/>

                    </Grid>
                </Grid>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    padding: 16
                }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCloseButtonClick}
                    >
                        {buttonLabels.close}
                    </Button>

                </div>
            </React.Fragment>
        )
    }
}


const mapTranslationsToProps = ({labels}) => ({labels});
export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(PatientResultBasicView);