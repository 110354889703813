import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import {columnType} from "./columnType";


const TableBodyCell = ({type, children, colSpan}) =>

    <TableCell numeric={type === columnType.numeric} colSpan={colSpan} padding={'checkbox'}>
        {(Array.isArray(children) && children.join(', ')) ||
        (typeof(children) === typeof(true) && children.toString()) ||
        children}
    </TableCell>;

export default TableBodyCell




