import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {translatable} from 'react-multilingual';
import {compose} from 'recompose';
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import PendingResultBasicView from "./PendingResultBasicView";
import DetailedResultView from "../common/DetailedResult/DetailedResultView";

const styles = theme => ({

    paper: {
        marginBottom: 30
    },

});

class PendingResultView extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,

        };

    }

    handleChange = (event, value) => {
        this.setState({selectedTab: value});
    };


    render() {
        const {classes, labels, resultId, onClose,} = this.props;
        const specLabels = labels.result_view;
        const {selectedTab} = this.state;


        return (
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                <Paper square className={classes.paper}>
                    <Tabs value={this.state.selectedTab}
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={this.handleChange}>
                        <Tab label={specLabels.result}/>
                        <Tab label={specLabels.result_details}/>

                    </Tabs>
                </Paper>
                {selectedTab === 0 &&
                <div style={{overflow: 'hidden'}}>
                    <PendingResultBasicView resultId={resultId} onClose={onClose}/>
                </div>}
                {selectedTab === 1 &&
                <DetailedResultView resultId={resultId} onCloseButtonClick={onClose}/>
                }
            </div>
        )
    }
}


const mapTranslationsToProps = ({labels}) => ({labels});
export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(PendingResultView);