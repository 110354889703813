export default {
    labels: {
        log: {
            login: 'Log in',
            log_out: 'Log Out',
        },
        landing_page: {
            login: 'Log in',
            register_clinic: 'Register new clinic',
            forget_password: 'Forgot your password?',
            see_api_docs: 'See Api documentation',

        },
        required: 'Required',
        delete_user: 'Delete User',
        employee: 'Employee',
        delete_success: 'Deleted successfully',
        empty_result: 'No results for the selected search criteria',
        page_not_found: 'Page not found',
        welcome_message: 'Welcome to CalproSmart portal',
        roles: {
            ADMINISTRATOR: 'ADMINISTRATOR',
            API_USER: 'API USER',
            CLINIC_ADMINISTRATOR: 'CLINIC ADMINISTRATOR',
            CLINIC_LAB: 'CLINIC LAB',
            CLINIC_USER: 'CLINIC USER',
            CLINIC_USER_FULL_INSIGHT: 'CLINIC USER - FULL INSIGHT',
            ORGANIZATION_ADMINISTRATOR: 'ORGANIZATION ADMINISTRATOR',
            QUALITY_CONTROL: 'QUALITY CONTROL'
        },
        select_field: {
            language: 'Language',
            clinic: 'Clinic',
            country: 'Country',
            physician: 'Treating physician',
            frequency_type: 'Frequency type',
            organization: 'Organization',
            no_option: 'Not found'
        },
        buttons: {
            create: 'Create',
            cancel: 'Cancel',
            next: 'Next',
            back: 'Back',
            finish: 'Finish',
            home: 'Back to home page',
            search: 'Search',
            edit: 'Edit',
            export_results: 'Export results to Excel',
            export_filtered_results: 'Export filtered results to Excel',
            export_filtered_patients: 'Export filtered patients to Excel',
            details: 'Details',
            update: 'Update',
            delete: 'Delete',
            close: 'Close',
            send: 'Send',
            save: 'Save',
            accept: 'Accept',
            reject: 'Reject',
            reset_password: 'Reset password',
            confirm: 'Confirm',
            reset_mfa: 'Reset MFA for user',
            export_all_results_to_pdf: 'Export all results to PDF',
            export_this_result_to_pdf: 'Export this result to PDF'
        },
        fields: {
            search: 'Search',
            clinic: 'Clinic',
            status: 'Status',
            role: 'Role',
            language: 'Language'
        },
        table: {
            empty_result: 'No results for the selected search criteria',
            error_message: 'An error occurred. Can\'t display data'
        },
        statuses: {
            active: 'Active',
            inactive: 'Inactive',
            created: 'Created'
        },
        menu_labels: {
            clinics: 'Clinics',
            organizations: 'Organisations',
            staff_users: 'Staff Users',
            patients: 'Patients',
            manage_patients: 'Manage Patients',
            results: 'Results',
            pending_results: 'Pending Results',
            batches: 'Batches',
            documents: 'Documents',
            faq: 'FAQ management',
            support: 'Get support',
        },
        reset_password: {
            title: 'Reset your password',
            message: 'Forgotten your password? Enter your e-mail address below, and we\'ll e-mail instructions for setting a new one:',
            success_title: 'Success',
            error_message: 'We are experiencing an internal server error. Please contact the administrator.',
            success: 'If the email you provided is correct, the password reset link has been sent to your email.\nPlease check your inbox.'
        },
        managePatients_labels: {
            create_patient_button: 'Create patient',
            page_title: 'Manage patients',
            patient_type: 'Patient type',
            patient_types: {
                home: 'Home monitoring patient',
                lab: 'Lab patient'
            },
            columns: {
                id: 'Calpro Id',
                externalId: 'Id',
                email: 'Email',
                firstName: 'First name',
                lastName: 'Last name',
                treatmentType: 'Patient type',
                created: 'Created',
                active: 'Status',
                clinicName: 'Clinic',
                details: '',
            },
        },
        manageClinics_labels: {
            create_clinic_button: 'Create clinic',
            page_title: 'Manage clinics',
            columns: {
                name: 'Name',
                city: 'City',
                countryCode: 'Country',
                status: 'Status',
                organizationName: 'Organization',
                created: 'Created',
                details: '',
            },
        },
        manageOrganizations_labels: {
            create_organization_button: 'Create organization',
            page_title: 'Manage organizations',
            columns: {
                name: 'Name',
                moderateThreshold: 'Moderate threshold',
                severeThreshold: 'Severe threshold',
                active: 'Status',
            },
        },
        manageBatches_labels: {
            create_batch_button: 'Create batch',
            page_title: 'Manage batches',
            releaseStatus: 'Release status',
            columns: {
                number: 'Batch no',
                expirationDate: 'Expiry date',
                creationDate: 'Created',
                status: 'Release status',
                details: '',
            },
        },
        manageResults_labels: {
            page_title: 'Manage results',
            classification: 'Classification',
            result_type: 'Result type',
            pendingResultLabel: 'Pending Results',
            pendingResultTooltip: '"Accepted" is an accepted Pending Result "Rejected" is a rejected Pending Result',
            pendingResultTypes: {
                accepted: 'Accepted',
                rejected: 'Rejected'
            },
            result_types: {
                home: 'Home',
                lab: 'Lab'
            },
            columns: {
                date: 'Date',
                externalPatientId: 'Patient Id',
                patientInfo: 'Patient',
                clinicName: 'Clinic',
                batchNumber: 'Batch',
                finalConcentration: 'Concentration',
                overallResult: 'Classification',
                archived: 'Archived',
                labResult: 'Result type',
                details: ''
            },
        },
        managePendingResults_labels: {
            page_title: 'Manage pending results',
            classification: 'Classification',
            columns: {
                date: 'Date',
                externalPatientId: 'Patient Id',
                patientInfo: 'Patient',
                runNo: 'Run No',
                finalConcentration: 'Concentration',
                overallResult: 'Classification',
                actions: 'Actions'
            },
        },
        signup_labels: {
            user_tab: 'Personal information',
            clinic_tab: 'Clinic information',
            summary_tab: 'Summary',
            first_name: 'First name',
            last_name: 'Last name',
            email: 'Email',
            password: 'Password',
            confirm_password: 'Confirm password',
            company: 'Company',
            address1: 'Address line 1',
            address2: 'Address line 2',
            city: 'City',
            postcode: 'Postcode',
            country: 'Country',
            contact_name: 'Contact name',
            contact_email: 'Contact email',
            contact_tel: 'Telephone number',
            captcha: 'Captcha',
            provide_contact_info: 'Contact person for the clinic',
            provide_contact_info_subtitle: 'This person will serve as a Clinic Administrator.',
            provide_clinic_info: 'Provide information about the clinic',
            confirm_info: 'Verify that the given information is correct',
            done: 'Thank you for registering your clinic. A Calpro Administrator will review your registration and activate your account and clinic within 24 hours. You will receive an email when your account and clinic is ready to be used.',
            error_message: 'There was a problem creating your account - please contact support@calpro.no'
        },
        patient_details: {
            page_title: 'Manage Patient',
            first_name: 'First name',
            last_name: 'Last name',
            address1: 'Address line 1',
            address2: 'Address line 2',
            postcode: 'Postcode',
            city: 'City',
            country: 'Country',
            email: 'Email address',
            phone1: 'Primary phone',
            phone2: 'Secondary phone',
            patient_id: 'Patient ID',
            sex: 'Sex',
            date_of_birth: 'Date of birth',
            clinic: 'Clinic',
            physician: 'Treating physician',
            frequency: 'Frequency',
            frequency_type: 'Frequency type',
            language: 'Language',
            home_monitoring: 'Patient active for home monitoring',
            interval_day: 'day(s)',
            interval_week: 'week(s)',
            interval_month: 'month(s)',
            interval_year: 'year(s)',
            moderate_threshold: 'Moderate threshold',
            high_threshold: 'High threshold',
            sex_male: 'male',
            sex_female: 'female',
            thirdPartyConsent: 'Consent to third party data sharing',
            is_active: 'Is active',
            patientCreated: 'Patient created',
            patientUpdated: 'Patient updated',
            mfa: 'Multi factor authentication (MFA)',
            test_frequency_every: 'Test frequency every:',
            help_message: 'Indicates how often the patient should perform a test'
        },
        staff_users: {
            page_title: 'Manage staff users',
            id: 'Calpro Id',
            email: 'Email',
            firstName: 'First name',
            lastName: 'Last name',
            active: 'Active',
            edit: 'Edit',
            details: 'Details',
            primaryPhone: 'Primary phone',
            secondaryPhone: 'Secondary phone',
            roles: 'Role',
            clinicName: 'Clinic',
            create: 'Create staff user'
        },
        user_details: {
            page_title: 'Create staff user',
            first_name: 'First Name',
            last_name: 'Last Name',
            address1: 'Address line 1',
            address2: 'Address line 2',
            postcode: 'Postcode',
            city: 'City',
            email: 'Email address',
            phone1: 'Primary phone',
            phone2: 'Secondary phone',
            language: 'Language',
            clinic: 'Clinic',
            is_active: 'Is active',
            calpro_admin: 'Calpro_Admin',
            calpro_qc: 'IsCalpro_Qc',
            clinic_admin: 'Clinic_Admin',
            clinic_lab: 'Clinic_Lab',
            clinic_user: 'Clinic_User',
            clinic_user_all_results: 'Clinic_User_All_Results',
            roles: 'Roles',
            no_roles: 'No roles selected, please select at least one role',
            success: 'User created successfully',
            employeeCreated: 'Employee created',
            employeeUpdated: 'Employee updated',
            warning_title: 'Warning',
            deactivation_warning: 'You have deactivated a staff user. If they had any patients, remember to reallocate them!',
            mfa: 'Multi factor authentication (MFA)',
        },
        clinic_details: {
            page_title: 'Create or edit clinic',
            name: 'Clinic name',
            address1: 'Address line 1',
            address2: 'Address line 2',
            postcode: 'Postcode',
            city: 'City',
            contactName: 'Contact name',
            contactTel: 'Contact phone',
            contactEmail: 'Contact email',
            status: 'Status',
            update_ok: 'Clinic updated successfully',
            clinicCreated: 'Congratulations, you have successfully created new clinic! Go to the Manage Users tab to assign a new administrator to it.',
            clinicUpdated: 'Clinic updated',
            mfa_patient: 'Patient Multi factor authentication (MFA)',
            mfa_staff: 'Staff User Multi factor authentication (MFA)',
        },
        organization_details: {
            page_title: 'Organization details',
            name: 'Name',
            logo: 'Logo',
            moderateThreshold: 'Moderate threshold',
            severeThreshold: 'Severe threshold',
            update_success: 'Organization updated successfully',
            create_success: 'Organization created successfully',
            logo_placeholder: 'Insert your logo here',
            thirdPartyConsentRequired: 'Requires patients to consent to third party data sharing',
            fullPatientInformationRequired: 'Requires full patient information (name, address, etc.)',
            phoneRegexp: 'Phone number regex',
            organizationCreated: 'Organization created',
            organizationUpdated: 'Organization updated',
            active: 'Active',
            deactivate_organization: {
                title: 'Deactivate clinic',
                message: "By pressing 'Confirm', you are deactivating all clinics, users and patients affiliated to this organization. If you want to keep these active, you need to affiliate these clinics to an active organization before deactivating this specific organization.",
                cancel: 'Cancel',
                confirm: 'Confirm'
            }
        },
        result_basic_view: {
            concentration: 'Concentration',
            classification:
                'Classification',
            patient:
                'Patient',
            patientId:
                'Patient Id',
            date:
                'Date'
        },
        pending_result_basic_view: {
            concentration: 'Concentration',
            classification:
                'Classification',
            patient:
                'Patient',
            patientId:
                'Patient Id',
            date:
                'Date',
            searchPatientPlaceholder:
                'Search patient by name, id or email',
            patientReassignmentSuccess:
                'The patient was reassigned',
            patientReassignmentFail:
                'Patient reassignment fail',
        },
        detailed_result_view: {
            phoneBrand: 'Phone brand',
            phoneModel: 'Phone model',
            phoneName: 'Phone name',
            appVersion: 'App version',
            readerVersion: 'Reader version',
            batchNumber: 'Batch number',
            batchExpDate: 'Batch exp date',
            cScore: 'C Score',
            tScore: 'T Score',
            tcRatio: 'T/C Ratio',
            cPeakPosition: 'C peak position',
            tPeakPosition: 'T peak position',
            cThresh: 'C Threshold',
            notes: 'Notes',
            image: 'Image',
            stripImage: 'Strip image',
            updateResultNotes: 'Update result notes',
            notesCharacterLimit: 'Limit 255 characters',
            notesUpdatedSuccessfully: 'Notes updated successfully',
            notesUpdateFail: 'Notes update fail',
        },
        result_view: {
            result: 'Result',
            result_details: 'Result details',
        },
        create_batch: {
            create_batch: 'Create batch',
            batch_details: 'Batch details',
            batch_number: 'Batch number',
            manufacture_date: 'Manufactured date',
            expiry_date: 'Expiry date',
            moderate_threshold: 'Moderate threshold',
            severe_threshold: 'Severe threshold',
            created_by: 'Created by',
            release_status: 'Release status',
            barcode: 'Barcode',
            calibration_equation: 'Calibration parameter equation',
            calibration_parameter_a: 'Calibration parameter A',
            calibration_parameter_b: 'Calibration parameter B',
            calibration_parameter_c: 'Calibration parameter C',
            qc_review: 'QC review',
            qc_approved: 'QC approved',
            released: 'Released',
            reject_batch: 'Reject batch',
            reject_batch_line1: 'This will reject the batch and alert Calpro QC.',
            reject_batch_line2: 'Before rejecting this batch you need to add a note to indicate the reason why.',
            reject_batch_line3: 'If you do not wish to continue, click on \'Cancel\' or click on \'Confirm\' to reject the batch.',
            reason_label: 'Reason for Rejecting',
            reject: 'Reject',
            batch_created: 'Batch created',
            batch_updated: 'Batch updated',
            batch_failed: 'Batch Operation failed',
        },
        batchResults_labels: {
            sampling_page_title: 'Batch sampling results list',
            review_page_title: 'Batch review results list',
            columns: {
                resultId: 'ID',
                date: 'Date',
                sampleId: 'Sample ID',
                runNo: 'Run No',
                tpeakPosition: 'T peak position',
                cpeakPosition: 'C peak position',
                finalScore: 'Final score',
                tscore: 'T score',
                cscore: 'C score',
                tcRatio: 'TC Ratio',
                finalConcentration: 'Concentration',
                overallResult: 'Classification',
                details: 'Details'
            }
        },
        myPage_labels: {
            page_title: 'My page',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            clinicName: 'Clinic',
            roles: 'Roles',
            view_agreements: 'View agreements you have signed',
            apiVersion: 'Api version',
            releaseNotes: 'Release notes'
        },
        myAgreements_labels: {
            title: 'My agreements',
            version: 'version'
        },
        active: {
            active: 'Active',
            inactive: 'Inactive'
        },
        documents_section: {
            documents: {
                publish: "Publish version",
                cannot_publish: "All translation must be finished",
            },
            versions: {
                title: 'Versions',
                add_tooltip: "Add new version",
                publish: 'Publish',
                release_date: 'Release date',
                creation_date: 'Creation date'
            },
            translations: {
                title: 'Translations',
                add_tooltip: 'Add new Translation'
            },
            document_representation: {
                edit: 'Edit document'
            },
            edit_document_representation: {
                finish: 'Finished',
                in_progress: 'Save as draft',
                cancel: 'cancel',
            },
            add_version: {
                title: 'Add new version',
                sub_label: 'Version name',
                save: 'Save',
                cancel: 'cancel',
                success: 'Successfully added new version'
            },
            add_translation: {
                title: 'Add new translation',
                sub_label: 'Language name',
                save: 'Save',
                cancel: 'cancel',
                success: 'Successfully added new translation'
            },
            tooltips: {
                none: 'The same as in previous version',
                finish: 'Translation finished, ready for publishing',
                in_progress: 'Translation was updated, but is not ready for publishing',
            },
            publish: {
                title: 'Publish new document version',
                notify: 'Notify all users about the new version?',
                save: 'Save',
                cancel: 'cancel'
            }
        },
        support: {
            page_title: 'Get support',
            faq: 'FAQ',
            email: 'Email support',
        },
        support_faq: {
            page_title: 'FAQ',
        },
        faq: {
            page_title: 'FAQ management',
            edit: 'Edit',
            publish: 'Publish',
            addNew: 'Add new',
            selectOptions: {
                PATIENT: 'Patient',
                EMPLOYEE: 'Employee',
            }
        },
        faq_item: {
            show_more: 'Show more',
        },
        add_edit_faq: {
            required_translation: 'English translation is required',
            lack_of_answers: 'Some questions will not be saved due to lack of answers',
            add_title: 'Add new question and answer',
            edit_title: 'Edit question and answer',
            save: 'Save',
            delete: 'Delete',
            question: {
                placeholder: 'Type in the question...',
            },
            answer: {
                placeholder: 'Type in the answer...',
            },
            photo: {
                add: 'Add photo',
                added_suffix: 'was added as an attachment.',
            },
            video: {
                label: 'Link to the video (optional):',
                placeholder: 'Paste a link to the video',
            },
            language: {
                EN: 'English',
                AR: 'Arabic',
                CA: 'Catalan',
                CS: 'Czech',
                DE: 'German',
                EL: 'Greek',
                ES: 'Spanish',
                FI: 'Finnish',
                HE: 'Hebrew',
                HU: 'Hungarian',
                IS: 'Icelandic',
                IT: 'Italian',
                KO: 'Korean',
                NL: 'Dutch',
                SK: 'Slovak',
                SV: 'Swedish',
                NB: 'Norwegian',
                DA: 'Dannish',
                PL: 'Polish',
                FR: 'French',
                PT: 'Portuguese',
                SL: 'Slovenian',
            }
        }
    }
}
