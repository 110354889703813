import RestService from "./RestService";
import UpdateResultNotesService from "./UpdateResultNotesService";
import ReassignPatientService from "./ReassignPatientService";
import ResetPasswordService from "./ResetPasswordService";
import PdfExportResultService from "./PdfExportResultService";

export const DOCUMENTS_REPRESENTATION_SERVICE = new RestService('document');
export const DOCUMENTS_VERSION_SERVICE = new RestService('documentVersion');
export const DOCUMENTS_TRANSLATION_SERVICE = new RestService('translation');

export const EMPLOYEE_SERVICE = new RestService('employee');
export const USER_SERVICE = new RestService('user');
export const EMPLOYEE_AVAILABLE_SERVICE = new RestService('employee/available');
export const PATIENT_SERVICE = new RestService('patient');
export const SIGNUP_SERVICE = new RestService('register');
export const CLINIC_SERVICE = new RestService('clinic');
export const CLINIC_SERVICE_AVAILABLE = new RestService('clinic/available');
export const RESET_PASSWORD_SERVICE = new ResetPasswordService();
export const LANGUAGES_SERVICE = new RestService('language');
export const COUNTRY_SERVICE = new RestService('country');
export const ORGANIZATION_SERVICE = new RestService('organization');
export const ORGANIZATION_SERVICE_ALL = new RestService('organization/all');
export const AVAILABLE_BATCH_SERVICE = new RestService('batch/available');
export const AGREEMENT_SERVICE = new RestService('agreement');
export const RELEVANT_AGREEMENT_SERVICE = new RestService('document/required-list');
export const DISPLAY_SERVICE = new RestService('document/display');
export const BATCH_SERVICE = new RestService('batch');
export const RESULT_SERVICE = new RestService('result');
export const EXPORT_RESULTS_SERVICE = new RestService('export/results');
export const DETAILED_RESULT_SERVICE = new RestService('detailedResult');
export const PENDING_RESULT_SERVICE = new RestService('result/pending');
export const EXPORT_PENDING_RESULT_SERVICE = new RestService('export/results/pending');
export const PATIENT_RESULT_SERVICE = new RestService('result/patient');
export const UPDATE_RESULT_NOTES_SERVICE = new UpdateResultNotesService();
export const REASSIGN_PATIENT_SERVICE = new ReassignPatientService();
export const PDF_EXPORT_RESULT_SERVICE = new PdfExportResultService();
export const EXPORT_BATCHES_SERVICE = new RestService('export/batches');
export const SIGNED_AGREEMENTS_SERVICE = new RestService('document/signed-list');
export const EXPORT_PATIENTS_SERVICE = new RestService('export/patients');
export const API_VERSION_SERVICE = new RestService('apiVersion');
export const ORGANIZATION_RULE_SERVICE = new RestService('organization/rules/user');
export const ADMIN_FAQ_QUESTIONS_SERVICE = new RestService('admin/faq');
export const FAQ_QUESTIONS_SERVICE = new RestService('faq');

