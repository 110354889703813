import React, {Component} from 'react';
import {translatable} from "react-multilingual";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {compose} from "recompose";
import Checkbox from "../../node_modules/@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "../../node_modules/@material-ui/core/FormControlLabel/FormControlLabel";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";

const styles = () => ({
    acceptButton: {
        paddingBottom: "20px",
    }
});

class AgreementDetails extends Component {
    render() {
        const {title, version, editable, display, checkedBoxes, classes} = this.props;

        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {title} {version}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{overflow: 'auto', height: '80vh'}}>
                    {[...display].map((elem, index) => this.displayChunk(elem, index))}
                </Grid>
                <Grid container item xs={12} justify="flex-end" className={classes.acceptButton}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={this.props.handleSubmit}
                        disabled={editable && checkedBoxes.includes(false)}
                    >
                        {editable ?
                            this.props.labels.buttons.accept :
                            this.props.labels.buttons.close}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    /**
     * Only display checkboxes when they have a specific title, otherwise
     * this is either an invalid checkbox added by the user, or an extra checkbox
     * added by HtmlConverter.
     */
    displayChunk = (chunk, key) => {
        const {checkedBoxes, editable} = this.props;
        const checkboxHasTitle = !!chunk.title;
        const checked = checkedBoxes[key];
        return (
            <div key={key}>
                <Typography
                    align={'justify'}
                    dangerouslySetInnerHTML={{__html: chunk['content']}}
                />
                {checkboxHasTitle &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!editable || checked}
                                onChange={() => {
                                    checkedBoxes[key] = !checkedBoxes[key];
                                    this.setState({checkedBoxes: checkedBoxes})
                                }}
                            />
                        }
                        label={chunk['title']}
                    />
                }
            </div>
        );
    };
}

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(AgreementDetails);