import PropTypes from "prop-types";
import React from "react";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import MenuList from "@material-ui/core/MenuList/MenuList";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Divider from "@material-ui/core/Divider/Divider";
import {fabAddButton} from "../../common/fields/components/FabButtonComponent";
import {translatable} from "react-multilingual";
import Utils from "../../common/Utils";
import Chip from '@material-ui/core/Chip';
import Grid from "@material-ui/core/Grid/Grid";

const styles = theme => ({
    content: {
        overflow: 'auto',
        maxHeight: '25vh'
    }
});


const DocumentVersion = ({versions, classes, labels, versionSelectedHandler, selectedVersion, windowHandler}) => {


    let renderSingleItem = (version, selectedVersion) => {
        const timestamp = version.published ? version.releaseDateTime : version.creationDateTime;
        const label = version.published ? labels.release_date : labels.creation_date;
        return <div key={version.id}>
            <MenuItem selected={selectedVersion !== null && selectedVersion.id === version.id}
                      onClick={() => versionSelectedHandler(version)}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={3}>
                        <ListItemText primary={version.name}/>
                    </Grid>
                    {version.published &&
                    <Grid item xs={3}>
                        <Chip color="primary" label="published"/>
                    </Grid>
                    }
                    <Grid container item xs={5}>
                        <Grid item xd={6}>
                            {label}:
                        </Grid>
                        <Grid item xd={6}>
                            <i>{Utils.shortDateRenderer(new Date(timestamp))}</i>
                        </Grid>
                    </Grid>
                </Grid>
            </MenuItem>
            <Divider/>
        </div>;
    };

    return <React.Fragment>
        <Typography variant="h5" color={"primary"}>
            {labels.title}
        </Typography>
        <Card className={classes.content}>
            <MenuList style={{padding: 0}}>
                {versions.map(item => renderSingleItem(item, selectedVersion))}
            </MenuList>
        </Card>
        {fabAddButton(labels.add_tooltip, windowHandler)}
    </React.Fragment>;


};


DocumentVersion.propTypes = {
    versions: PropTypes.array.isRequired,
    versionSelectedHandler: PropTypes.func.isRequired,
    selectedVersion: PropTypes.object,
    newVersionAddedHandler: PropTypes.func,
    windowHandler: PropTypes.func.isRequired
};


const mapTranslationsToProps = (translations) => {
    return {labels: translations.labels.documents_section.versions};
};


export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(DocumentVersion);

