import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {reduxForm} from "redux-form";
import {PagedTableContainer} from "../common/components/PagedTable/PagedTableContainer";
import {AVAILABLE_BATCH_SERVICE, EXPORT_BATCHES_SERVICE} from "../common/services/availableServices";
import {translatable} from "react-multilingual";
import {compose} from "recompose";
import {columnType} from "../common/components/PagedTable/columnType";
import SearchTextField from "../common/fields/SearchTextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PermissionContainer from "../Auth/PermissionContainer";
import {permission} from "../Auth/permissions";
import {managePageStyles} from "../styles";
import ReleaseStatusSelectField from "../common/fields/selectFields/ReleaseStatusSelectField";
import ReleaseStatusComponent from "./ReleaseStatusComponent";
import Dialog from "@material-ui/core/Dialog/Dialog";
import BatchDetailsTabs from "./BatchDetailsTabs";
import ExportExcelButton from "../common/components/ExportExcelButton";


const styles = () => ({
    dialogPaperClass: {
        padding: 0,
        minHeight: '60vh',
        height: '85vh',
        width: '120vh'
    }


});

class ManageBatches extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            criteria: null,
            openModal: false,
            batchNumber: null,
            loadingTableData: true,
        };
        this.tableColumnSetup();

    }

    createButtonClickHandler = () => {

        this.setState({
            openModal: true,
            batchNumber: null
        });

    };

    tableColumnSetup() {

        this.columns = [
            {key: 'number', type: columnType.text},
            {key: 'expirationDate', type: columnType.text},
            {key: 'creationDate', type: columnType.text},
            {key: 'status', type: columnType.text, customCellRenderer: this.statusCellRenderer},
            {key: 'details', type: columnType.text, customCellRenderer: this.detailsCellRenderer, sortable: false}
        ];

    }

    statusCellRenderer = (rowId, columnValue) => {
        return <ReleaseStatusComponent releaseStatus={columnValue}/>;
    };

    detailsCellRenderer = (rowId) => {

        return <Button onClick={this.detailsButtonClickHandler(rowId)}>{this.props.labels.buttons.details}</Button>;
    };


    detailsButtonClickHandler = rowId => () => {

        this.setState({
            openModal: true,
            batchNumber: rowId
        });
    };
    closeWindowHandler = () => {
        this.setState({batchNumber: null, openModal: false});
    };


    searchButtonClickHandler = values => {

        let queryParams = {
            search: values.search
        };

        if ('status' in values) {
            queryParams = {
                ...queryParams,
                'status': values.status
            };
        }

        this.setState({
            criteria: queryParams,
            loadingTableData: true,
        });
    };
    successHandler = () => {
        this.setState({batchNumber: null, openModal: false, loadingTableData: true});
    };


    loadingDataFinishHandler = () => {
        this.setState({
            loadingTableData: false,
        })
    };


    render() {
        const {handleSubmit, labels, classes} = this.props;
        const specLabels = labels.manageBatches_labels;
        const {criteria, openModal, loadingTableData} = this.state;

        return (
            <div>

                <Dialog classes={{paper: classes.dialogPaperClass}}
                        maxWidth={false}
                        open={openModal}
                        onClose={this.closeWindowHandler}

                >
                    <BatchDetailsTabs
                        batchNumber={this.state.batchNumber}
                        onCancel={this.closeWindowHandler}
                        onSuccess={this.successHandler}
                    />
                </Dialog>

                <Grid container className={classes.header}>
                    <Grid item xs={4}>
                        <Typography variant="h5" color="primary">
                            {specLabels.page_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={32} alignItems="center" justify="flex-end">
                            <Grid item>
                                <ExportExcelButton service={EXPORT_BATCHES_SERVICE} criteria={this.state.criteria}/>
                            </Grid>
                            <Grid item>
                                <PermissionContainer
                                    allowedPermissions={[permission.batches_add]}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={this.createButtonClickHandler}>
                                        {specLabels.create_batch_button}
                                    </Button>
                                </PermissionContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
                    <Grid spacing={24} container className={classes.criteria}>
                        <Grid item md={4}>
                            <SearchTextField
                                name="search"
                                className={classes.criteriaField}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}> {/* align select field to the search bar */}
                                <ReleaseStatusSelectField
                                    name="status"
                                    className={classes.criteriaField}
                                />
                            </div>
                        </Grid>
                        <Grid item container md={5} alignItems="center" justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                {labels.buttons.search}
                            </Button>

                        </Grid>
                    </Grid>
                </form>
                <PagedTableContainer rowKey="number" orderBy="number" order="asc" columns={this.columns}
                                     criteria={criteria} service={AVAILABLE_BATCH_SERVICE}
                                     loadingData={loadingTableData}
                                     onLoadingDataFinish={this.loadingDataFinishHandler}
                                     errorMessage={labels.table.error_message}
                                     emptyResultMessage={labels.table.empty_result}
                                     columnLabels={specLabels.columns}
                />
            </div>
        );
    }


}


const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps),
    reduxForm({form: 'BatchSearch'}),
    withStyles(
        (theme) => ({
            ...managePageStyles(theme),
            ...styles(theme),
        }),
        {withTheme: true},
    )
)(ManageBatches);
