import React from "react";

const handleBeforeUnload = (event) => {
  event.preventDefault();
  event.returnValue = '';
  return '';
}

export const useBeforeUnload = (condition) => {
  React.useEffect(() => {
    if (!condition) {
      return;
    }
    window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }
  }, [condition]);
}