import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { EMPLOYEE_SERVICE, RESET_PASSWORD_SERVICE, USER_SERVICE } from "../common/services/availableServices";
import TextField from "../common/fields/TextField";
import CheckBoxField from "../common/fields/CheckBoxField";
import { initialize, reduxForm } from "redux-form";
import { email, phoneNumber, required } from "../common/fieldValidators";
import FormGroup from '@material-ui/core/FormGroup';
import toastr from 'toastr'
import { roles } from "../Auth/roles";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import LanguageSelectField from "../common/fields/selectFields/LanguageSelectField";
import ClinicsSelectField from "../common/fields/selectFields/ClinicsSelectField";
import CountrySelectField from "../common/fields/selectFields/CountrySelectField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import { compose } from "redux";
import { translatable } from "react-multilingual";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import FormButtons from "../common/components/FormButtons";
import { constructErrorMessage, FormMode, initializeFormMode } from "../common/FormUtils";
import LoadingIndicator from "../common/components/LoadingIndicator";
import { connect } from "react-redux";
import PermissionContainer from "../Auth/PermissionContainer";
import { permission as permissions, permission } from "../Auth/permissions";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    inputField: {
        marginRight: 100,
        padding: '0px',
        marginTop: 10,
        marginBottom: 10
    },
    roleField: {
        marginLeft: '18px',
        marginTop: '1px',
        marginBottom: '1px',
        marginRight: 100,
        padding: '0px'
    },
    buttonMargin: {
        marginRight: 32
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        marginTop: 10,
        marginBottom: 10
    },
    postcode: {
        marginTop: 10,
        marginBottom: 10
    },
    city: {
        marginLeft: 32,
        marginRight: 100,
        flexGrow: 3,
        marginTop: 10,
        marginBottom: 10
    },
    group: {
        listStyleType: 'none',
        fontSize: 30,
        verticalAlign: 'above',
    }

});


const reduxFormName = 'userDetails';

class StaffUserDetails extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            employeeId: this.props.employeeId,
            employee: null,
            formMode: initializeFormMode(this.props.employeeId),
            loading: false,
            isModified: false,
            roles: this.createRoleMapObject(),
        };

        this.editButtonClickHandler = this.editButtonClickHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.closeButtonClickHandler = this.closeButtonClickHandler.bind(this);
        this.editable = this.editable.bind(this);

    }

    clinic_administrator_roles = {
        CLINIC_LAB: 'CLINIC_LAB',
        CLINIC_USER: 'CLINIC_USER',
        CLINIC_USER_FULL_INSIGHT: 'CLINIC_USER_FULL_INSIGHT',
        CLINIC_ADMINISTRATOR: 'CLINIC_ADMINISTRATOR'
    };


    componentDidMount() {
        if (FormMode.VIEW === this.state.formMode) {
            this.fetchEmployee(this.state.employeeId);
        }
    }

    closeButtonClickHandler() {
        if (this.state.isModified) {
            this.props.onSuccess();
        }
        else {
            this.props.onCancel();
        }
    }

    cancelButtonClickHandler() {
        this.setState({
            formMode: FormMode.VIEW
        }, () => this.fetchEmployee(this.state.employeeId));
    }

    editButtonClickHandler() {
        this.setState({
            formMode: FormMode.EDIT
        })
    }

    getSelectedRolesFromState() {
        let roles = this.state.roles;
        const userRoles = this.props.user.roles;
        let selectedRoles = [];
        Object.keys(roles).forEach(key => {
            // Only send roles you can administer
            if (roles[key] === true &&
                (userRoles.includes("ADMINISTRATOR") || key in this.clinic_administrator_roles)) {
                selectedRoles.push(key)
            }
        });

        return selectedRoles;
    }

    submitFormHandler(values) {
        delete values.useMfa;
        let selectedRoles = this.getSelectedRolesFromState();

        if (selectedRoles.length === 0) {
            toastr.error(this.props.labels.user_details.no_roles);
            return;
        }

        values.roles = selectedRoles;


        if (FormMode.CREATE === this.state.formMode) {
            this.createEmployee(values);
        }
        else if (FormMode.EDIT === this.state.formMode) {
            this.updateEmployee(values, this.state.employeeId);
        }

    }

    createEmployee(values) {
        this.setState({
            loading: true,
        });
        EMPLOYEE_SERVICE.post(values).then(response => {

            this.setState({
                loading: false,
                employeeId: response.data.id,
                employee: response.data,
                isModified: true,
                formMode: FormMode.VIEW
            });

            this.props.dispatch(initialize(reduxFormName, response.data));
            this.setRoles(response.data.roles);

            toastr.success(this.props.labels.user_details.employeeCreated);

        }).catch(error => {

            this.setState({
                loading: false,
            });

            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't create employee.", error));
        })
    }

    updateEmployee(values, employeeId) {

        this.setState({
            loading: true,
        });

        EMPLOYEE_SERVICE.put(values, employeeId).then(response => {
            if (this.state.employee['active'] === true && values['active'] === false) {
                const labels = this.props.labels.user_details;
                toastr.warning(labels.deactivation_warning, labels.warning_title, {timeOut: 0, extendedTimeOut: 0});
            }

            this.setState({
                loading: false,
                isModified: true,
                employee: response.data,
                formMode: FormMode.VIEW,
            });

            this.props.dispatch(initialize(reduxFormName, response.data));
            this.setRoles(response.data.roles);

            toastr.success(this.props.labels.user_details.employeeUpdated);

        }).catch(error => {
            this.setState({
                loading: false,
            });
            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't update employee.", error));
        })
    }


    fetchEmployee(employeeId) {

        this.setState({
            loading: true,
        });

        EMPLOYEE_SERVICE.get(employeeId).then(response => {

            this.props.dispatch(initialize(reduxFormName, response.data));
            this.setState({
                employee: response.data,
                loading: false,
            });

            this.setRoles(response.data.roles);

        }).catch(error => {

            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't fetch employee.", error));
        })
    }

    editable() {

        return PermissionContainer.checkAllowedPermissions([permission.employee_edit_all, permission.employee_edit_clinic, permission.employee_edit_your], this.props.user.permissions)

    }


    resetMfaForEmployee() {
        this.setState({
            loading: true,
        });
        USER_SERVICE.putId(`${this.state.employeeId}/reset-mfa`).then(() => {
            toastr.success("Reset mfa successful!");
        }).catch(error => {
            if (!error.handledGlobally) {
                toastr.error(this.props.labels.reset_password.error_message);
            }
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    resetPassword(email) {
        RESET_PASSWORD_SERVICE.post(email).then(() => {
            toastr.success("Reset password email sent!");
        }).catch(error => {
            if (!error.handledGlobally) {
                toastr.error(this.props.labels.reset_password.error_message);
            }
        });
    }

    createRoleMapObject() {

        const filledRoles = {};
        Object.keys(roles).forEach(roleName => {
            filledRoles[roleName] = false
        });


        return filledRoles;

    }

    setRoles(employeeRoles) {
        const roleMapObject = this.createRoleMapObject();
        employeeRoles.forEach(role => {
            roleMapObject[role] = true;
        });

        this.setState({roles: roleMapObject});
    }

    toggleCheckbox = (roleName) => {
        this.setState({
            roles: {
                ...this.state.roles,
                [roleName]: !this.state.roles[roleName]
            }
        });
    };


    render() {
        const {handleSubmit, classes, labels} = this.props;
        const userLabels = labels.user_details;
        const buttonLabels = labels.buttons;
        const {formMode, loading, employee} = this.state;

        const disabled = formMode === FormMode.VIEW;

        const canDeactivateUser = PermissionContainer.checkAllowedPermissions([permission.employee_deactivate_all, permission.employee_deactivate_clinic], this.props.user.permissions);
      const canResetMfa = PermissionContainer.checkAllowedPermissions([ permissions.employee_edit_all,
                                                                        permissions.employee_edit_clinic ],
                                                                      this.props.user.permissions);
        return (
            <React.Fragment>
                {loading &&
                <LoadingIndicator/>
                }
                <div style={{padding: 16}}>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit(this.submitFormHandler.bind(this))}
                          className={classes.container}>
                        <Grid container spacing={16} style={{height: '72vh', overflow: 'auto'}}>

                            <Grid item xs={12}>
                                <Typography variant="h5" color={"primary"} gutterBottom>
                                    {userLabels.page_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup className={classes.container}>

                                    <TextField name="firstName"
                                               label={userLabels.first_name}
                                               className={classes.inputField}
                                               required validate={[required]}
                                               disabled={disabled}
                                    />
                                    <TextField name="lastName"
                                               label={userLabels.last_name}
                                               className={classes.inputField}
                                               required validate={[required]}
                                               disabled={disabled}
                                    />
                                    <TextField name="email" label={userLabels.email} className={classes.inputField}
                                               required
                                               validate={[required, email]}
                                               disabled={disabled}/>
                                    <CountrySelectField name="countryCode"
                                                        className={classes.inputField}
                                                        disabled={disabled}
                                    />
                                    <TextField name="addressLine1"
                                               label={userLabels.address1}
                                               className={classes.inputField}
                                               disabled={disabled}
                                    />
                                    <TextField name="addressLine2"
                                               label={userLabels.address2}
                                               className={classes.inputField}
                                               disabled={disabled}/>
                                    <div className={classes.formRow}>
                                        <TextField
                                            name={"postalCode"}
                                            label={userLabels.postcode}
                                            className={classes.postcode}
                                            disabled={disabled}
                                        />
                                        <TextField
                                            name={"city"}
                                            label={userLabels.city}
                                            className={classes.city}
                                            disabled={disabled}
                                        />
                                    </div>

                                    <TextField name="primaryPhone"
                                               label={userLabels.phone1}
                                               className={classes.inputField}
                                               validate={[phoneNumber]}
                                               disabled={disabled}
                                    />
                                    <TextField name="secondaryPhone"
                                               label={userLabels.phone2}
                                               className={classes.inputField}
                                               validate={[phoneNumber]}
                                               disabled={disabled}
                                    />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <FormGroup className={classes.container}>

                                    <LanguageSelectField name="languageCode"
                                                         className={classes.inputField} required
                                                         validate={[required]}
                                                         disabled={disabled}
                                    />

                                    <ClinicsSelectField name="clinicId"
                                                        className={classes.inputField}
                                                        required
                                                        validate={[required]}
                                                        disabled={disabled}
                                    />


                                    <CheckBoxField name="active"
                                                   label={userLabels.is_active}
                                                   className={classes.inputField}
                                                   disabled={disabled || !canDeactivateUser}
                                    />

                                    {formMode !== FormMode.CREATE &&
                                    <CheckBoxField name="useMfa"
                                                   label={userLabels.mfa}
                                                   className={classes.inputField}
                                                   disabled={true}
                                    />
                                    }

                                </FormGroup>

                                {this.permissionsBlock(classes)}

                            </Grid>

                        </Grid>
                        <Grid container alignItems="center" justify="flex-end" spacing={8} style={{marginTop: 10}}>
                          {formMode !== FormMode.CREATE &&
                           <Button onClick={() => this.resetPassword(employee.email)}
                                   variant={"outlined"}
                                   color={"secondary"}
                                   className={classes.buttonMargin}>
                             {buttonLabels.reset_password}
                           </Button>
                          }

                          {canResetMfa && formMode !== FormMode.CREATE &&
                            <Button onClick={() => this.resetMfaForEmployee()}
                                    variant={"outlined"}
                                    color={"secondary"}
                                    className={classes.buttonMargin}
                                    disabled={loading}>
                                {buttonLabels.reset_mfa}
                            </Button>
                            }

                            <FormButtons formMode={formMode}
                                         onCloseButtonClick={this.closeButtonClickHandler}
                                         onCancelButtonClick={this.cancelButtonClickHandler}
                                         onEditButtonClick={this.editButtonClickHandler}
                                         actionButtonDisabled={loading}
                                         editModeEnabled={this.editable()}
                            />
                        </Grid>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    permissionsBlock(classes) {
        const roles_all = this.state.roles;
        const disabled = this.state.formMode === FormMode.VIEW;
        const userRoles = this.props.user.roles;
        let assignableRoles = {};

        if (userRoles.includes("ADMINISTRATOR")) {
            assignableRoles = roles_all;
        } else if (userRoles.includes("CLINIC_ADMINISTRATOR")) {
            assignableRoles = this.clinic_administrator_roles;
        }

        return <div className={classes.container}>
            <div>{this.props.labels.user_details.roles}:</div>
            {
                Object.keys(roles_all).map(roleName => {
                    const isChecked = roles_all[roleName];

                    const isUserAllowed = Object.keys(assignableRoles).includes(roleName);
                    return (
                        <React.Fragment>
                            {isUserAllowed &&
                            <FormControlLabel key={roleName}
                                              className={classes.roleField}
                                              control={
                                                  <Checkbox
                                                      checked={isChecked}
                                                      onChange={this.toggleCheckbox.bind(this, roleName)}
                                                      disabled={disabled}
                                                  />
                                              }
                                              label={roleName}
                            />}
                        </React.Fragment>
                    )
                })
            }
        </div>;
    }
}

StaffUserDetails.propTypes = {
    employeeId: PropTypes.number,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
};


const mapTranslationsToProps = ({labels}) => ({labels});

function mapStateToProps({auth}) {

    return {user: auth.user}

}

export default compose(
    connect(mapStateToProps, null),
    translatable(mapTranslationsToProps),
    withStyles(styles),
    reduxForm({
        form: reduxFormName,
        initialValues: {
            active: true
        }
    })
)(StaffUserDetails);
