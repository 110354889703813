import React from "react";
import PropTypes from 'prop-types';
import RestService from "../../services/RestService";
import toastr from "toastr";
import GenericAutoCompleteField from "../GenericAutoCompleteField";


export default class ServiceSelectField extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            result: []
        });
        this.optionmap = props.optionmap;
        this.service = props.service;
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        let {name, label, className, ...other} = this.props;
        return <GenericAutoCompleteField name={name}
                                         className={className}
                                         label={label}
                                         optionmap={this.state.result}
                                         {...other}/>
    }

    fetchData() {
        const customMapper = this.props.customMapper;
        if (this.service !== undefined && this.service !== null) {
            this.service.getAll(this.props.criteria).then(response => {
                const result = customMapper ? customMapper(response.data) : response.data;
                this.setState({result: result})
            }).catch(error => {
                console.error(error);
                if (error.response === undefined || error.response.data === undefined)
                    return;

                if (error.response.data.message !== undefined)
                    toastr.error(error.response.data.message)
            });
        }
        else {
            this.setState({result: this.optionmap})
        }
    }


}

ServiceSelectField.propTypes = {
    name: PropTypes.string.isRequired,
    service: PropTypes.instanceOf(RestService),
    optionmap: PropTypes.any,
    customMapper: PropTypes.func,
    label: PropTypes.string,
};

