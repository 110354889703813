import Button from "@material-ui/core/Button";

import {translatable} from "react-multilingual";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {compose} from "recompose";
import PropTypes from "prop-types";
import {FormMode} from "../FormUtils";
import Grid from '@material-ui/core/Grid/Grid';

const styles = theme => ({
    button: {
        marginRight: 32
    },

});

const FormButtons = ({formMode, editModeEnabled, onCloseButtonClick, onCancelButtonClick, onEditButtonClick, actionButtonDisabled, labels, classes}) => (

        <React.Fragment>
            {(formMode === FormMode.VIEW || formMode === FormMode.CREATE) &&
            <Grid item>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={onCloseButtonClick}

                >
                    {labels.close}
                </Button>
            </Grid>
            }

            {
                (formMode === FormMode.EDIT) &&
                <Grid item>
                    < Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={onCancelButtonClick}

                    >
                        {labels.cancel}
                    </Button>
                </Grid>
            }

            {
                (formMode === FormMode.VIEW && editModeEnabled) &&
                <Grid item>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        onClick={onEditButtonClick}
                        disabled={actionButtonDisabled}
                    >
                        {labels.edit}
                    </Button>
                </Grid>
            }
            {
                (formMode === FormMode.CREATE || formMode === FormMode.EDIT) &&
                <Grid item>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={actionButtonDisabled}
                    >
                        {(formMode === FormMode.CREATE ? labels.create : labels.update)}
                    </Button>
                </Grid>
            }
        </React.Fragment>
    )
;

FormButtons.propTypes = {
    formMode: PropTypes.oneOf([FormMode.VIEW, FormMode.CREATE, FormMode.EDIT]),
    onCloseButtonClick: PropTypes.func.isRequired,
    onCancelButtonClick: PropTypes.func.isRequired,
    onEditButtonClick: PropTypes.func.isRequired,
    actionButtonDisabled: PropTypes.bool.isRequired,
    editModeEnabled: PropTypes.bool.isRequired,
};

const mapTranslationsToProps = ({labels}) => {

    return {labels: labels.buttons}

};

export default compose(
    withStyles(styles),
    translatable(mapTranslationsToProps))(FormButtons);