import PropTypes from "prop-types";
import React from "react";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import {translatable} from "react-multilingual";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button/Button";
import renderAgreement from "./renderAgreement";


const styles = theme => ({
    content: {
        overflow: 'auto',
    }
});


const DocumentRepresentation = ({labels, classes, docText, translation, version, windowHandler}) => {

    if (translation === null)
        return <div/>;

    return (
        <Paper>
            <Grid container style={{padding: 20}}>
                <Grid item xs={8}>
                    <Typography variant="h5" color="primary">
                        {translation.languageName}
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Grid container spacing={32} alignItems="center" justify="flex-end">
                        <Grid item>
                            {!version.published &&
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                onClick={windowHandler}>
                                {labels.edit}
                            </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div style={{overflowY: 'scroll', padding: '15px', maxHeight: '80vh'}} dir={translation.textDirection}>
                {docText !== null && docText !== 'undefined' && renderAgreement(docText)}
            </div>

        </Paper>);


};


DocumentRepresentation.propTypes = {
    docText: PropTypes.string,
    translation: PropTypes.object,
    version: PropTypes.object
};


const mapTranslationsToProps = (translations) => {
    return {labels: translations.labels.documents_section.document_representation};
};


export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(DocumentRepresentation);

