import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import SelectField from "./selectFields/SelectField";


const releaseStatuses = (status) => [
    {value: 'ACTIVE', label: status.active},
    {value: 'INACTIVE', label: status.inactive},
    {value: 'CREATED', label: status.created}]
;


const StatusSelectField = ({labels: {label, status}, name, ...other}) => {
    return <SelectField name={name} optionmap={releaseStatuses(status)}
                        label={label} {...other}/>;
};


StatusSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {
        labels:
            {
                label: labels.manageResults_labels.classification,
                status: labels.statuses
            }
    }
};

export default translatable(mapTranslationsToProps)(StatusSelectField);