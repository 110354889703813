import axios from "axios/index";

export default class PdfExportResultService {

    API_URL = null;


    constructor() {

        this.API_URL = process.env.REACT_APP_API_ROOT + 'pdfExport/results/patient/'

    }

    getBlobWithCustomParams(params, patientId) {
        const crits = this.prepareCriteriaQueryString(params);

        return axios({
            url: this.API_URL + patientId + '?' + crits,
            method: 'GET',
            responseType: 'blob' // important
        })
    }

    prepareCriteriaQueryString(criteria) {

        let criterionQueryStringArray = [];

        for (var key in criteria) {
            if (criteria.hasOwnProperty(key)) {
                let value = criteria[key];
                if (value !== undefined && value !== '')
                    criterionQueryStringArray.push(`${key}=${value}`)
            }

        }

        return criterionQueryStringArray.join('&')

    }

}

