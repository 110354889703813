import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {reduxForm} from "redux-form";
import {PagedTableContainer} from "../../common/components/PagedTable/PagedTableContainer";
import {EXPORT_PENDING_RESULT_SERVICE, PENDING_RESULT_SERVICE} from "../../common/services/availableServices";
import {translatable} from "react-multilingual";
import {compose} from "recompose";
import {columnType} from "../../common/components/PagedTable/columnType";
import SearchTextField from "../../common/fields/SearchTextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {managePageStyles} from "../../styles";
import ClassificationComponent from "../common/ClassificationComponent";
import ClassificationSelectField from "../../common/fields/selectFields/ClassificationSelectField";
import {permission} from "../../Auth/permissions";
import PermissionContainer from "../../Auth/PermissionContainer";
import ClinicsSelectField from "../../common/fields/selectFields/ClinicsSelectField";
import PendingResultView from "./PendingResultView";
import toastr from "toastr";
import Utils from "../../common/Utils";
import ExportExcelButton from "../../common/components/ExportExcelButton";
import Dialog from "@material-ui/core/Dialog";

const styles = () => ({
    dialogPaperClass: {
        padding: 0,
        width: 1100
    }

});

class ManagePendingResults extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            criteria: null,
            openModal: false,
            id: NaN,
            loadingTableData: true,
        };
        this.tableColumnSetup();

    }

    tableColumnSetup() {

        this.columns = [
            {key: 'date', type: columnType.text, customCellRenderer: this.dateCellRenderer},
            {key: 'externalPatientId', type: columnType.text, orderProperty: "patient.externalId"},
            {key: 'patientInfo', type: columnType.text, orderProperty: ["patient.firstName", "patient.lastName"]},
            {key: 'runNo', type: columnType.text},
            {key: 'finalConcentration', type: columnType.numeric},
            {
                key: 'overallResult',
                type: columnType.text,
                customCellRenderer: this.overallResultCellRenderer,
                sortable: false
            },
            {key: 'actions', type: columnType.text, customCellRenderer: this.actionsCellRenderer, sortable: false}
        ];

    }

    overallResultCellRenderer = (rowId, columnValue) => {
        return <ClassificationComponent classification={columnValue}/>;
    };

    actionsCellRenderer = (rowId, columnValue) => {

        return <div>
            <Button onClick={this.detailsButtonClickHandler(rowId)}>{this.props.labels.buttons.details}</Button>
            <PermissionContainer allowedPermissions={[permission.pendingResults_accept_your,
                permission.pendingResults_accept_all]}>
                <Button onClick={() => this.acceptButtonClickHandler(rowId)}>{this.props.labels.buttons.accept}</Button>
            </PermissionContainer>
            <PermissionContainer allowedPermissions={[permission.pendingResults_reject_your,
                permission.pendingResults_reject_all]}>
                <Button color="secondary"
                        onClick={() => this.rejectButtonClickHandler(rowId)}>{this.props.labels.buttons.reject}</Button>
            </PermissionContainer>

        </div>;
    };

    dateCellRenderer = (rowId, columnValue) => {
        return Utils.dateRenderer(columnValue);
    };


    detailsButtonClickHandler = rowId => event => {

        this.setState({
            openModal: true,
            id: rowId
        });
    };

    acceptButtonClickHandler(rowId) {
        PENDING_RESULT_SERVICE.put(null, rowId).then(response => {
            this.refreshTable();
            toastr.success("Result accepted!");
        }).catch(error => {
            console.error(error);
            if (typeof error.response === "undefined") {
                toastr.error(error);
            }
        })
    }

    rejectButtonClickHandler(rowId) {
        PENDING_RESULT_SERVICE.delete(rowId).then(response => {
            this.refreshTable();
            toastr.success("Result rejected!");
        }).catch(error => {
            console.error(error);
            if (typeof error.response === "undefined")
                toastr.error(error.response.data.message)
            return;
        })
    }

    searchButtonClickHandler = values => {

        let queryParams = {
            search: values.search
        };

        if ('overallResult' in values) {
            queryParams = {
                ...queryParams,
                'overallResult': values.overallResult
            };
        }

        if ('clinic' in values) {
            queryParams = {
                ...queryParams,
                'patient.employee.clinic.id': values.clinic
            };
        }

        this.setState({
            criteria: queryParams,
            loadingTableData: true,
        });
    };

    refreshTable = () => {
        this.setState({id: NaN, openModal: false, loadingTableData: true});
    };


    loadingDataFinishHandler = () => {
        this.setState({
            loadingTableData: false,
        })
    };


    render() {
        const {handleSubmit, labels, classes} = this.props;
        const specLabels = labels.managePendingResults_labels;
        const {criteria, id, openModal, loadingTableData} = this.state;

        return (
            <div>

                <Dialog classes={{paper: classes.dialogPaperClass}}
                        maxWidth={false}
                        open={openModal}
                        onClose={this.refreshTable}

                >

                    <PendingResultView
                        resultId={id}
                        onClose={this.refreshTable}
                    />

                </Dialog>

                <Grid container className={classes.header}>
                    <Grid item xs={8}>
                        <Typography variant="h5" color="primary">
                            {specLabels.page_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={32} alignItems="center" justify="flex-end">
                            <Grid item>
                                <ExportExcelButton service={EXPORT_PENDING_RESULT_SERVICE}
                                                   criteria={this.state.criteria}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
                    <Grid spacing={24} container className={classes.criteria} justify="flex-start">
                        <Grid item md={3}>
                            <SearchTextField
                                name="search"
                                className={classes.criteriaField}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}>
                                <ClassificationSelectField
                                    name="overallResult"
                                    className={classes.criteriaField}
                                />
                            </div>
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}>
                                <PermissionContainer allowedPermissions={[permission.patientResult_view_all]}>
                                    <ClinicsSelectField name="clinic"
                                                        className={classes.criteriaField}/>
                                </PermissionContainer>
                            </div>
                        </Grid>
                        <Grid item container md alignItems="center" justify="flex-end">
                            <Button

                                variant="contained"
                                color="primary"
                                type="submit">
                                {labels.buttons.search}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <PagedTableContainer rowKey="id" orderBy="date" order="asc" columns={this.columns}
                                     criteria={criteria} service={PENDING_RESULT_SERVICE}
                                     loadingData={loadingTableData}
                                     onLoadingDataFinish={this.loadingDataFinishHandler}
                                     errorMessage={labels.table.error_message}
                                     emptyResultMessage={labels.table.empty_result}
                                     columnLabels={specLabels.columns}
                />
            </div>
        );
    }


}


const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps),
    reduxForm({form: 'PendingResultSearch'}),
    withStyles(
        (theme) => ({
            ...managePageStyles(theme),
            ...styles(theme),
        }),
        {withTheme: true},
    )
)(ManagePendingResults);
