import React from 'react';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import toastr from "toastr";
import lodash from "lodash";


const styles = theme => ({
    root: {
        height: 250,
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },

});

class AsyncAutoCompleteField extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedSuggestion: this.props.selectedSuggestion,
            selectedValue: this.props.selectedValue,
            suggestions: [],
        };

        this.debouncedLoadSuggestions = lodash.debounce(this.fetchSuggestions, this.props.debounceDelay);
    }


    renderInputComponent(inputProps) {
        const {
            classes, inputRef = () => {
            }, ref, ...other
        } = inputProps;

        return (
            <TextField
                fullWidth
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                }}
                {...other}
            />
        );
    }


    handleSuggestionsFetchRequested = ({value}) => {
        this.debouncedLoadSuggestions(value)
    };


    fetchSuggestions(value) {

        this.setState({
            loading: true,
        });

        const inputValue = value.trim().toLowerCase();

        let page = {
            number: 0,
            size: this.props.pageSize,
        };

        let sorting = {
            orderBy: this.props.orderBy,
            order: this.props.order
        };


        let criteria = {
            search: inputValue
        }

        if (this.props.criteria) {
            criteria = {
                ...criteria,
                ...this.props.criteria,
            }
        }
        this.props.service.fetchData(page, sorting, criteria).then(res => {
            const data = res.data.content;

            if (data === undefined)
                throw new Error("Data should be placed in 'content' property");

            this.setState({
                suggestions: data,
                loading: false,
            });

        }).catch(error => {
            console.error("Can't fetch data", error);
            this.setState({
                suggestions: [],
                loading: false,
            });

            toastr.error(this.props.errorMessage);
        })

    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = () => (event, {newValue}) => {


        if (this.state.selectedValue !== null) {

            this.props.onSelect(null)

        }

        this.setState({
            selectedSuggestion: newValue,
            selectedValue: null

        });


    };

    shouldRenderSuggestions = (value) => {
        return true;
    };

    onSuggestionSelected = (event, {suggestion}) => {


        this.setState({
            selectedValue: suggestion
        })

        this.props.onSelect(suggestion)
    };

    render() {
        const {classes, getSuggestionValue, renderSuggestion, placeholder} = this.props;

        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
            shouldRenderSuggestions: this.shouldRenderSuggestions,
            onSuggestionSelected: this.onSuggestionSelected

        };

        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={{
                    classes,
                    placeholder: placeholder,
                    value: this.state.selectedSuggestion,
                    onChange: this.handleChange(),
                }}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square>
                        {options.children}
                    </Paper>
                )}
            />
        );
    }
}

AsyncAutoCompleteField.defaultProps = {
    pageSize: 5,
    order: 'asc',
    debounceDelay: 400,
    selectedSuggestion: '',
    selectedValue: null,
};

export default withStyles(styles)(AsyncAutoCompleteField);

