import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import {
    DOCUMENTS_REPRESENTATION_SERVICE,
    DOCUMENTS_TRANSLATION_SERVICE
} from "../../../common/services/availableServices";
import React, {Component} from 'react';
import EditDocumentRepresentation from "./EditDocumentRepresentation";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import HtmlConverter from "../../../RtfEditor/HtmlConverter";
import toastr from 'toastr'

const styles = theme => ({
    root: {
        display: 'flex',
        minWidth: '60vh'
    },
    container: {
        width: '100%'
    },
    inputField: {
        width: '100%'
    },
    submitRow: {
        marginTop: 20
    }
});


class EditDocumentRepresentationContainer extends Component {


    constructor(props) {
        super(props);
        this.state = {
            docEdit: null,
            editorState: EditorState.createEmpty(),
            translationsToCompare: [],
            compareLanguage: null,
            docView: null
        };
    }

    componentDidMount() {
        this.getDocumentEdit(this.props.translation.documentRepresentationId);
        this.getCompareTranslations(this.props.version.id);

    }

    getDocumentEdit(id) {
        DOCUMENTS_REPRESENTATION_SERVICE.get('edit/' + id).then(docView => {
            this.loadDocumentIntoRtf(docView.data.content)
        }).catch(error => {
            this.setState({docEdit: null});
        });
    }

    getDocumentView(id) {
        DOCUMENTS_REPRESENTATION_SERVICE.get('display/' + id).then(docView => {
            this.setState({docView: docView.data})
        }).catch(error => {
            this.setState({docView: null});
        });
    }


    getCompareTranslations(versionId) {
        DOCUMENTS_TRANSLATION_SERVICE.get(versionId).then(translations => {
            const translationData = translations.data;
            const selectedTranslation = this.props.translation;
            const differentLangTranslation = translationData.filter(translation => translation.languageCode !== selectedTranslation.languageCode);
            this.setState({translationsToCompare: differentLangTranslation});
        }).catch(error => {
            if (typeof error.response === "undefined")
                return;
            toastr.error(error.response.data.message)
        });
    }


    onChange(editorState) {
        this.setState({editorState});
    };


    loadDocumentIntoRtf(docData) {
        let parseJson = JSON.parse(docData);
        this.setState({editorState: EditorState.createWithContent(convertFromRaw(parseJson))});

    }

    prepareDocData() {
        const contentState = this.state.editorState.getCurrentContent();
        const jsonEdit = JSON.stringify(convertToRaw(contentState));
        const htmlStructure = JSON.stringify(HtmlConverter.prepareHtmlCode(contentState));
        return {
            display: htmlStructure,
            edit: jsonEdit
        };
    }


    saveDocInProgress() {
        const documentDto = this.prepareDocData();
        const id = this.props.translation.documentRepresentationId;

        DOCUMENTS_REPRESENTATION_SERVICE.put(documentDto, 'in_progress/' + id).then(response => {
            this.props.onSuccess(documentDto.display, "IN_PROGRESS");
        }).catch(error => {
            console.error("error", error)
        })
    }

    saveDocInFinished() {
        const documentDto = this.prepareDocData();
        const id = this.props.translation.documentRepresentationId;

        DOCUMENTS_REPRESENTATION_SERVICE.put(documentDto, 'finish/' + id).then(response => {
            this.props.onSuccess(documentDto.display, "FINISHED");
        }).catch(error => {
            console.error("error", error)
        })
    }


    handleChangeTranslationCompareLanguage(event) {
        const translation = event.target.value;
        if (translation !== null) {
            this.getDocumentView(translation.documentRepresentationId);
            this.setState({compareLanguage: translation})
        }
        else {
            this.setState({docView: null});
            this.setState({compareLanguage: null})
        }
    }

    render() {
        return (

            <EditDocumentRepresentation

                windowHandler={this.props.onClose}
                onDraftHandler={this.saveDocInProgress.bind(this)}
                onFinishHandler={this.saveDocInFinished.bind(this)}

                docEdit={this.state.docEdit}
                compareTextDocument={this.state.docView}

                translation={this.props.translation}
                translationsToCompare={this.state.translationsToCompare}
                compareTranslation={this.state.compareLanguage}

                handleChangeTranslationCompareLanguage={this.handleChangeTranslationCompareLanguage.bind(this)}


                onChange={this.onChange.bind(this)}
                editorState={this.state.editorState}


            />)
    };
}


EditDocumentRepresentationContainer.propTypes = {
    version: PropTypes.object,
    translation: PropTypes.object,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
};

export default compose(
    withStyles(styles)
)(EditDocumentRepresentationContainer);

