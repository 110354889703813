export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const required = value => (value || value === 0 ? undefined : 'Required');

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined;

export const phoneNumber = value => {
    var re = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
    return value && !re.test(String(value)) ? 'Invalid phone number' : undefined;
};

