import FileDownloaderService from "../services/FileDownloaderService";
import toastr from "toastr";
import Button from "@material-ui/core/Button/Button";
import React from "react";
import {translatable} from "react-multilingual";
import PropTypes from 'prop-types';
import RestService from "../services/RestService";
import LoadingIndicator from "./LoadingIndicator";
import {constructErrorMessage} from "../FormUtils";


class ExportExcelButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        const {label, service, criteria} = this.props;

        const namedExportService = this.exportResultService(service, criteria);
        return <React.Fragment>

            {this.state.loading && <LoadingIndicator/>}
            <Button
                disabled={this.state.loading}
                variant="outlined"
                color="primary"
                onClick={namedExportService}
            >
                {label}
            </Button>
        </React.Fragment>
    }


    exportResultService = (service, criteria) => () => {

        this.setState({loading: true});
        service.getBlobWithParams(criteria).then((response) => {
            FileDownloaderService.handleBlobResponse(response)
        }).catch(error => {
            console.error(error);
            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't export data.", error));
        }).finally(() => {
            this.setState({loading: false});
        });
    };

}


ExportExcelButton.propTypes = {
    criteria: PropTypes.any,
    service: PropTypes.instanceOf(RestService)
};


const mapTranslationsToProps = (translations) => {
    return {label: translations.labels.buttons.export_results};
};
export default translatable(mapTranslationsToProps)(ExportExcelButton)