import React from 'react';
import {translatable} from "react-multilingual";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Button from "../../node_modules/@material-ui/core/Button/Button";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import {compose} from "recompose";

const styles = () => ({
    doneButton: {
        marginTop: "20px"
    }
});

class Done extends React.Component {
    render() {
        const labels = this.props.labels.signup_labels;
        const {classes} = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography>
                        {labels.done}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={'signup-buttons'}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.props.onHome}
                        className={classes.doneButton}
                    >
                        {this.props.labels.buttons.home}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    withStyles(styles),
    translatable(mapTranslationsToProps)
)(Done);
