import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import ServiceSelectField from "./ServiceSelectField";
import {ORGANIZATION_SERVICE_ALL} from "../../services/availableServices";

const customMapperHandler = (values) => {
    return values.map(item => {
        return {
            value: item.id,
            label: item.name
        }
    });

};

const OrganizationSelectField = ({label, name, ...other}) => {
    return <ServiceSelectField name={name} service={ORGANIZATION_SERVICE_ALL} customMapper={customMapperHandler}
                               label={label} {...other}/>;
};


OrganizationSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.select_field.organization};
};

export default translatable(mapTranslationsToProps)(OrganizationSelectField);