import {convertToHTML} from "draft-convert";
import {convertFromRaw, convertToRaw} from "draft-js";

export default class HtmlConverter {

    static prepareHtmlCode(contentState) {
        let outputHtmlArr = [];

        let rawSlitted = convertToRaw(contentState);
        let rawSlittedBlocks = rawSlitted.blocks;

        let size = rawSlittedBlocks.length;
        let nonCheckBoxLines = [];
        for (let count = 0; count < size; count++) {
            let block = rawSlittedBlocks[count];
            if (block && block.type === 'checkable-list-item') {
                rawSlitted.blocks = nonCheckBoxLines;
                outputHtmlArr.push(HtmlConverter.createPair(rawSlitted, block.text));
                nonCheckBoxLines = [];
            }
            else {
                nonCheckBoxLines.push(block);
            }
        }
        //remaining blocks of text but not a checkbox to close it
        if (nonCheckBoxLines.length > 0) {
            rawSlitted.blocks = nonCheckBoxLines;
            outputHtmlArr.push(HtmlConverter.createPair(rawSlitted, null));
        }
        return outputHtmlArr;
    }


    static createPair(rawSlitted, checkBoxTitle) {
        let htmlFirstPart = HtmlConverter.convertToHtmlFromRaw(rawSlitted);
        return HtmlConverter.createCheckHtmlPair(htmlFirstPart, checkBoxTitle);
    }

    static createCheckHtmlPair(html, checkBoxTitle) {
        let outputJson = {content: "", title: ""};
        outputJson.content = html;
        outputJson.title = checkBoxTitle;
        return outputJson
    }

    static convertToHtmlFromRaw(rawContentState) {
        let firstPartHtml = '';
        if (rawContentState.blocks.length > 0)
            firstPartHtml = convertToHTML(convertFromRaw(rawContentState));

        return firstPartHtml;
    }


}
