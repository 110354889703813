import axios from "axios/index";

export default class AuthorizationService {

    AUTH_URL = process.env.REACT_APP_AUTH_URL;

    async authorize(accessToken) {

        const response = await
            axios.get(this.AUTH_URL, {
                headers: {
                    "Authorization": "Bearer " + accessToken
                },
                errorHandle: false
            });

        return this.mapResponseToUserObject(response.data)

    }

    mapResponseToUserObject(data) {

        return {
            "name": data.userFirstName,
            "surname": data.userLastName,
            "email": data.email,
            "roles": data.roles,
            "clinic": data.clinicName,
            "permissions": data.permissions,
            "clinicName": data.clinicName,
            "userId": data.userId,

        }
    }

}

