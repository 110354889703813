import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import ServiceSelectField from "./ServiceSelectField";


const customMapper = (roles) => {
    return Object.keys(roles).map(role => {
        return {
            value: role,
            label: roles[role]
        }
    })
};

const RoleSelectField = (props) => {
    let {labels, name, ...other} = props;


    return <ServiceSelectField name={name} label={labels.label}
                               optionmap={customMapper(labels.roles)} {...other}/>
};


RoleSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {
        labels: {label: labels.fields.role, roles: labels.roles}
    };
};


export default translatable(mapTranslationsToProps)(RoleSelectField);