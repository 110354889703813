import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import {PATIENT_SERVICE, REASSIGN_PATIENT_SERVICE} from "../../common/services/availableServices";
import AutocompleteField from "../../common/fields/AutocompleteField";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from "@material-ui/core/Grid";
import toastr from "toastr";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import PermissionContainer from "../../Auth/PermissionContainer";
import {permission} from "../../Auth/permissions";
import {connect} from "react-redux";


const styles = theme => ({
    iconButton: {
        fontSize: 21,
        width: 28,
        height: 28,
    },
});

class ReassignPatientComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            value: null,
            selectedPatient: null,
        };

        this.selectHandler = this.selectHandler.bind(this);
        this.reassignButtonClickHandler = this.reassignButtonClickHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);


        if (PermissionContainer.checkAllowedPermission(permission.pendingResults_changePatient_your, this.props.user.permissions) &&
            !PermissionContainer.checkAllowedPermission(permission.pendingResults_changePatient_all, this.props.user.permissions)) {
            this.criteria = {'employee.id': this.props.user.userId}
        }

    }


    renderSuggestion(suggestion, {query, isHighlighted}) {

        const {id, externalId, firstName, lastName, email} = suggestion;

        const text = [externalId, firstName, lastName, email].filter(Boolean).join(' ');

        return (
            <MenuItem selected={isHighlighted} key={id} value={id}>
                {text}
            </MenuItem>
        );
    }


    getSuggestionValue(suggestion) {

        const {externalId, firstName, lastName, email} = suggestion;

        return [externalId, firstName, lastName, email].filter(Boolean).join(' ')
    }

    selectHandler(selectedPatient) {

        this.setState({
            selectedPatient: selectedPatient
        })
    }


    reassignButtonClickHandler() {

        const selectedPatientId = this.state.selectedPatient.id;
        const resultId = this.props.resultId;

        REASSIGN_PATIENT_SERVICE.put(selectedPatientId, resultId).then(response => {
            toastr.success(this.props.labels.patientReassignmentSuccess);
            this.props.onSuccess();
        }).catch(error => {
            toastr.error(this.props.labels.patientReassignmentFail);
        });

    }


    cancelButtonClickHandler() {
        this.props.onCancel();
    }


    render() {
        const {classes, labels} = this.props;
        return (
            <Grid container>
                <Grid item xs>
                    <AutocompleteField
                        renderSuggestion={this.renderSuggestion}
                        getSuggestionValue={this.getSuggestionValue}
                        service={PATIENT_SERVICE}
                        criteria={this.criteria}
                        placeholder={labels.searchPatientPlaceholder}
                        orderBy={"externalId"}
                        onSelect={this.selectHandler}
                    />
                </Grid>
                <Grid item>

                    <IconButton aria-label="reassign" className={classes.iconButton}
                                disabled={this.state.selectedPatient === null} color="primary"
                                onClick={this.reassignButtonClickHandler}>
                        <DoneIcon fontSize='inherit'/>
                    </IconButton>
                    <IconButton aria-label="cancel" color="secondary" className={classes.iconButton}
                                onClick={this.cancelButtonClickHandler}>
                        <ClearIcon fontSize='inherit'/>
                    </IconButton>


                </Grid>
            </Grid>
        );
    }
}

const mapTranslationsToProps = ({labels}) => {

    return {labels: labels.pending_result_basic_view}
};


function mapStateToProps({auth}) {

    return {user: auth.user}

}

export default compose(
    connect(mapStateToProps, null),
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(ReassignPatientComponent);


