import React from 'react';
import { Button, Drawer, List, ListItem, Typography, withStyles } from '@material-ui/core';
import { translatable } from 'react-multilingual';
import { connect } from 'react-redux';
import { CheckBoxOutlined } from '@material-ui/icons';
import { compose } from 'recompose';
import { Field, getFormValues, reduxForm } from 'redux-form';
import TextField from '../common/fields/TextField';
import FAQFormLanguage from './FAQFormLanguage';
import classNames from 'classnames';
import { languages } from './utils';

const styles = (theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  drawer: {
    height: '100%',
  },
  drawerList: {
    padding: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    flex: 1,
    overflow: 'auto',
    paddingTop: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 6,
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  drawerPaper: {
    width: 240,
    backgroundColor: '#FFFFFF',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
  input: {
    fontSize: 16,
  },
  dNone: {
    display: 'none',
  },
  questionInput: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  questionInputFilled: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  notchedOutlineTop: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: 'transparent',
  },
  notchedOutlineBottom: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  photoUrlContainer: {
    marginTop: 8,
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  photoIcon: {
    color: 'green',
  },
});

const FAQForm = ({ classes, labels, initialValues, formValues, handleSubmit, submitting, onSubmit, change, ...rest }) => {
  const [selectedLocale, setSelectedLocale] = React.useState('EN');

  if (!formValues) {
    return null;
  }

  return (
    <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
      <Drawer
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
      >
        <List component="nav" classes={{
          root: classes.drawerList
        }}>
          {languages.map((languageCode) => (
            <ListItem
              key={languageCode}
              button
              selected={selectedLocale === languageCode}
              onClick={() => setSelectedLocale(languageCode)}
              divider
            >
              <FAQFormLanguage
                initialValue={initialValues[languageCode]}
                value={formValues[languageCode]}
                language={labels.language[languageCode]}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div className={classes.content}>
        <div className={classes.inputs}>
          <TextField
            fullWidth
            label=""
            margin="none"
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutlineTop,
                input: classNames(classes.questionInput, classes.input, {
                  [classes.questionInputFilled]: formValues[selectedLocale].question.text !== '',
                }),
              },
            }}
            name={`${selectedLocale}.question.text`}
            placeholder={labels.question.placeholder}
            variant="outlined"
            showFormHelperText={false}
          />
          <TextField
            fullWidth
            multiline
            label=""
            margin="none"
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutlineBottom,
                input: classes.input
              },
            }}
            name={`${selectedLocale}.answer.text`}
            placeholder={labels.answer.placeholder}
            variant="outlined"
            showFormHelperText={false}
            rows={7}
            rowsMax={7}
          />
          <Typography variant="subtitle1" color="primary" style={{ marginTop: 10 }}>{labels.video.label}</Typography>
          <TextField
            fullWidth
            label=""
            margin="none"
            name={`${selectedLocale}.answer.videoUrl`}
            placeholder={labels.video.placeholder}
            variant="outlined"
            showFormHelperText={false}
          />
          {(formValues[selectedLocale].answer.photoName && formValues[selectedLocale].answer.base64EncodedPhoto) && (
            <div className={classes.photoUrlContainer}>
              <CheckBoxOutlined className={classes.photoIcon} />
              <a href={formValues[selectedLocale].answer.photoUrl || formValues[selectedLocale].answer.base64EncodedPhoto}>
                <Typography variant="body1" noWrap style={{ maxWidth: 200 }}>
                  <u>{formValues[selectedLocale].answer.photoName}</u>
                </Typography>
              </a>
              <Typography variant="body1">was added as an attachment.</Typography>
              <Button
                onClick={() => {
                  change(`${selectedLocale}.answer.base64EncodedPhoto`, '');
                  change(`${selectedLocale}.answer.photoName`, '');
                }}
              >
                X {labels.delete}
              </Button>
            </div>
          )}
        </div>
        <div className={classes.actions}>
          <input
            key={formValues[selectedLocale].answer.base64EncodedPhoto}
            accept="image/*"
            className={classes.dNone}
            id="upload-photo-button"
            type="file"
            onChange={(event) => {
              const file = event.target.files[0];
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  change(`${selectedLocale}.answer.base64EncodedPhoto`, reader.result);
                  change(`${selectedLocale}.answer.photoName`, file.name);
                }
                reader.readAsDataURL(file);
              }
            }}
          />
          <label htmlFor="upload-photo-button">
            <Button variant="outlined" color="primary" component="span">
              {labels.photo.add}
            </Button>
          </label>
          <Button variant="contained" color="primary" type="submit" disabled={submitting}>
            {labels.save}
          </Button>
        </div>
      </div>
    </form>
  )
};

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: languages.reduce((acc, language) => {
      const translation = ownProps.faq.find(item => item.languageCode === language);
      return {
        ...acc,
        [language]: translation ? {
          question: {
            id: translation.question.id,
            text: translation.question.text,
          },
          answer: {
            id: translation.question.id,
            text: translation.answer.text,
            photoUrl: translation.answer.photoUrl || '',
            base64EncodedPhoto: translation.answer.base64EncodedPhoto || '',
            photoName: translation.answer.photoName || translation.answer.photoUrl || '',
            videoUrl: translation.answer.videoUrl || '',
          },
        } : {
            question: {
              text: '',
            },
            answer: {
              text: '',
              photoUrl: '',
              videoUrl: '',
            },
        }
      }
    }, {}),
    formValues: getFormValues('FAQForm')(state),
  }
}

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: 'FAQForm' }),
  withStyles(styles),
  translatable(({ labels }) => ({ labels: labels.add_edit_faq })),
)(FAQForm);