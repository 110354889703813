export const permission = {
    documents_add_version: 'documents_add_version',
    documents_edit: 'documents_edit',
    documents_publish: 'documents_publish',
    documents_view_eulaPortal: 'documents_view_eulaPortal',
    documents_view_eulaApp: 'documents_view_eulaApp',
    documents_view_ppClinic: 'documents_view_ppClinic',
    documents_view_dpaClinic: 'documents_view_dpaClinic',
    documents_view_ppPatient: 'documents_view_ppPatient',
    organization_view: 'organization_view',
    organization_add: 'organization_add',
    organization_edit: 'organization_edit',
    organization_deactivate: 'organization_deactivate',
    employee_view_all: 'employee_view_all',
    employee_view_clinic: 'employee_view_clinic',
    employee_add_all: 'employee_add_all',
    employee_add_clinic: 'employee_add_clinic',
    employee_edit_all: 'employee_edit_all',
    employee_edit_clinic: 'employee_edit_clinic',
    employee_edit_your: 'employee_edit_your',                         //TODO
    employee_deactivate_all: 'employee_deactivate_all',
    employee_deactivate_clinic: 'employee_deactivate_clinic',
    employee_reset_mfa: 'employee_reset_mfa',
    clinic_view_all: 'clinic_view_all',
    clinic_view_clinic: 'clinic_view_clinic',
    clinic_activate: 'clinic_activate',
    clinic_add: 'clinic_add',
    clinic_edit: 'clinic_edit',
    clinic_deactivate: 'clinic_deactivate',
    patient_view_all: 'patient_view_all',
    patient_view_clinic: 'patient_view_clinic',
    patient_view_your: 'patient_view_your',
    patient_add_all: 'patient_add_all',
    patient_add_clinic: 'patient_add_clinic',
    patient_add_your: 'patient_add_your',
    patient_edit_all: 'patient_edit_all',
    patient_edit_clinic: 'patient_edit_clinic',
    patient_edit_your: 'patient_edit_your',
    patient_assign_all: 'patient_assign_all',                         //TODO Remove
    patient_assign_clinic: 'patient_assign_clinic',                      //TODO Remove
    patient_reset_mfa: 'patient_reset_mfa',
    patientResult_add: 'patientResult_add',
    patientResult_edit_all: 'patientResult_edit_all',
    patientResult_edit_clinic: 'patientResult_edit_clinic',
    patientResult_edit_your: 'patientResult_edit_your',
    patientResult_view_all: 'patientResult_view_all',
    patientResult_view_clinic: 'patientResult_view_clinic',
    patientResult_view_your: 'patientResult_view_your',
    patientResult_view_own: 'patientResult_view_own',
  patientResult_delete_all : 'patientResult_delete_all',
  patientResult_delete_clinic : 'patientResult_delete_clinic',
    patientResultDetails_view_all: 'patientResultDetails_view_all',              //TODO
    patientResultDetails_view_clinic: 'patientResultDetails_view_clinic',           //TODO
    patientResultDetails_view_your: 'patientResultDetails_view_your',             //TODO
    pendingResults_changePatient_all: 'pendingResults_changePatient_all',
    pendingResults_changePatient_your: 'pendingResults_changePatient_your',
    pendingResults_view_all: 'pendingResults_view_all',
    pendingResults_view_clinic: 'pendingResults_view_clinic',
    pendingResults_view_your: 'pendingResults_view_your',
    pendingResults_accept_all: 'pendingResults_accept_all',
    pendingResults_accept_your: 'pendingResults_accept_your',
    pendingResults_reject_all: 'pendingResults_reject_all',
    pendingResults_reject_your: 'pendingResults_reject_your',
    batches_view_all: 'batches_view_all',
    batches_view_released: 'batches_view_released',
    batches_add: 'batches_add',
    batches_edit: 'batches_edit',
    batches_archive: 'batches_archive',                            //TODO
    batches_review: 'batches_review',
    batches_approve: 'batches_approve',
    batches_release: 'batches_release',
    samplingResult_add: 'samplingResult_add',
    samplingResult_export: 'samplingResult_export',
    reviewResults_add: 'reviewResults_add',
    reviewResults_export: 'reviewResults_export',
    role_setAdministrator: 'role_setAdministrator',
    role_setApiUser: 'role_setApiUser',
    role_setOrganizationAdministrator: 'role_setOrganizationAdministrator',
    role_setOrganizationAdministrator_clinic: 'role_setOrganizationAdministrator_clinic',   //TODO
    role_setQualityControl: 'role_setQualityControl',
    role_setClinicAdministrator: 'role_setClinicAdministrator',
    role_setClinicAdministrator_clinic: 'role_setClinicAdministrator_clinic',
    role_setClinicLab: 'role_setClinicLab',
    role_setClinicUser: 'role_setClinicUser',
    role_setClinicUserFullInsight: 'role_setClinicUserFullInsight',
};
