import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import {compose} from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import classNames from 'classnames';

const styles = theme => ({
    dialogPaper: {
        padding: theme.spacing.unit * 4,
    },
    noPaddingDialogPaper: {
        padding: 0
    },
    root: {
        margin: theme.spacing.unit * 4,
        width: '100%',
    }

});

class PopupWindow extends Component {


    render() {
        const {children, classes, disablePadding, ...other} = this.props;

        let dialogPaperClass = classes.dialogPaper;
        if (disablePadding)
            dialogPaperClass = classes.noPaddingDialogPaper;

        return (
            <Dialog classes={{paper: classNames(dialogPaperClass, classes.root)}}   {...other} maxWidth={false}>

                {children}

            </Dialog>
        );
    }
}

PopupWindow.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    disablePadding: PropTypes.bool
};

PopupWindow.defaultProps = {
    disablePadding: false
};


export default compose(
    withStyles(styles)
)(PopupWindow);


