import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {columnType} from "./columnType";


export const SortedTableHeaderCell = ({headerKey, orderProperty, active, type, direction, children, onChangeSort}) =>

    <TableCell numeric={type === columnType.numeric} padding={'checkbox'}>
        <Tooltip
            title="Sort"
            placement='bottom-end'
            enterDelay={300}
        >
            <TableSortLabel
                active={active}
                direction={direction}
                onClick={(orderProperty !== undefined ? onChangeSort(orderProperty) : onChangeSort(headerKey))}
            >
                {children}
            </TableSortLabel>
        </Tooltip>
    </TableCell>;

export default SortedTableHeaderCell


