import React from "react";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography";
import {translatable} from "react-multilingual";

const WelcomePage = ({classes, label}) => (

    <div>
        <Typography variant="h5" color="primary">
            {label}
        </Typography>
    </div>

);


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.welcome_message};
};

export default compose(
    translatable(mapTranslationsToProps)
)(WelcomePage);