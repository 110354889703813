import React, {Component} from 'react';
import Idle from 'react-idle'
import {logoutFailed, logoutRequest} from "../Auth/actions";
import {connect} from "react-redux";
import {authenticationService} from "../index";
import {IdleMonitor} from "./IdleMonitor";

class IdleMonitorContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.alertIdleChangeHandler = this.alertIdleChangeHandler.bind(this);
        this.logout = this.logout.bind(this);
        this.setupCounter = this.setupCounter.bind(this);
        this.tick = this.tick.bind(this);
        this.destroyCounter = this.destroyCounter.bind(this)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    alertIdleChangeHandler({idle}) {
        if (idle)
            this.setupCounter();
        else
            this.destroyCounter()
    }


    logout() {
        this.props.logoutRequest();

        try {
            authenticationService.logout()
        } catch (e) {
            this.props.logoutFailed(e.toString())
        }
    }


    setupCounter() {
        let timeToLogout = this.props.idleLogoutTime - this.props.idleAlertTime;

        this.setState({secondsRemaining: timeToLogout}, this.startCounter)
    }


    startCounter() {
        this.interval = setInterval(this.tick, 1000);
    }

    destroyCounter() {
        clearInterval(this.interval);
        this.setState({secondsRemaining: undefined});
    }


    tick() {
        this.setState({secondsRemaining: this.state.secondsRemaining - 1});
        if (this.state.secondsRemaining <= 0) {
            this.destroyCounter();
            this.logout()
        }
    }


    render() {

        let {idleAlertTime} = this.props;
        let {secondsRemaining} = this.state;


        return (

            <Idle timeout={idleAlertTime * 1000}
                  onChange={this.alertIdleChangeHandler}
                  render={({idle}) =>
                      <div>
                          {(idle && secondsRemaining !== undefined) &&
                          <IdleMonitor secondsRemaining={secondsRemaining}/>}</div>

                  }/>
        )

    }
}


export default connect(null, {
    logoutRequest, logoutFailed

})(IdleMonitorContainer)