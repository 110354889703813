import React from 'react';
import { translatable } from "react-multilingual";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { managePageStyles } from "../styles";
import { useState } from 'react';
import FAQItem from '../common/components/FAQItem';
import { FAQ_QUESTIONS_SERVICE } from '../common/services/availableServices';
import LoadingIndicator from "../common/components/LoadingIndicator";

const useFaqs = () => {
  const [loading, setLoading] = useState(false);
  const [faqItems, setFaqItems] = useState(undefined);

  React.useEffect(() => {
    setLoading(true);
    FAQ_QUESTIONS_SERVICE.getAll([{ language: 'EN', userType: 'EMPLOYEE' }])
      .then((response) => {
        setFaqItems(response.data);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    data: faqItems,
    loading,
  }
};

const styles = () => ({});

const SupportFAQ = ({ classes, labels }) => {
  const { data: faqItems, loading } = useFaqs();

  return (
    <Grid container className={classes.header} spacing={16}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          {labels.page_title}
        </Typography>
      </Grid>
      {loading &&
        <LoadingIndicator />
      }
      {faqItems && faqItems.map((item) => (
        <Grid key={item.question.id} item xs={12} style={{ padding: 10 }}>
          <FAQItem question={item.question.text} answer={item.answer.text} />
        </Grid>
      ))}
    </Grid>
  )
};

const mapTranslationsToProps = ({ labels }) => ({ labels: labels.support_faq });

export default compose(
  translatable(mapTranslationsToProps),
  withStyles(
    (theme) => ({
      ...managePageStyles(theme),
      ...styles(theme),
    }),
    { withTheme: true },
  )
)(SupportFAQ);
