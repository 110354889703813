import axios from "axios/index";

export default class ReassignPatientService {

    API_URL = null;


    constructor() {

        this.API_URL = process.env.REACT_APP_API_ROOT + 'result/pending'

    }


    put(patientId, id) {
        let urlString = this.API_URL + '/' + id + '/reassign';
        return axios.put(urlString, patientId, {headers: {"Content-Type": "text/plain"}})
    }


}

