import React from 'react';
import {CartesianGrid, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import PropTypes from 'prop-types';

class ProfileGraph extends React.Component {

    componentWillMount() {
        const baseline = this.props.baseline.split(' ');
        const profile = this.props.profile.split(' ');
        let data = [];
        for (let i = 0; i < baseline.length; i++) {
            data.push({
                x: i,
                baseline: parseFloat(baseline[i]),
                intensity: parseFloat(profile[i]),
            });
        }
        this.setState({
            data: data,
            cPeakPosition: this.props.cpeakPosition,
            tPeakPosition: this.props.tpeakPosition,
        })

    }

    render() {
        return (
            <div style={{overflow: 'hidden'}}>
                <LineChart width={1040} height={250} data={this.state.data}
                           margin={{top: 40, right: 0, bottom: 20, left: 0}}>
                    <Line type="monotone" dataKey="intensity" stroke="green" activeDot={{r: 6}} dot={() => {
                    }}/>
                    <Line type="monotone" dataKey="baseline" stroke="blue" activeDot={{r: 6}} dot={() => {
                    }}/>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                    <XAxis interval={19} label={{value: 'Profile', dy: 20}}/>
                    <YAxis label={{value: 'Intensity', angle: -90, dx: -20}} domain={[100, 220]}/>
                    <ReferenceLine x={this.state.cPeakPosition} stroke="blue"
                                   label={{value: 'C peak position', dx: 60, dy: 70}}/>
                    <ReferenceLine x={this.state.tPeakPosition} stroke="blue"
                                   label={{value: 'T peak position', dx: 60, dy: 70}}/>
                    <Tooltip formatter={value => Math.round(value * 10) / 10 + ''}/>
                </LineChart>
            </div>

        );
    }
}

ProfileGraph.defaultProps = {
    baseline: ''
};

ProfileGraph.propTypes = {
    baseline: PropTypes.string
};


export default ProfileGraph
