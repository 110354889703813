export default class FileDownloaderService {
    static handleBlobResponse(response) {
        const headers = response.headers;
        const filename = headers['content-disposition'].split(" ")[1].split("=")[1]; //parse the header
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }
}