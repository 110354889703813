import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";


const renderAgreement = (docDisplay) => {
    let idx = 0;

    if (docDisplay === null)
        return <p>empty</p>

    return JSON.parse(docDisplay).map((paragraph) => {
        return (
            <div key={idx++}>
                <div dangerouslySetInnerHTML={{__html: paragraph.content}}/>
                {paragraph.title && <FormControlLabel control={<Checkbox/>} label={paragraph.title}/>}
            </div>
        );
    });

};

export default renderAgreement;
