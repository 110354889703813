import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import SelectField from "./SelectField";


const releaseStatuses = (types) => {
    return (
        {
            HOME: types.home,
            LAB: types.lab
        });
};


const PatientTypeSelectField = ({labels: {label, types}, name, ...other}) => {
    return <SelectField name={name} optionmap={releaseStatuses(types)}
                        label={label} {...other}/>;
};


PatientTypeSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {
        labels:
            {
                label: labels.managePatients_labels.patient_type,
                types: labels.managePatients_labels.patient_types
            }
    }
};

export default translatable(mapTranslationsToProps)(PatientTypeSelectField);