import React, {Component} from "react";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import {translatable} from "react-multilingual";
import {loginRequest, logoutFailed, logoutRequest} from "../Auth/actions";
import CardContent from "../../node_modules/@material-ui/core/CardContent/CardContent";
import Grid from "../../node_modules/@material-ui/core/Grid/Grid";
import Button from "../../node_modules/@material-ui/core/Button/Button";
import Card from "../../node_modules/@material-ui/core/Card/Card";
import {authenticationService} from "../index";
import {connect} from "react-redux";
import {compose} from "recompose";
import PropTypes from 'prop-types';


const styles = () => ({

    logForm: {
        width: '450px',
        display: 'inline-block',
    }
});


class LandingMenu extends Component {
    render() {
        const {classes, labels} = this.props;
        return (
            <div style={{textAlign: 'center'}}>
                <Card className={classes.logForm}>
                    <CardContent>
                        <Grid container spacing={32}>
                            <Grid item xs={12}>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={this.logInHandler.bind(this)}
                                        variant="contained"
                                        color="primary"
                                        fullWidth={true}
                                >
                                    {labels.login}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained"
                                        color="secondary"
                                        onClick={this.props.onSignup}
                                        fullWidth={true}
                                >
                                    {labels.register_clinic}
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button onClick={this.props.onResetPassword}> {labels.forget_password}</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button href={`${process.env.REACT_APP_API_ROOT}swagger-ui/index.html#/`}> {labels.see_api_docs}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }

    logInHandler() {
        this.props.loginRequest();
        try {
            authenticationService.login()
        } catch (e) {
            this.props.loginFailed(e)
        }
    }
}


LandingMenu.propTypes = {
    onSignup: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {labels: labels.landing_page};
};

function mapStateToProps({auth}) {
    return auth
}

export default compose(
    withStyles(styles),
    translatable(mapTranslationsToProps),
    connect(mapStateToProps, {
        loginRequest,
        logoutRequest,
        logoutFailed,
    })
)(LandingMenu);