import React from 'react';
import Select from '@material-ui/core/Select'
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {translatable} from 'react-multilingual';
import {compose} from 'recompose';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SelectFieldComponent = ({input, label, name, meta: {touched, error}, children, className, required, labels, optionmap, changeLocale, ...props}) => (

    <FormControl error={error && touched} className={className} required={required}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
            label={label}
            {...input}
            onChange={(event) => input.onChange(event)}
            children={children}
            error={error && touched}
            name={name}
            optionmap={optionmap}
            MenuProps={MenuProps}
            {...props}
            changeLocale={changeLocale}
        />

        <FormHelperText
            error={touched && error !== undefined}>{touched && error} {!(touched && error) && required && labels.required}</FormHelperText>
    </FormControl>
);

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps)
)(SelectFieldComponent);