import React, {Component} from 'react';
import {compose} from 'recompose';
import {translatable} from 'react-multilingual';
import {ORGANIZATION_SERVICE} from "../common/services/availableServices";
import {PagedTableContainer} from "../common/components/PagedTable/PagedTableContainer";
import {columnType} from "../common/components/PagedTable/columnType";
import Button from "@material-ui/core/Button";
import PopupWindow from "../common/components/PopupWindow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PermissionContainer from "../Auth/PermissionContainer";
import {permission} from "../Auth/permissions";
import SearchTextField from "../common/fields/SearchTextField";
import {reduxForm} from "redux-form";
import OrganizationDetails from "./OrganizationDetails";
import {managePageStyles} from "../styles";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";

class ManageOrganizations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            criteria: null,
            openModal: false,
            organizationId: NaN,
            loadingTableData: true,
        };
        this.tableColumnSetup();
    }

    tableColumnSetup() {
        this.columns = [
            {key: 'name', type: columnType.text},
            {key: 'active', type: columnType.text, customCellRenderer: this.activeCellRenderer},
            {key: 'moderateThreshold', type: columnType.numeric},
            {key: 'severeThreshold', type: columnType.numeric},
            {key: 'details', type: columnType.numeric, customCellRenderer: this.detailsCellRenderer, sortable: false}
        ];
    }

    activeCellRenderer = (rowKey, columnValue) => {

        if (columnValue !== undefined)
            if (columnValue === true)
                return <div style={{width: 30}}>{this.props.labels.statuses.active}</div>
            else if (columnValue === false)
                return <div style={{width: 30}}>{this.props.labels.statuses.inactive}</div>

    };

    detailsCellRenderer = (rowId) => {
        return (
            <Button onClick={this.detailsButtonClickHandler(rowId)}>
                {this.props.labels.buttons.details}
            </Button>
        );
    };

    detailsButtonClickHandler = rowId => () => {
        this.setState({
            openModal: true,
            organizationId: rowId
        });
    };

    createButtonClickHandler = () => {
        this.setState({
            openModal: true,
            organizationId: NaN
        });
    };

    searchButtonClickHandler = values => {
        this.setState({
            criteria: {search: values.search},
            loadingTableData: true,
        });
    };

    closeWindowHandler = () => {
        this.setState({
            organizationId: NaN,
            openModal: false
        });
    };

    successHandler = () => {
        this.setState({
            organizationId: NaN,
            openModal: false,
            loadingTableData: true
        });
    };

    loadingDataFinishHandler = () => {
        this.setState({
            loadingTableData: false,
        })
    };

    render() {
        const {handleSubmit, labels, classes} = this.props;
        const specLabels = labels.manageOrganizations_labels;
        const {criteria, organizationId, openModal, loadingTableData} = this.state;

        return (
            <div>
                <PopupWindow open={openModal} onClose={this.closeWindowHandler}>
                    <OrganizationDetails
                        organizationId={organizationId}
                        onCancel={this.closeWindowHandler}
                        onSuccess={this.successHandler}
                    />
                </PopupWindow>

                <Grid container className={classes.header}>
                    <Grid item xs={8}>
                        <Typography variant="h5" color="primary">
                            {specLabels.page_title}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid container spacing={32} alignItems="center" justify="flex-end">
                            <Grid item>
                                <PermissionContainer
                                    allowedPermissions={[permission.organization_add]}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={this.createButtonClickHandler}>
                                        {specLabels.create_organization_button}
                                    </Button>
                                </PermissionContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
                    <Grid spacing={24} container className={classes.criteria}>
                        <Grid item md={4}>
                            <SearchTextField
                                name="search"
                                className={classes.criteriaField}
                            />
                        </Grid>
                        <Grid item container md={1} alignItems="center" justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                {labels.buttons.search}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <PagedTableContainer rowKey="id" orderBy="name" order="asc" columns={this.columns}
                                     criteria={criteria} service={ORGANIZATION_SERVICE}
                                     loadingData={loadingTableData}
                                     onLoadingDataFinish={this.loadingDataFinishHandler}
                                     errorMessage={labels.table.error_message}
                                     emptyResultMessage={labels.table.empty_result}
                                     columnLabels={specLabels.columns}
                />
            </div>
        );
    }
}

export default compose(
    withStyles(managePageStyles),
    translatable(({labels}) => ({labels})),
    reduxForm({form: 'OrganizationSearch'}),
)(ManageOrganizations);