import axios from "axios/index";

export default class UpdateResultNotesService {

    API_URL = null;


    constructor() {

        this.API_URL = process.env.REACT_APP_API_ROOT + 'detailedResult'

    }


    put(values, id) {
        let urlString = this.API_URL + '/' + id + '/notes';
        return axios.put(urlString, values, {headers: {"Content-Type": "text/plain"}})
    }


}

