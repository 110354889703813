import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '../common/fields/TextField';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import Grid from '@material-ui/core/Grid/Grid';
import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import {reduxForm} from 'redux-form';
import {compose} from 'redux';
import {required} from '../common/fieldValidators';
import PropTypes from 'prop-types';
import {translatable} from 'react-multilingual';

const styles = theme => ({
    header: {
        color: theme.palette.secondary
    }
});

class RejectBatch extends Component {

    submitHandler(values) {
        let newValues = Object.assign({}, this.props.formValues, values, {rejected: true});
        this.props.onSuccess(newValues);
    }

    render() {
        const {handleSubmit, classes, labels} = this.props;
        const translatedLabels = labels.create_batch;
        return (
            <form noValidate onSubmit={handleSubmit(this.submitHandler.bind(this))}>
                <Grid container spacing={32}>
                    <Grid item xs={12}>
                        <Typography className={classes.header}>
                            {translatedLabels.reject_batch} {this.props.batchNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {translatedLabels.reject_batch_line1}
                            <br/>
                            {translatedLabels.reject_batch_line2}
                            <br/>
                            {translatedLabels.reject_batch_line3}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <TextField name={"notes"}
                                       label={translatedLabels.reason_label}
                                       rows={5}
                                       multiline={true}
                                       validate={[required]}
                                       required/>
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={32} alignItems="center" justify="flex-end">
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.buttonMargin}
                            onClick={this.props.onCancel}>
                            {labels.buttons.cancel}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.buttonMargin}>
                            {labels.buttons.confirm}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

RejectBatch.propTypes = {
    batchNumber: PropTypes.string,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
};

export default compose(
    reduxForm({
        form: 'rejectBatch'
    }),
    translatable(mapTranslationsToProps),
    withStyles(styles))(RejectBatch);