import React, {Component} from 'react';
import {reduxForm} from 'redux-form'
import {RESET_PASSWORD_SERVICE} from "../common/services/availableServices";
import withStyles from "@material-ui/core/styles/withStyles";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import ResetLinkSent from "./ResetLinkSent";
import ResetEmailForm from "./ResetEmailForm";
import toastr from "toastr";
import LoadingIndicator from '../common/components/LoadingIndicator';
import CardContent from "../../node_modules/@material-ui/core/CardContent/CardContent";
import Card from "../../node_modules/@material-ui/core/Card/Card";

const styles = () => ({
    logForm: {
        width: '450px',
        display: 'inline-block',
        textAlign: 'left',
    }
});

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            loading: false
        };
    }

    getContent = (step) => {
        switch (step) {
            case 0:
                return <ResetEmailForm
                    labels={this.props.labels}
                    handleSubmit={this.props.handleSubmit}
                    pristine={this.props.pristine}
                    submitting={this.props.submitting}
                    submitHandler={this.submitHandler}
                    onHome={this.props.onHome}
                />;
            case 1:
                return ResetLinkSent(this.props);
            default:
        }
    };

    submitHandler = (values) => {
        this.setState({loading: true});
        RESET_PASSWORD_SERVICE.post(values['email']).then(() => {
            this.setState({step: 1, loading: false});
        }).catch(error => {
            this.setState({loading: false});
            if (!error.handledGlobally)
                toastr.error(this.props.labels.reset_password.error_message);

        });
    };

    render() {
        const {classes} = this.props;
        const {loading} = this.state;
        if (loading) {
            return (<LoadingIndicator />);
        }
        return (
            <div style={{textAlign: 'center'}}>
                <Card className={classes.logForm}>
                    <CardContent>
                        {this.getContent(this.state.step)}
                    </CardContent>
                </Card>
            </div>
        )
    }
}

const validate = () => {
    return {}
};

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    withStyles(styles),
    translatable(mapTranslationsToProps),
    reduxForm({
        form: 'reset',
        validate
    })
)(ResetPassword);

