import React from 'react';
import { Collapse, IconButton, Paper, Typography, withStyles } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { translatable } from 'react-multilingual';
import { compose } from 'recompose';

const styles = (theme) => ({
  faq: {
    border: '1px solid #D1D1D1',
    borderRadius: 8,
    width: '100%',
  },
  faqTitle: {
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    position: 'relative',
  },
  faqTitleExpanded: {
    '&:after': {
      content: "''",
      position: 'absolute',
      left: -1,
      top: -1,
      right: -1,
      bottom: 0,
      borderRadius: 8,
      border: '1px solid #D1D1D1',
      pointerEvents: 'none',
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapseContent: {
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 2,
  },
  description: {
    lineHeight: 1.2,
  }
});

const FAQItem = ({ question, answer, classes, labels, children }) => {
  const [expanded, setExpanded] = React.useState(false);

  const faqExpanded = expanded || typeof children !== 'undefined';

  return (
    <Paper elevation={0} className={classes.faq}>
      <div
        className={classNames(classes.faqTitle, {
          [classes.faqTitleExpanded]: faqExpanded,
        })}
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(prevExpanded => !prevExpanded);
        }}
      >
        <Typography variant="body1" color={"primary"}>{question}</Typography>
        {typeof children !== 'undefined' ? children : (
          <IconButton
            color="inherit"
            aria-expanded={faqExpanded}
            aria-label={labels.show_more}
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(prevExpanded => !prevExpanded);
            }}
            className={classNames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
          >
            <ExpandMore />
          </IconButton>
        )}
      </div>
      <Collapse in={faqExpanded} timeout="auto" unmountOnExit>
          <div className={classes.collapseContent}>
            <Typography className={classes.description}>
              {answer}
            </Typography>
          </div>
      </Collapse>
    </Paper>
  )
};
const mapTranslationsToProps = ({ labels }) => ({ labels: labels.faq_item });

export default compose(
  translatable(mapTranslationsToProps),
  withStyles(styles, { withTheme: true })
)(FAQItem);