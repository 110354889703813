import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";


export const IdleMonitor = ({secondsRemaining}) => {

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={true}
            message={<span>Session expires in {secondsRemaining} seconds</span>}
        />
    )
};


