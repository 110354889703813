import {Field} from "redux-form";
import React from "react";
import PropTypes from 'prop-types';
import TextFieldComponent from "./components/TextFieldComponent";


const TextField = (props, {name, label}) =>
    <Field name={name} component={TextFieldComponent} label={label} {...props}/>;


TextField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default TextField