import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import {EMPLOYEE_AVAILABLE_SERVICE} from '../../services/availableServices';
import ServiceSelectField from "./ServiceSelectField";


const customMapperHandler = (values) => {
    return values.map(item => {
        return {
            value: item.id,
            label: item.firstName + " " + item.lastName + " [" + item.clinicName + "]"
        }
    });

};


const AdminPhysiciansSelectField = ({label, name, criteria, ...other}) => {
    return <ServiceSelectField name={name} service={EMPLOYEE_AVAILABLE_SERVICE} customMapper={customMapperHandler}
                               label={label} criteria={criteria} {...other}/>;
};


AdminPhysiciansSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.select_field.physician};
};

export default translatable(mapTranslationsToProps)(AdminPhysiciansSelectField);