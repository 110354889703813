import axios from "axios/index";

export default class ResetPasswordService {

    API_URL = null;

    constructor() {
        this.API_URL = process.env.REACT_APP_API_ROOT + 'reset-password';
    }

    post(email) {
        return axios.post(this.API_URL, email, {headers: {"Content-Type": "text/plain"}})
    }
}