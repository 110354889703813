import React from "react";
import Chip from '@material-ui/core/Chip/Chip';
import {withStyles} from '@material-ui/core';

const styles = theme => ({

    label: {
        color: 'black',
    },

});

const classificationColors = {
    MILD: '#00a65a',
    MODERATE: '#ff851b',
    SEVERE: '#f56954',
}


const ClassificationComponent = ({classification, classes}) => {

    const inlineStyle = {
        background: classificationColors[classification]
    }

    return <Chip label={classification} style={inlineStyle} classes={{label: classes.label}}/>

};
export default withStyles(styles)(ClassificationComponent);