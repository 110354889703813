import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import image from '../img/ce_mark.png'

const styles = () => ({
    container: {
        position: 'absolute',
        right: '0',
        bottom: '0',
    },
    image: {
        height: '40px',
        width: 'auto',
    },
});

class CEMark extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                <img src={image} alt="CE Marking" className={classes.image} />
            </div>
        )
    }
}

export default withStyles(styles)(CEMark);
