import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import SelectField from "./selectFields/SelectField";


const ActiveSelectField = ({name, labels, className}) => {

    const activeTranslation = labels.active;


    return <SelectField name={name} className={className} label={labels.fields.status} optionmap={activeTranslation}/>

};

ActiveSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => ({labels});
export default translatable(mapTranslationsToProps)(ActiveSelectField);