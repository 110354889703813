import React from "react";
import PropTypes from 'prop-types';
import { translatable } from "react-multilingual";
import SelectField from './SelectField';

const intervals = (intervals_labels) => [
    {value: 'DAYS', label: intervals_labels.interval_day},
    {value: 'WEEKS', label: intervals_labels.interval_week},
    {value: 'MONTHS', label: intervals_labels.interval_month},
    {value: 'YEARS', label: intervals_labels.interval_year}]
;


const FrequencySelectField = ({labels: {label, intervals_labels}, name, ...other}) => {
  return <SelectField name={name} optionmap={intervals(intervals_labels)}
                      label={label} {...other}/>;
};

FrequencySelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {
        labels:
            {
                label: labels.select_field.frequency_type,
                intervals_labels: labels.patient_details
            }
    }
};

export default translatable(mapTranslationsToProps)(FrequencySelectField);