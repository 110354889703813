import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "../common/fields/TextField";
import {email} from "../common/fieldValidators";
import Button from "@material-ui/core/Button";
import React from "react";
import {Link} from "react-router-dom";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import {compose} from "recompose";

const styles = () => ({
    prevButton: {
        float: "left"
    },
    nextButton: {
        float: "right",
    }
});

class ResetEmailForm extends React.Component {
    render() {
        const {handleSubmit, pristine, submitting, submitHandler, classes} = this.props;
        return (
            <form onSubmit={handleSubmit(submitHandler)}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h6" gutterBottom>
                            {this.props.labels.reset_password.title}
                        </Typography>
                        <Typography>
                            {this.props.labels.reset_password.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            name={"email"}
                            label={"email"}
                            validate={[email]}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container justify={"space-between"}>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.props.onHome}
                                    component={Link}
                                    to={'/'}
                                    className={classes.prevButton}
                                >
                                    {this.props.labels.buttons.home}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={pristine || submitting}
                                    className={classes.nextButton}
                                >
                                    {this.props.labels.buttons.send}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default compose(
    withStyles(styles),
)(ResetEmailForm);