import React from 'react';
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const primary = '#28407F';
const secondary = '#BC0934';
const surface = '#ffffff';
const surfaceDark = '#f9f9f9';
// const focusAndActive = '#3B6DB1';
// const textAndIcons = '#000000';
// const inactiveContent = '#000000';

const drawerWidth = 240;

export const styles = createMuiTheme({


    palette: {
        primary: {
            main: primary
        },
        secondary: {
            main: secondary
        },
        surface: {
            main: surface,
            dark: surfaceDark,
        },

    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiAppBar: {
            root: {
                marginBottom: '1px'
            },
        },
        MuiToolbar: {
            root: {
                backgroundColor: surface,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: surfaceDark,
                position: 'relative',
                width: drawerWidth,

            }
        },
        MuiButton: {
            root: {
                color: primary,
                minWidth: 83
            }

        },
        MuiCheckbox: {
            root: {
                color: secondary,
            },
            checked: {
                color: secondary,
            },
        },


    }


});

export const managePageStyles = theme => ({
    header: {
        paddingBottom: theme.spacing.unit * 4,
    },
    criteria: {
        paddingBottom: theme.spacing.unit * 4,
    },
    criteriaField: {
        width: "100%"
    },

});

export const withTheme = (Component) => (props) => (
    <MuiThemeProvider theme={styles}>
        <Component {...props} />
    </MuiThemeProvider>
);

