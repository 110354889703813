import React from 'react';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "@material-ui/core/Button/Button";
import Icon from "@material-ui/core/Icon/Icon";

export const fabAddButton = (title, handler) => {
    return (
        <Tooltip title={title}>
            <Button style={{float: 'right', marginTop: -20, marginRight: -20}} variant="fab" color="primary"
                    aria-label="Add" size='medium'
                    onClick={handler}>
                <Icon>add</Icon>
            </Button>
        </Tooltip>);

};