import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Typography } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import { Close } from '@material-ui/icons';

const SimpleDialog = ({ title, text, closeText = 'Cancel', confirmText = 'Confirm', confirmColor = 'primary', resolve, reject }) => {
  const handleClose = () => {
    reject(undefined);
  };
  const handleConfirm = () => {
    resolve();
  };
  return (
    <Dialog open={true} maxWidth="md" fullWidth style={{ padding: 12, width: '100%' }} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Typography id="form-dialog-title" color="primary" style={{ padding: '20px 48px 30px 24px', maxWidth: 640, fontSize: 26, fontWeight: '500' }}>{title}</Typography>
      <IconButton style={{ position: 'absolute', top: 4, right: 4 }}>
        <Close onClick={handleClose} />
      </IconButton>
      <DialogContent>
        {text && <DialogContentText color="primary">{text}</DialogContentText>}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', }}>
          <Button onClick={handleClose} variant="outlined" color="primary">{closeText}</Button>
          <Button style={{ paddingLeft: 60, paddingRight: 60 }} onClick={handleConfirm} color={confirmColor} variant="contained">{confirmText}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SimpleDialog;
