import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import ReactCountryFlag from "react-country-flag";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {connect} from "react-redux";
import {compose} from "redux";
import {translatable} from "react-multilingual";


const languages = {
    pl: 'pl',
    en: 'us',
    fr: 'fr',
};


class ChangeLanguageComponent extends Component {

    constructor(props) {
        super();

        this.state = {
            anchor: null,
            lang: props.lang
        };
    }


    handleClick = event => {
        this.setState({
            ...this.state,
            anchor: event.currentTarget
        });

    };

    handleClose = (lang) => {
        if (typeof lang !== 'string')
            lang = this.state.lang;
        this.setState({
            lang: lang,
            anchor: null
        });
    };

    FlagItem = (langCode) => {
        const flagCode = languages[langCode];
        const {changeLocale} = this.props;
        return <MenuItem key={langCode} onClick={() => {
            changeLocale(langCode);
            this.handleClose(langCode)
        }}> <ReactCountryFlag code={flagCode} svg/></MenuItem>
    };


    render() {

        const {lang, anchor} = this.state;

        return <React.Fragment>
            <Button
                aria-owns={anchor ? 'lang-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
            >
                <ReactCountryFlag code={languages[lang]} svg/>
            </Button>

            <Menu
                id="lang-menu"
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={this.handleClose}
            >

                {Object.keys(languages).map(langCode => {

                    return this.FlagItem(langCode)
                })}
            </Menu>
        </React.Fragment>
    };

}


const mapStateToProps = state => {
    return {
        lang: state.locale
    };
};


export default compose(connect(mapStateToProps), translatable())(ChangeLanguageComponent)



