import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import {Link} from "react-router-dom";
import {ListItem, ListItemText} from "@material-ui/core/index";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PermissionContainer from "../Auth/PermissionContainer";
import {permission} from "../Auth/permissions";
import {docEnum} from "../Documents/DocNamesEnum";
import {roles} from '../Auth/roles';


const styles = theme => ({

    root: {
        maxWidth: 10,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});


class Menu extends React.Component {

    state = {
        open_doc: false,
        open_patients: false,
    };

    openDoc = () => {
        this.setState(state => ({open_doc: !state.open_doc}));
    };

    openPatients = () => {
        this.setState(state => ({open_patients: !state.open_patients}));
    };

    render() {
        const {classes, labels} = this.props;
        let menu = labels.menu_labels;

        return (
            <Drawer
                variant="persistent"
                anchor="left"
                open={true}
            >
                <List style={{paddingBottom: 65}}>
                    <PermissionContainer
                        allowedPermissions={[
                            permission.documents_add_version,
                            permission.documents_edit,
                            permission.documents_publish]}>
                        <ListItem button onClick={this.openDoc}>
                            <ListItemText primary={menu.documents}/>
                            {this.state.open_doc ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.open_doc} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                {Object.keys(docEnum).map(value => {
                                    const toPath = "/documents/" + encodeURI(value);
                                    return <ListItem key={value} button className={classes.nested} component={Link}
                                                     to={toPath}>
                                        <ListItemText primary={docEnum[value]}/>
                                    </ListItem>

                                })}
                            </List>
                        </Collapse>

                        <Divider/>
                    </PermissionContainer>

                    <PermissionContainer
                        allowedPermissions={[permission.organization_add]}>
                        <ListItem button component={Link} to="/organizations/manage_organizations"
                                  onClick={this.handleClick}>
                            <ListItemText primary={menu.organizations}/>
                        </ListItem>
                        <Divider/>
                    </PermissionContainer>


                    <PermissionContainer
                        allowedPermissions={[
                            permission.clinic_view_all]}>
                        <ListItem button component={Link} to="/clinics/manage_clinics" onClick={this.handleClick}>
                            <ListItemText primary={menu.clinics}/>
                        </ListItem>
                        <Divider/>
                    </PermissionContainer>


                    <PermissionContainer
                        allowedPermissions={[
                            permission.employee_view_all,
                            permission.employee_view_clinic]}>
                        <ListItem button component={Link} to="/users/manage_staff_users" onClick={this.handleClick}>
                            <ListItemText primary={menu.staff_users}/>
                        </ListItem>
                        <Divider/>
                    </PermissionContainer>


                    <PermissionContainer
                        allowedPermissions={[
                            permission.patient_view_all,
                            permission.patient_view_clinic,
                            permission.patient_view_your,
                            permission.patientResult_view_all,
                            permission.patientResult_view_clinic,
                            permission.patientResult_view_your,
                            permission.pendingResults_view_all,
                            permission.pendingResults_view_clinic,
                            permission.pendingResults_view_your
                        ]}>
                        <ListItem button onClick={this.openPatients}>
                            <ListItemText primary={menu.patients}/>
                            {this.state.open_patients ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={this.state.open_patients} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                <PermissionContainer
                                    allowedPermissions={[
                                        permission.patient_view_all,
                                        permission.patient_view_clinic,
                                        permission.patient_view_your]}>
                                    <ListItem button className={classes.nested} component={Link}
                                              to="/patients/manage_patients"
                                              onClick={this.handleClick}>
                                        <ListItemText primary={menu.manage_patients}/>
                                    </ListItem>
                                </PermissionContainer>

                                <PermissionContainer
                                    allowedPermissions={[
                                        permission.patientResult_view_all,
                                        permission.patientResult_view_clinic,
                                        permission.patientResult_view_your]}>
                                    <ListItem button className={classes.nested} component={Link} to="/patients/results"
                                              onClick={this.handleClick}>
                                        <ListItemText primary={menu.results}/>
                                    </ListItem>
                                </PermissionContainer>

                                <PermissionContainer allowedPermissions={[
                                    permission.pendingResults_view_all,
                                    permission.pendingResults_view_clinic,
                                    permission.pendingResults_view_your]}>
                                    <ListItem button className={classes.nested} component={Link}
                                              to="/patients/pending_results"
                                              onClick={this.handleClick}>
                                        <ListItemText primary={menu.pending_results}/>
                                    </ListItem>
                                </PermissionContainer>

                            </List>
                        </Collapse>
                        <Divider/>
                    </PermissionContainer>

                    <PermissionContainer allowedPermissions={[permission.batches_view_all]}>
                        <ListItem button component={Link} to="/batches/manage_batches" onClick={this.handleClick}>
                            <ListItemText primary={menu.batches}/>
                        </ListItem>
                        <Divider/>
                    </PermissionContainer>

                    <PermissionContainer allowedRoles={[roles.ADMINISTRATOR]}>
                        <ListItem button component={Link} to="/faq-management" onClick={this.handleClick}>
                            <ListItemText primary={menu.faq} />
                        </ListItem>
                        <Divider />
                    </PermissionContainer>

                    <PermissionContainer exceptRoles={[roles.ADMINISTRATOR]}>
                        <ListItem button component={Link} to="/get-support" onClick={this.handleClick}>
                            <ListItemText primary={menu.support} />
                        </ListItem>
                        <Divider />
                    </PermissionContainer>
                </List>
            </Drawer>
        );

    }

}


const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(Menu);
