import auth0 from 'auth0-js';

export default class AuthenticationService {

    constructor() {

        let authConfig = process.env;

        this.auth0 = new auth0.WebAuth({
            domain: authConfig.REACT_APP_AUTH_DOMAIN,
            clientID: authConfig.REACT_APP_AUTH_CLIENT_ID,
            redirectUri: authConfig.REACT_APP_AUTH_REDIRECT_URI,
            audience: authConfig.REACT_APP_AUTH_AUDIENCE,
            responseType: authConfig.REACT_APP_AUTH_RESPONSE_TYPE,
            scope: authConfig.REACT_APP_AUTH_SCOPE
        });

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.acquireNewToken = this.acquireNewToken.bind(this);
    }


    login() {

        this.auth0.authorize();
    }


    processAuthentication(onAuthenticationSuccess, onAuthenticationFailed) {

        this.auth0.parseHash((error, authResult) => {

            if (authResult) {

                if (authResult.accessToken)
                    return onAuthenticationSuccess(authResult);
                else
                    return onAuthenticationFailed("Access token is missing.")


            } else {
                return onAuthenticationFailed(error)

            }

        });

    }

    acquireNewToken(onAcquireNewTokenSuccess, onAcquireNewTokenFailed) {
        this.auth0.checkSession({}, function (error, authResult) {

            if (authResult) {
                if (authResult.accessToken)
                    return onAcquireNewTokenSuccess(authResult)
            } else {
                return onAcquireNewTokenFailed(error)

            }

        });

    }


    logout() {
        this.auth0.logout({})
    }


}