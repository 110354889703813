export const roles = {
    ADMINISTRATOR: 'ADMINISTRATOR',
    ORGANIZATION_ADMINISTRATOR: 'ORGANIZATION_ADMINISTRATOR',
    QUALITY_CONTROL: 'QUALITY_CONTROL',
    CLINIC_ADMINISTRATOR: 'CLINIC_ADMINISTRATOR',
    CLINIC_LAB: 'CLINIC_LAB',
    CLINIC_USER: 'CLINIC_USER',
    CLINIC_USER_FULL_INSIGHT: 'CLINIC_USER_FULL_INSIGHT',
    API_USER: 'API_USER',
    C4T_USER: 'C4T_USER',
    INTEGRATION_USER: 'INTEGRATION_USER'
    
};