import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";

const ResetLinkSent = (props) => {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" gutterBottom>
                    {props.labels.reset_password.success_title}
                </Typography>
                <Typography>
                    {props.labels.reset_password.success}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container justify={"flex-end"}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onHome}
                        component={Link}
                        to={'/'}
                    >
                        {props.labels.buttons.home}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ResetLinkSent;