import React from 'react';
import toastr from "toastr";
import { Prompt, useLocation } from 'react-router-dom';
import { translatable } from "react-multilingual";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { managePageStyles } from "../styles";
import { Button, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import FAQItem from '../common/components/FAQItem';
import { KeyboardBackspaceSharp, DeleteOutline, EditTwoTone, ExpandMore, CheckBoxOutlined } from '@material-ui/icons';
import AddOrEditFAQ from './AddOrEditFAQ';
import { ADMIN_FAQ_QUESTIONS_SERVICE } from '../common/services/availableServices';
import LoadingIndicator from '../common/components/LoadingIndicator';
import { useBeforeUnload } from '../common/hooks/useBeforeUnload';

const styles = (theme) => ({
  container: {
    height: 'calc(100% - 50px)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  faqContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    overflow: 'auto',
  },
  heading: {
    marginBottom: 24,
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  outlinedButton: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  select: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px !important',
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.primary.main,
    textAlign: 'left',
    padding: '0.5rem 4rem 0.5rem 0.75rem',
  },
  selectDisabled: {
    border: 'none',
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  icon: {
    color: theme.palette.common.black,
    marginRight: theme.spacing.unit,
  },
  selectOutlined: {
    border: '0 !important',
  },
  editSuffix: {
    textTransform: 'uppercase',
  },
  faqActions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.unit,
  },
  faqActionsButton: {
    cursor: 'pointer',
    background: 'transparent',
    border: 0,
    padding: [theme.spacing.unit / 2, 0],
    height: 48,

    '&:not(:disabled)': {
      color: theme.palette.primary.main,
    },

    '&:disabled': {
      color: '#D9D9D9',
    }
  },
  faqItemContainer: {
    display: 'flex',
    gap: '6px',
    width: '100%',
  },
  faqItemNumber: {
    marginTop: '0.75rem',
  },
});

const EDIT_ACTIONS = {
  NONE: 'NONE',
  DELETE: 'DELETE',
  ADD: 'ADD',
  EDIT: 'EDIT',
  REORDER: 'REORDER'
}

const faqItemsToEditData = (faqItems) => {
  return faqItems.map(faq => {
    return {
      id: faq.id,
      faq,
      action: EDIT_ACTIONS.NONE,
      order: faq.order,
    };
  });
};

const prepareTranslations = (translations, action) => {
  return translations.map(translation => ({
    answer: {
      ...translation.answer,
      base64EncodedPhoto: translation.answer.base64EncodedPhoto ? translation.answer.base64EncodedPhoto.split('base64,')[1] : undefined,
      photoName: undefined,
    },
    question: translation.question,
    ...(action === EDIT_ACTIONS.ADD && { language: translation.languageCode }),
    ...(action === EDIT_ACTIONS.EDIT && { languageCode: translation.languageCode }),
  }));
};

const useAdminFaqs = (userType) => {
  const [loading, setLoading] = React.useState(false);
  const [faqItems, setFaqItems] = React.useState(undefined);

  const handleFetchFaqs = React.useCallback(() => {
    setLoading(true);
    return ADMIN_FAQ_QUESTIONS_SERVICE.getWithRequestParams({ userType })
      .then((response) => {
        setFaqItems(response.data);
      }).finally(() => {
        setLoading(false);
      });
  }, [userType])

  React.useEffect(() => {
    handleFetchFaqs();
  }, [handleFetchFaqs]);

  return {
    data: faqItems,
    loading,
    refetch: handleFetchFaqs,
  }
};

const handlePromise = (promise) => {
  return promise
    .then(result => ({ success: true, result }))
    .catch(error => ({ success: false, error }))
}

const options = ['EMPLOYEE', 'PATIENT']

const ManageFAQ = ({ classes, labels, location, history }) => {
  const params = new URLSearchParams(location.search);
  const paramsUserType = params.get('userType');
  const [editMode, setEditMode] = React.useState(false);
  const [dialog, setDialog] = React.useState({ open: false, id: undefined, initialData: null });
  const [userType, setUserType] = React.useState(options.includes(paramsUserType) ? paramsUserType : 'PATIENT');
  const [updating, setUpdating] = React.useState(false);
  const { data: faqItems, loading, refetch } = useAdminFaqs(userType);
  const [editData, setEditData] = React.useState([]);

  React.useEffect(() => {
    const newUserType = options.includes(paramsUserType) ? paramsUserType : 'PATIENT';
    setUserType(newUserType);
    setEditMode(false);
    setEditData([]);
  }, [paramsUserType]);

  const filteredEditData = React.useMemo(() => editData.filter(item => item.action !== EDIT_ACTIONS.DELETE), [editData]);

  const handleDeleteItem = React.useCallback((id) => {
    if (id < 0) {
      setEditData(prevEditData => prevEditData.filter(item => item.id !== id));
    } else {
      setEditData(prevEditData => prevEditData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            action: EDIT_ACTIONS.DELETE,
          };
        }
        return item;
      }))
    }
  }, []);

  const handleReorderItem = React.useCallback((direction, itemIndex) => {
    if (direction === 'up') {
      const prevItem = filteredEditData[itemIndex - 1];
      const currentItem = filteredEditData[itemIndex];
      setEditData(prevEditData => prevEditData.map(item => {
        if (item.id === prevItem.id) {
          return {
            ...currentItem,
            order: item.order,
            action: currentItem.action === EDIT_ACTIONS.NONE ? EDIT_ACTIONS.REORDER : currentItem.action,
          };
        }
        if (item.id === currentItem.id) {
          return {
            ...prevItem,
            order: item.order,
            action: prevItem.action === EDIT_ACTIONS.NONE ? EDIT_ACTIONS.REORDER : prevItem.action,
          };
        }
        return item;
      }));
    }
    if (direction === 'down') {
      const nextItem = filteredEditData[itemIndex + 1];
      const currentItem = filteredEditData[itemIndex];
      setEditData(prevEditData => prevEditData.map(item => {
        if (item.id === currentItem.id) {
          return {
            ...nextItem,
            order: item.order,
            action: nextItem.action === EDIT_ACTIONS.NONE ? EDIT_ACTIONS.REORDER : nextItem.action,
          };
        }
        if (item.id === nextItem.id) {
          return {
            ...currentItem,
            order: item.order,
            action: currentItem.action === EDIT_ACTIONS.NONE ? EDIT_ACTIONS.REORDER : currentItem.action,
          };
        }
        return item;
      }));
    }
  }, [filteredEditData]);

  const dirty = editData.length !== 0 && editData.some(item => item.action !== EDIT_ACTIONS.NONE);

  useBeforeUnload(dirty);

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <div className={classes.pageTitle}>
          <Typography variant="h5" color="primary">
            {labels.page_title}{editMode ? <span className={classes.editSuffix}> - {labels.edit}</span> : undefined}
          </Typography>
          <div className={classes.actions}>
            <TextField
              select
              size="small"
              variant="outlined"
              SelectProps={{
                classes: {
                  select: classes.select,
                  icon: classes.icon,
                  disabled: classes.selectDisabled,
                },
                IconComponent: ExpandMore,
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.selectOutlined,
                }
              }}
              value={userType}
              onChange={(e) => {
                history.push({
                  pathname: history.location.pathname,
                  search: `?userType=${e.target.value}`,
                });
              }}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {labels.selectOptions[option]}
                </MenuItem>
              ))}
            </TextField>
            {editMode ? (
              <>
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  classes={{
                    root: classes.outlinedButton,
                  }}
                  onClick={() => {
                    const lowestId = Math.min(...editData.map(item => item.id));
                    setDialog({ open: true, id: lowestId < 0 ? lowestId - 1 : -1, initialData: null });
                  }}>
                  {labels.addNew}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={editData.length === 0 || editData.every(item => item.action === EDIT_ACTIONS.NONE)}
                  onClick={() => {
                    const { deletePromises, addPromises, editPromises, reorderPromises } = editData.reduce((acc, item) => {
                      if (item.action === EDIT_ACTIONS.DELETE) {
                        return {
                          ...acc,
                          deletePromises: [
                            ...acc.deletePromises,
                            handlePromise(ADMIN_FAQ_QUESTIONS_SERVICE.delete(item.id)),
                          ],
                        };
                      }
                      if (item.action === EDIT_ACTIONS.ADD) {
                        return {
                          ...acc,
                          addPromises: [
                            ...acc.addPromises,
                            handlePromise(ADMIN_FAQ_QUESTIONS_SERVICE.post({
                              translations: prepareTranslations(item.values, item.action),
                              userType,
                              order: typeof item.order === 'number' ? item.order : undefined,
                            })),
                          ],
                        }
                      }
                      const orderChanged = faqItems.find(faq => faq.id === item.id).order !== item.order;
                      if (orderChanged) {
                        acc.reorderPromises = [
                          ...acc.reorderPromises,
                          handlePromise(ADMIN_FAQ_QUESTIONS_SERVICE.patch(item.id, '/order', { order: item.order })),
                        ];
                      }
                      if (item.action === EDIT_ACTIONS.EDIT) {
                        return {
                          ...acc,
                          editPromises: [
                            ...acc.editPromises,
                            handlePromise(ADMIN_FAQ_QUESTIONS_SERVICE.put({
                              translations: prepareTranslations(item.values, item.action),
                              userType,
                            }, item.id)),
                          ]
                        }
                      };
                      return acc;
                    }, { deletePromises: [], addPromises: [], editPromises: [], reorderPromises: [] });

                    setUpdating(true);
                    return Promise.all([...deletePromises, ...addPromises, ...editPromises, ...reorderPromises]).then((results) => {
                      setUpdating(false);
                      if (results.some(result => !result.success)) {
                        toastr.error('Something went wrong');
                      }
                      refetch().then(() => {
                        setEditMode(false);
                        setEditData([]);
                      })
                    })
                  }}
                >
                  {labels.publish}
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => {
                  setEditMode(true);
                  setEditData(faqItemsToEditData(faqItems));
                }}>
                {labels.edit}
              </Button>
            )}
          </div>
        </div>
      </div>
      {(loading || updating) && <LoadingIndicator />}
      <div className={classes.faqContainer}>
        {editMode ? (
          <>
            {filteredEditData.map((item, index, array) => {
              return (
                <div key={item.id} className={classes.faqItemContainer}>
                  <Typography variant="h5" color="primary" className={classes.faqItemNumber}>
                    {index + 1}.
                  </Typography>
                  <FAQItem question={item.faq.question.text} answer={item.faq.answer.text}>
                    <div className={classes.faqActions}>
                      <button
                        disabled={index === 0}
                        type="button"
                        className={classes.faqActionsButton}
                        onClick={() => handleReorderItem('up', index)}
                      >
                        <KeyboardBackspaceSharp style={{ rotate: '90deg' }} size={16} />
                      </button>
                      <button
                        disabled={index === array.length - 1}
                        type="button"
                        className={classes.faqActionsButton}
                        onClick={() => handleReorderItem('down', index)}
                      >
                        <KeyboardBackspaceSharp style={{ rotate: '-90deg' }} size={16} />
                      </button>
                      <button
                        type="button"
                        className={classes.faqActionsButton}
                        onClick={() => setDialog({
                          open: true,
                          id: item.id,
                          initialData: item.values ? item.values : null,
                        })}
                      >
                        <EditTwoTone size={16} />
                      </button>
                      <button type="button" className={classes.faqActionsButton} onClick={() => handleDeleteItem(item.id)}>
                        <DeleteOutline size={16} />
                      </button>
                    </div>
                  </FAQItem>
                </div>
              )
            })}
          </>
        ) : (
          <>
            {faqItems && faqItems.map((item) => (
              <div key={item.id} className={classes.faqItemContainer}>
                <FAQItem question={item.question.text} answer={item.answer.text} />
              </div>
            ))}
          </>
        )}
      </div>
      <AddOrEditFAQ
        open={dialog.open}
        id={dialog.id}
        initialData={dialog.initialData}
        userType={userType}
        onClose={() => {
          setDialog({ open: false, id: undefined, initialData: null });
        }}
        onSave={({ id, values }) => {
          const translations = Object.entries(values).map(entry => ({
            languageCode: entry[0],
            ...entry[1],
          }));
          setEditData(prevEditData => {
            const prevOrders = prevEditData.map(item => item.order);
            const prevItemIndex = prevEditData.findIndex(item => item.id === id);
            const newFaq = {
                question: {
                  text: values['EN'] ? values['EN'].question.text : '(missing English translation)',
                },
                answer: {
                  text: values['EN'] ? values['EN'].answer.text : '(missing English translation)',
                },
              };
            if (prevItemIndex !== -1) {
              // edit existing faq or new, just created but not saved faq
              return [
                ...prevEditData.slice(0, prevItemIndex),
                {
                  ...prevEditData[prevItemIndex],
                  id,
                  action: id < 0 ? EDIT_ACTIONS.ADD : EDIT_ACTIONS.EDIT,
                  values: translations,
                  faq: newFaq,

                },
                ...prevEditData.slice(prevItemIndex + 1)
              ];
            }
            // add new item
            const newOrder = Math.max(...prevOrders) + 1;
            return [...prevEditData, {
              id,
              action: EDIT_ACTIONS.ADD,
              values: translations,
              faq: newFaq,
              order: newOrder < 0 ? 0 : newOrder,
            }];
          });
          setDialog({ open: false, id: undefined, initialData: null });
        }}
      />
      <Prompt
        message={() => {
          if (dirty) {
            return 'You haven\'t published the changes you have made. Are you sure you want to leave this page?';
          }

          return true;
        }}
      />
    </div>
  )
};

const mapTranslationsToProps = ({labels}) => ({labels: labels.faq});

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(
        (theme) => ({
            ...managePageStyles(theme),
            ...styles(theme),
        }),
        {withTheme: true},
    )
)(ManageFAQ);
