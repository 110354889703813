import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {withStyles} from "@material-ui/core/styles/index";


const styles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit * 2.5,
    },
});


const PagedTablePageNavigator = ({classes, count, page, rowsPerPage, onChangePage}) => {

    const clickFirstPageButtonHandler = () => {
        onChangePage(0);
    };

    const clickBackButtonHandler = () => {
        onChangePage(page - 1);
    };

    const clickNextButtonHandler = () => {
        onChangePage(page + 1);
    };

    const clickLastPageButtonHandler = () => {
        onChangePage(
            Math.max(0, Math.ceil(count / rowsPerPage) - 1),
        );
    };


    return (
        <div className={classes.root}>
            <IconButton
                onClick={clickFirstPageButtonHandler}
                disabled={page === 0}
                aria-label="First Page"
            >
                <FirstPageIcon/>
            </IconButton>
            <IconButton
                onClick={clickBackButtonHandler}
                disabled={page === 0}
                aria-label="Previous Page"
            >
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={clickNextButtonHandler}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={clickLastPageButtonHandler}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
            >
                <LastPageIcon/>
            </IconButton>
        </div>
    );

};


export default withStyles(styles)(PagedTablePageNavigator);

