import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {withStyles} from '@material-ui/core/styles';
import {translatable} from 'react-multilingual';
import {compose} from 'recompose';
import ClassificationComponent from "../common/ClassificationComponent";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import toastr from "toastr";
import {PENDING_RESULT_SERVICE, RESULT_SERVICE} from "../../common/services/availableServices";
import PermissionContainer from "../../Auth/PermissionContainer";
import {permission} from "../../Auth/permissions";
import HistoryGraph from "../common/Graphs/HistoryGraph";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import ReassignPatientComponent from "./ReassignPatientComponent";
import Utils from "../../common/Utils";

const styles = theme => ({
    root: {
        paddingLeft: 16,
        paddingRight: 16,
    },

    iconButton: {
        fontSize: 21,
        width: 28,
        height: 28,
    },

});

class PendingResultBasicView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            editPatientMode: false,
        };
        this.acceptButtonClickHandler = this.acceptButtonClickHandler.bind(this);
        this.editPatientButtonClickHandler = this.editPatientButtonClickHandler.bind(this);
        this.cancelReassignPatientHandler = this.cancelReassignPatientHandler.bind(this);
        this.successReassignPatientHandler = this.successReassignPatientHandler.bind(this);
    }

    componentDidMount() {

        this.fetchResult(this.props.resultId);
    }


    fetchResult(id) {

        RESULT_SERVICE.get(id).then(response => {
            this.setState({
                result: response.data,
                loading: false
            });
        }).catch(error => {
            toastr.error(error.response.data.message)
        })
    }

    acceptButtonClickHandler(id) {
        PENDING_RESULT_SERVICE.put(null, id).then(response => {
            this.props.onClose();
            toastr.success("Result accepted!");
        }).catch(error => {
            console.error(error);
            if (typeof error.response === "undefined")
                toastr.error(error.response.data.message);
        })
    }

    rejectButtonClickHandler(id) {
        PENDING_RESULT_SERVICE.delete(id).then(response => {
            this.props.onClose();
            toastr.success("Result rejected!");
        }).catch(error => {
            console.error(error);
            if (typeof error.response === "undefined")
                toastr.error(error.response.data.message);
        })
    }

    editPatientButtonClickHandler() {

        this.setState({
            editPatientMode: true
        })
    }

    cancelReassignPatientHandler() {

        this.setState({
            editPatientMode: false
        })
    }

    successReassignPatientHandler() {

        this.setState({
            editPatientMode: false,
            loading: true
        });
        this.fetchResult(this.props.resultId)
    }

    render() {
        const {classes, labels, onClose} = this.props;
        const buttonLabels = labels.buttons;
        const specLabels = labels.pending_result_basic_view;
        const {result, loading, editPatientMode} = this.state;

        if (loading)
            return (<LoadingIndicator/>);

        return (
            <React.Fragment>
                <Grid container className={classes.root}>
                    <Grid container item xs={5}>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.concentration}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="h6" paragraph={true}>
                                {result.finalConcentration} mg/kg
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.classification}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <ClassificationComponent classification={result.overallResult}/>
                        </Grid>
                        {!editPatientMode &&
                        <React.Fragment>
                            <Grid className="summary-grid" item xs={4}>
                                <Typography variant="subtitle1" paragraph={true}>
                                    {specLabels.patient}:
                                    <PermissionContainer
                                        allowedPermissions={[permission.pendingResults_changePatient_all, permission.pendingResults_changePatient_your]}>
                                        <IconButton color="primary" className={classes.iconButton}
                                                    onClick={this.editPatientButtonClickHandler}>
                                            <EditIcon fontSize='inherit'/>
                                        </IconButton>
                                    </PermissionContainer>
                                </Typography>
                            </Grid>
                            <Grid className="summary-grid" item xs={8}>
                                <Typography variant="subtitle1" paragraph={true}>
                                    {result.patientInfo}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        }
                        {editPatientMode &&
                        <Grid className="summary-grid" item xs={12}>
                            <div style={{paddingBottom: 11}}>
                                <ReassignPatientComponent onCancel={this.cancelReassignPatientHandler}
                                                          onSuccess={this.successReassignPatientHandler}
                                                          resultId={result.id}/>
                            </div>
                        </Grid>
                        }
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.patientId}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {result.externalPatientId}
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={4}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {specLabels.date}:
                            </Typography>
                        </Grid>
                        <Grid className="summary-grid" item xs={8}>
                            <Typography variant="subtitle1" paragraph={true}>
                                {Utils.dateRenderer(result.date)}
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={8}>
                                <PermissionContainer
                                    allowedPermissions={[permission.pendingResults_accept_all, permission.pendingResults_accept_your]}>
                                    <Button
                                        variant="contained" color="primary"
                                        onClick={() => this.acceptButtonClickHandler(result.id)}
                                        disabled={editPatientMode}
                                    >
                                        {buttonLabels.accept}
                                    </Button>
                                </PermissionContainer>
                                <PermissionContainer
                                    allowedPermissions={[permission.pendingResults_reject_all, permission.pendingResults_reject_your]}>
                                    <Button
                                        variant="contained" color="secondary"
                                        style={{marginLeft: 15}}
                                        onClick={() => this.rejectButtonClickHandler(result.id)}
                                        disabled={editPatientMode}
                                    >
                                        {buttonLabels.reject}
                                    </Button>
                                </PermissionContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={7}>
                        <HistoryGraph patientId={result.patientId} resultId={result.id}/>
                    </Grid>
                </Grid>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    padding: 16
                }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                    >
                        {buttonLabels.close}
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}


const mapTranslationsToProps = ({labels}) => ({labels});
export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(PendingResultBasicView);