import React, {Component} from 'react';
import TextField from "../common/fields/TextField";
import {translatable} from "react-multilingual";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import {email, phoneNumber, required} from "../common/fieldValidators";
import {reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {compose} from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
    expandForm: {
        width: "100%"
    },
    rightInput: {
        width: "100%"
    },
    prevButton: {
        float: "left"
    },
    nextButton: {
        float: "right",
    },
    formContainer: {
        minHeight: "650px",
        maxHeight: "650px",
    }
});

class UserForm extends Component {
    render() {
        const labels = this.props.labels.signup_labels;
        const {classes, handleSubmit} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={16} className={classes.formContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {labels.provide_contact_info}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {labels.provide_contact_info_subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="first_name" label={labels.first_name}
                                   validate={[required]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name="last_name" label={labels.last_name} className={classes.rightInput}
                                   validate={[required]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.expandForm} name="email" label={labels.email}
                                   validate={[email, required]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.expandForm} name="contact_tel" label={labels.contact_tel}
                                   validate={[phoneNumber, required]}/>
                    </Grid>
                </Grid>
                <Grid container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                >
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.prevButton}
                            onClick={this.props.onHome}
                        >
                            {this.props.labels.buttons.home}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.nextButton}
                        >
                            {this.props.labels.buttons.next}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );


    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(withStyles(styles),
    translatable(mapTranslationsToProps),
    reduxForm({
        form: 'UserSignupForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true
    })
)(UserForm);
