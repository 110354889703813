import React from "react";
import ServiceSelectField from "./ServiceSelectField";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import {LANGUAGES_SERVICE} from "../../services/availableServices";


const LanguageSelectField = ({label, name, removeAll, ...other}) => {

    const customMapperHandler = (values) => {
        let formattedValues = values.map(item => {
            return {
                value: item.code,
                label: item.name
            }
        });
        const removeAllCodes = removeAll.map(item => item.languageCode);

        formattedValues = formattedValues.filter(item => {
            return !removeAllCodes.includes(item.value)
        });

        return formattedValues
    };

    return <ServiceSelectField name={name} service={LANGUAGES_SERVICE} customMapper={customMapperHandler}
                               label={label} {...other}/>;
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.select_field.language};
};

LanguageSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


LanguageSelectField.defaultProps = {
    removeAll: []
};

export default translatable(mapTranslationsToProps)(LanguageSelectField);