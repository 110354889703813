import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import SelectField from "./SelectField";


const optionMap = (options) => [
    {value: false, label: options.accepted},
    {value: true, label: options.rejected}]
;


const PendingResultSelectField = ({labels: {label, options}, name, ...other}) => {
    return <SelectField name={name} optionmap={optionMap(options)} noneOptionEnabled={false}
                        label={label} {...other}/>;
};


PendingResultSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {
        labels:
            {
                label: labels.manageResults_labels.pendingResultLabel,
                options: labels.manageResults_labels.pendingResultTypes
            }
    }
};

export default translatable(mapTranslationsToProps)(PendingResultSelectField);