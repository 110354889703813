import {Field} from "redux-form";
import React from "react";
import PropTypes from 'prop-types';
import AutoCompleteComponent from "./components/AutoCompleteComponent";


const GenericAutoCompleteField = (props, {name, label, className}) =>
    <Field name={name} className={className} component={AutoCompleteComponent} label={label} {...props}/>;


GenericAutoCompleteField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default GenericAutoCompleteField