import PropTypes from "prop-types";
import React from "react";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import MenuList from "@material-ui/core/MenuList/MenuList";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Divider from "@material-ui/core/Divider/Divider";
import {fabAddButton} from "../../common/fields/components/FabButtonComponent";
import {translatable} from "react-multilingual";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import renderIcon from "../LanguageIconsRenderer";


const styles = theme => ({
    content: {
        overflow: 'auto',
        maxHeight: '25vh'
    }
});


const DocumentTranslation = ({labels, classes, translations, translationSelectedHandler, selectedTranslation, windowHandler, selectedVersion}) => {


    let renderSingleItem = (translation) => {
        return (
            <div key={translation.languageCode}>
                <MenuItem
                    selected={selectedTranslation !== null && selectedTranslation.languageCode === translation.languageCode}
                    onClick={() => translationSelectedHandler(translation)}
                >
                    <ListItemIcon>{renderIcon(translation.status, labels.tooltips)}</ListItemIcon>
                    <ListItemText primary={translation.languageName}/>
                </MenuItem>
                <Divider/>
            </div>);
    };

    return (
        <React.Fragment>
            <Typography variant="h5" color={"primary"}>
                {labels.title}
            </Typography>
            <Card className={classes.content}>
                <MenuList style={{padding: 0}}>
                    {translations.map(item => (
                        renderSingleItem(item)
                    ))}
                </MenuList>
            </Card>
            {!selectedVersion.published && fabAddButton(labels.add_tooltip, windowHandler)}
        </React.Fragment>);


};


DocumentTranslation.propTypes = {
    translations: PropTypes.array.isRequired,
    translationSelectedHandler: PropTypes.func.isRequired,
    selectedVersion: PropTypes.object
};


const mapTranslationsToProps = (translations) => {
    return {labels: translations.labels.documents_section.translations};
};


export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(DocumentTranslation);

