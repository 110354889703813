import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {authReducer} from "../Auth/authReducer";
import locals from "../i18n/locales/locale";
import {localeReducer} from "react-multilingual";

const rootReducer = combineReducers({
    form: formReducer,
    auth: authReducer,
    locale: localeReducer("en", locals)
});

export default rootReducer
