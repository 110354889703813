import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { reduxForm } from "redux-form";
import { PagedTableContainer } from "../common/components/PagedTable/PagedTableContainer";
import { CLINIC_SERVICE } from "../common/services/availableServices";
import { translatable } from "react-multilingual";
import { compose } from "recompose";
import { columnType } from "../common/components/PagedTable/columnType";
import SearchTextField from "../common/fields/SearchTextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PermissionContainer from "../Auth/PermissionContainer";
import { permission } from "../Auth/permissions";
import PopupWindow from "../common/components/PopupWindow";
import { managePageStyles } from "../styles";
import CountrySelectField from "../common/fields/selectFields/CountrySelectField";
import OrganizationSelectField from "../common/fields/selectFields/OrganizationSelectField";
import ClinicDetails from "./ClinicDetails";
import Utils from "../common/Utils";
import SelectField from "../common/fields/selectFields/SelectField";

class ManageClinics extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            criteria: null,
            openModal: false,
            clinicId: NaN,
            loadingTableData: true,
        };
        this.tableColumnSetup();

    }

    tableColumnSetup() {

        this.columns = [
            {key: 'name', type: columnType.text},
            {key: 'city', type: columnType.text, orderProperty: "address.city"},
            {key: 'countryCode', type: columnType.text, orderProperty: "address.country.code"},
            {key: 'status', type: columnType.text},
            {key: 'organizationName', type: columnType.text},
            {key: 'created', type: columnType.text, customCellRenderer: this.dateCellRenderer},
            {key: 'details', type: columnType.text, customCellRenderer: this.detailsCellRenderer, sortable: false}
        ];

    }

    detailsCellRenderer = (rowId) => {

        return <Button onClick={this.detailsButtonClickHandler(rowId)}>{this.props.labels.buttons.details}</Button>;
    };

    dateCellRenderer = (rowId, columnValue) => {
        return Utils.dateRenderer(columnValue);
    };

    detailsButtonClickHandler = rowId => () => {

        this.setState({
            openModal: true,
            clinicId: rowId
        });
    };

    createButtonClickHandler = () => {

        this.setState({
            openModal: true,
            clinicId: NaN
        });

    };

    searchButtonClickHandler = values => {

        let queryParams = {
            search: values.search
        };

        if ('status' in values) {
            queryParams = {
                ...queryParams,
                'status': values.status.toUpperCase()
            };
        }

        if ('country' in values) {
            queryParams = {
                ...queryParams,
                'address.country.code': values.country
            };
        }

        if ('organization' in values) {
            queryParams = {
                ...queryParams,
                'organization.id': values.organization
            };
        }

        this.setState({
            criteria: queryParams,
            loadingTableData: true,
        });
    };

    closeWindowHandler = () => {
        this.setState({clinicId: NaN, openModal: false});
    };
    successHandler = () => {
        this.setState({clinicId: NaN, openModal: false, loadingTableData: true});
    };

    loadingDataFinishHandler = () => {
        this.setState({
            loadingTableData: false,
        })
    };


    render() {
        const {handleSubmit, labels, classes} = this.props;
        const specLabels = labels.manageClinics_labels;
        const {criteria, clinicId, openModal, loadingTableData} = this.state;

        return (
            <div>
                <PopupWindow open={openModal}>
                    <ClinicDetails
                        clinicId={clinicId}
                        onCancel={this.closeWindowHandler}
                        onSuccess={this.successHandler}
                    />
                </PopupWindow>

                <Grid container className={classes.header}>
                    <Grid item xs={8}>
                        <Typography variant="h5" color="primary">
                            {specLabels.page_title}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid container spacing={32} alignItems="center" justify="flex-end">
                            <Grid item>
                                <PermissionContainer
                                    allowedPermissions={[permission.clinic_add]}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={this.createButtonClickHandler}>
                                        {specLabels.create_clinic_button}
                                    </Button>
                                </PermissionContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
                    <Grid spacing={24} container alignItems="center" className={classes.criteria}>
                        <Grid item md={3}>
                            <SearchTextField
                                name="search"
                                className={classes.criteriaField}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}>
                                <CountrySelectField
                                    name="country"
                                    className={classes.criteriaField}
                                />
                            </div>
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}>
                                <OrganizationSelectField
                                    name="organization"
                                    className={classes.criteriaField}
                                />
                            </div>
                        </Grid>
                        <Grid item md={2}>
                          <div style={{ marginTop : 8 }}>
                                <SelectField
                                    name={"status"}
                                    className={classes.criteriaField}
                                    label={labels.fields.status}
                                    optionmap={labels.statuses}
                                />
                            </div>
                        </Grid>
                        <Grid item container md={1} alignItems="center" justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                {labels.buttons.search}
                            </Button>

                        </Grid>
                    </Grid>
                </form>
                <PagedTableContainer rowKey="id" orderBy="name" order="asc" columns={this.columns}
                                     criteria={criteria} service={CLINIC_SERVICE}
                                     loadingData={loadingTableData}
                                     onLoadingDataFinish={this.loadingDataFinishHandler}
                                     errorMessage={labels.table.error_message}
                                     emptyResultMessage={labels.table.empty_result}
                                     columnLabels={specLabels.columns}
                />
            </div>
        );
    }
}


const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps),
    reduxForm({form: 'ClinicSearch'}),
    withStyles(managePageStyles)
)(ManageClinics);
