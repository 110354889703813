import React from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import withStyles from "@material-ui/core/styles/withStyles";
import BatchDetails from './BatchDetails';
import BatchSamplingResults from './BatchSamplingResults';
import BatchReviewResults from './BatchReviewResults';
import Paper from "@material-ui/core/Paper/Paper";

const styles = theme => ({

    paper: {
        marginBottom: 0,
    },
    batchDetailsTabContent: {
        margin: theme.spacing.unit * 2,
        overflow: 'hidden',
    }
});

class BatchDetailsTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 'batch details',
        };
    }

    handleChange = (event, value) => {
        this.setState({selectedTab: value});
    };

    render() {

        const selectedTab = this.state.selectedTab;
        const {classes, batchNumber, onCancel, onSuccess} = this.props;
        const createBatch = batchNumber === null;

        return (
            <React.Fragment>
                <Paper square className={classes.paper}>
                    <Tabs value={selectedTab} onChange={this.handleChange}>
                        <Tab label="Batch details" value='batch details'/>
                        {!createBatch && <Tab label="Sampling results" value='sampling results'/>}
                        {!createBatch && <Tab label="Review results" value='review results'/>}
                    </Tabs>
                </Paper>
                {
                    selectedTab === 'batch details' &&
                    <div className={classes.batchDetailsTabContent}>
                        <BatchDetails
                            batchNumber={batchNumber}
                            onCancel={onCancel}
                            onSuccess={onSuccess}
                        />
                    </div>
                }
                {
                    selectedTab === 'sampling results' &&
                    <div className={classes.batchDetailsTabContent}>
                        <BatchSamplingResults
                            batchNumber={batchNumber}
                            onCancel={onCancel}
                        />
                    </div>
                }
                {
                    selectedTab === 'review results' &&
                    <div className={classes.batchDetailsTabContent}>
                        <BatchReviewResults
                            batchNumber={batchNumber}
                            onCancel={onCancel}
                        />
                    </div>
                }

            </React.Fragment>
        );
    }

}

export default withStyles(styles)(BatchDetailsTabs);