import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import ServiceSelectField from "./ServiceSelectField";


const releaseStatuses = ["MILD", "MODERATE", "SEVERE"].map(item => {
    return {
        value: item,
        label: item
    }
});


const ClassificationSelectField = ({label, name, ...other}) => {
    return <ServiceSelectField name={name} optionmap={releaseStatuses}
                               label={label} {...other}/>;
};


ClassificationSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.manageResults_labels.classification};
};

export default translatable(mapTranslationsToProps)(ClassificationSelectField);
