import React from 'react';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";


export const UserLabelComponent = ({user}) => {
    return (
        <React.Fragment>
            {user &&
            <Tooltip disableFocusListener disableTouchListener title={user.roles.join(", ")}>
                <Button component={Link} to="/my_page">
                    {user.name} {user.surname}
                </Button>
            </Tooltip>
            }
        </React.Fragment>);
};






