import PropTypes from "prop-types";
import React from "react";
import {reduxForm} from "redux-form";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import {DOCUMENTS_VERSION_SERVICE} from "../../common/services/availableServices";
import {translatable} from "react-multilingual";
import CheckBoxField from "../../common/fields/CheckBoxField";
import {required} from "../../common/fieldValidators";
import toastr from "toastr";

const styles = theme => ({
    root: {
        display: 'flex',
        minWidth: '60vh'
    },
    container: {
        width: '100%'
    },
    inputField: {
        width: '100%'
    },
    submitRow: {
        marginTop: 20
    }
});


const PublishNewDocumentView = ({handleSubmit, classes, onCancel, onSuccess, documentVersion, labels}) => {


    const submitHandler = (values) => {

        const notify = values.notify === undefined ? false : values.notify;

        const versionForm = {
            notifyUsers: notify,
            id: documentVersion.id
        };

        DOCUMENTS_VERSION_SERVICE.putValues(versionForm).then(response => {
            toastr.success('Version is published');
            onSuccess(response.data);
        }).catch(error => {
            if (typeof error.response === "undefined")
                return;
            toastr.error(error.response.data.message)
        });


    };


    return (
        <div className={classes.root}>
            <form noValidate autoComplete="off" className={classes.container}
                  onSubmit={handleSubmit(submitHandler.bind(this))}>
                <Grid container spacing={16} direction="column">

                    <Grid item md={12}>
                        <Typography variant="h5" color="primary">
                            {labels.title}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <CheckBoxField name="notify"
                                       label={labels.notify}
                        />
                    </Grid>

                    <Grid container spacing={16} justify="flex-end" className={classes.submitRow}>
                        <Grid item>
                            <Button onClick={onCancel}>{labels.cancel}</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color={'primary'} type={'submit'}
                                    validate={[required]}>{labels.save}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>

    )
};


PublishNewDocumentView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    documentVersion: PropTypes.any.isRequired,
    labels: PropTypes.any
};

const mapTranslationsToProps = (translations) => {
    return {labels: translations.labels.documents_section.publish};
};

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
    reduxForm({
        form: 'publishDocumentVersionForm',
    })
)(PublishNewDocumentView);

