import React, {Component} from 'react';
import {translatable} from "react-multilingual";
import {compose} from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import toastr from "toastr";
import {AGREEMENT_SERVICE, DISPLAY_SERVICE, RELEVANT_AGREEMENT_SERVICE} from "../common/services/availableServices";
import AgreementDetails from "./AgreementDetails";
import PopupWindow from "../common/components/PopupWindow";

const styles = () => ({
    documentTitle: {
        marginBottom: "20px",
    },
    confirmationButton: {
        marginTop: "20px",
    },
});

class SignAgreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAgreement: 0,
            awaitingDocuments: [],
            title: '',
            version: '',
            display: [],
            checkedBoxes: [],
        };
    }

    componentDidMount() {
        RELEVANT_AGREEMENT_SERVICE.getAll().then((documents) => {
            if (documents.data.length === 0) {
                this.props.history.push("/my_page");
            }
            else {
                this.setState({awaitingDocuments: documents.data}, () => this.fetchDocument());
            }
        })
    }

    handleAccept = () => {
        const values = {translationId: this.state.awaitingDocuments[this.state.currentAgreement].translationId};
        AGREEMENT_SERVICE.post(values).then(() => {
            if ((this.state.currentAgreement < this.state.awaitingDocuments.length - 1)) {
                this.setState({
                        currentAgreement: this.state.currentAgreement + 1,
                    },
                    () => this.fetchDocument());
            } else {
                this.props.history.push("/my_page");
            }
        }).catch(error => {
            if (error.response === undefined) {
                return;
            }
            toastr.error(error.response.data.message);
        })
    };

    fetchDocument = () => {
        if (this.state.currentAgreement >= this.state.awaitingDocuments.length) {
            return;
        }
        const id = this.state.awaitingDocuments[this.state.currentAgreement]['documentRepresentationId'];
        DISPLAY_SERVICE.get(id).then(value => {
            const documentContent = JSON.parse(value.data.content);

            const checkedBoxes = documentContent.filter(section => {
                const checkBoxTitle = section.title;
                return !!checkBoxTitle;
            }).map(() => false);

            this.setState({
                title: this.state.awaitingDocuments[this.state.currentAgreement]['documentName'],
                version: this.state.awaitingDocuments[this.state.currentAgreement]['documentVersionName'],
                display: documentContent,
                checkedBoxes: checkedBoxes,
            });
        });
    };

    render() {
        const {title, version, display, checkedBoxes} = this.state;
        return (
            <PopupWindow open={true} onClose={() => {
            }}>
                <AgreementDetails
                    title={title}
                    version={version}
                    display={display}
                    editable={true}
                    handleSubmit={this.handleAccept}
                    checkedBoxes={checkedBoxes}
                />
            </PopupWindow>
        );
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    withStyles(styles),
    translatable(mapTranslationsToProps),
)(SignAgreement);