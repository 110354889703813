import PropTypes from "prop-types";
import React from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import {withStyles} from "@material-ui/core/styles/index";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography/Typography";
import {translatable} from "react-multilingual";


const styles = theme => ({});


const DeactivateOrganizationView = ({classes, onCancel, onConfirm, labels}) => {


    return (
        <div className={classes.root}>
            <Grid container spacing={16} direction="column">
                <Grid item md={12}>
                    <Typography variant="h5" color="primary">
                        {labels.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {labels.message}
                    </Typography>
                </Grid>
                <Grid container spacing={16} justify="flex-end" className={classes.submitRow}>
                    <Grid item>
                        <Button onClick={onCancel}>{labels.cancel}</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color={'primary'} onClick={onConfirm}>{labels.confirm}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>

    )
};


DeactivateOrganizationView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    labels: PropTypes.any
};

const mapTranslationsToProps = (translations) => {
    return {labels: translations.labels.organization_details.deactivate_organization};
};

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles)
)(DeactivateOrganizationView);

