import React from 'react';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {translatable} from 'react-multilingual';
import {compose} from 'recompose';

import PropTypes from 'prop-types';
import AutocompleteSelectField from "../selectFields/AutocompleteSelectField";

class AutoCompleteComponent extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false
        }

    }

    render() {
        const {
            changeLocale, input, label, name, meta: {touched, error},
            children, className, required, require_label,
            optionmap, value, onChange, ...props
        } = this.props;

        const menuOpen = this.state.menuOpen;

        return (
            <FormControl error className={className} required={required} margin={"normal"}
                         style={menuOpen ? {zIndex: 1} : {zIndex: 0}}>
                <AutocompleteSelectField
                    optionmap={optionmap}
                    placeholder={label}
                    value={optionmap.find(item => item.value === input.value)}
                    onChange={(event) => input.onChange(event.value)}
                    children={children}
                    error={error && touched}
                    name={name}
                    {...props}
                    changeLocale={changeLocale}
                    onMenuOpen={() => {
                        this.setState({menuOpen: true})
                    }}
                    onMenuClose={() => {
                        this.setState({menuOpen: false})
                    }}
                />
                <FormHelperText
                    error={touched && error !== undefined}>{touched && error} {!(touched && error) && required && require_label}</FormHelperText>
            </FormControl>

        )
    }
};

AutoCompleteComponent.defaultProps = {
    optionmap: []
};

AutoCompleteComponent.propTypes = {
    optionmap: PropTypes.array
};


const mapTranslationsToProps = (translations) => {
    return {require_label: translations.labels.required};
};


export default compose(
    translatable(mapTranslationsToProps)
)(AutoCompleteComponent);