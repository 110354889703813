import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {loginRequest, logoutFailed, logoutRequest} from "../Auth/actions";
import {connect} from "react-redux";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import LandingMenu from "./LandingMenu";
import Signup from "../Signup/Signup";
import ResetPassword from "../ResetPassword/ResetPassword";
import CEMark from "./CEMark";

const styles = () => ({
    container: {
        width: '100%'
    }
});

const MENU = 'MENU';
const SIGNUP = 'SIGNUP';
const RESET_PASSWORD = 'RESET PASSWORD';

class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: MENU,
        };
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                {
                    this.state.view === MENU &&
                    <LandingMenu
                        onSignup={() => this.setState({view: SIGNUP})}
                        onResetPassword={() => this.setState({view: RESET_PASSWORD})}
                    />
                }
                {
                    this.state.view === SIGNUP &&
                    <Signup
                        onHome={() => this.setState({view: MENU})}
                    />
                }
                {
                    this.state.view === RESET_PASSWORD &&
                    <ResetPassword
                        onHome={() => this.setState({view: MENU})}
                    />
                }
                <CEMark />
            </div>
        );
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

function mapStateToProps({auth}) {
    return auth
}

export default compose(withStyles(styles),
    translatable(mapTranslationsToProps),
    connect(mapStateToProps, {
        loginRequest,
        logoutRequest,
        logoutFailed,
    })
)(LandingPage);


