import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import classNames from 'classnames';
import { SaveOutlined } from '@material-ui/icons';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: '50%',
  },
  indicatorValid: {
    backgroundColor: '#92BE6F',
  },
  indicatorInvalid: {
    backgroundColor: '#BC0934',
  },
  saveIcon: {
    color: '#CCCCCC',
  },
  saveIconContainer: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 30,
      height: 2,
      backgroundColor: '#CCCCCC',
      top: 10,
      left: -3,
      transform: 'rotate(45deg)',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  language: {
    color: theme.palette.primary.main,
    fontSize: 20,
  }
});

const checkChanges = (initialValue, value) => {
  return initialValue.question.text !== value.question.text ||
    initialValue.answer.text !== value.answer.text ||
    initialValue.answer.photoUrl !== value.answer.photoUrl ||
    initialValue.answer.videoUrl !== value.answer.videoUrl;
}

const FAQFormLanguage = ({ initialValue, value, language, classes }) => {
  const isValid = value.question.text && value.answer.text;
  const hasChanges = checkChanges(initialValue, value);

  return (
    <div className={classes.container}>
      <span className={classNames(classes.indicator, {
        [classes.indicatorValid]: isValid,
        [classes.indicatorInvalid]: !isValid,
      })}
      />
      <div className={classNames(classes.saveIconContainer, { [classes.hidden]: !hasChanges })}>
        <SaveOutlined className={classNames(classes.saveIcon)} />
      </div>
      <span className={classes.language}>
        {language}
      </span>
    </div>
  )
};

export default compose(withStyles(styles))(FAQFormLanguage);