import React from 'react';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText";
import {compose} from "recompose";
import {translatable} from "react-multilingual";

const CheckBoxFieldComponent = ({input, label, labels, className, required, name, style, meta: {touched, error}, ...props}) => {
    return (
        <FormControl className={className} margin={"normal"} style={style}>
            <FormControlLabel
                control={
                    <Checkbox
                        label={label}
                        checked={input.value}
                        onClick={input.onChange}
                        {...props}/>
                }
                label={label}/>
            <FormHelperText
                error={touched && error !== undefined}>{touched && error} {!(touched && error) && required && labels.required}</FormHelperText>
        </FormControl>)
};

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps)
)(CheckBoxFieldComponent);