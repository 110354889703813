export const FormMode = {
    VIEW: Symbol('VIEW'),
    CREATE: Symbol('CREATE'),
    EDIT: Symbol('EDIT'),
}

export function initializeFormMode(id) {
    if (isNaN(id)) {
        return FormMode.CREATE
    }
    else {
        return FormMode.VIEW
    }
}


export function constructErrorMessage(message, error) {

    console.log('message:', message)
    if (!message)
        message = '';

    let serverErrorMessage = '';
    if (error.response && error.response.data && error.response.data.message) {
        serverErrorMessage = error.response.data.message
    }
    if (message !== '' && serverErrorMessage !== '')
        return message + '<br>' + serverErrorMessage;

    return message + serverErrorMessage;

}