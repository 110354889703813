import React from "react";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Icon from "@material-ui/core/Icon/Icon";

const renderIcon = (status, labels) => {

    let tooltipMessage = '';
    let resultIcon = null;

    switch (status) {
        case 'NONE':
            // tooltipMessage = labels.none;
            resultIcon = 'not_interested';
            break;
        case 'IN_PROGRESS':
            // tooltipMessage = labels.in_progress;
            resultIcon = 'edit';
            break;
        case 'FINISHED':
            // tooltipMessage = labels.finish;
            resultIcon = 'check';
            break;
        default:
            return <div/>;
    }

    return (
        <React.Fragment>
            <Tooltip title={tooltipMessage}>
                <Icon>{resultIcon}</Icon>
            </Tooltip>
        </React.Fragment>);

};

export default renderIcon;





