import React from "react";
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from "@material-ui/icons/Search";
import TextField from "./TextField";
import {translatable} from "react-multilingual";


const SearchTextField = ({name, labels, className}) =>
    <TextField name={name} label={labels.fields.search} className={className} InputProps={{
        endAdornment: (
            <InputAdornment >
                <Search />
            </InputAdornment>
        ),}}/>;


SearchTextField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => ({labels});
export default translatable(mapTranslationsToProps)(SearchTextField);
