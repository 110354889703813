import React from 'react';
import TextFieldMaterialUi from '@material-ui/core/TextField'
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {translatable} from 'react-multilingual';
import {compose} from 'recompose';

const TextFieldComponent = ({input, label, meta: {touched, error}, className, required, labels, changeLocale, style, showFormHelperText = true, ...custom}) => {

    return (
        <FormControl error className={className} required={required} margin={custom.margin || "normal"} style={style}>
            <TextFieldMaterialUi
              label={label}
              {...input}
              error={error && touched}
              required={required}
              {...custom}

            />
            {showFormHelperText && (
              <FormHelperText
                error={touched && error!==undefined}
              >
                {touched && error} {!(touched && error) && required && labels.required}
              </FormHelperText>
            )}
        </FormControl>

    )
};

const mapTranslationsToProps = ({labels}) => ({labels});


export default compose(
  translatable(mapTranslationsToProps)
)(TextFieldComponent);