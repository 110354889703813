import React from "react";
import PropTypes from 'prop-types';
import {translatable} from "react-multilingual";
import ServiceSelectField from "./ServiceSelectField";
import {CLINIC_SERVICE_AVAILABLE} from "../../services/availableServices";


const customMapperHandler = (values) => {
    return values.map(item => {
        return {
            value: item.id,
            label: item.name
        }
    });

};


const ClinicSelectField = ({label, name, ...other}) => {
    return <ServiceSelectField name={name} service={CLINIC_SERVICE_AVAILABLE} customMapper={customMapperHandler}
                               label={label} {...other}/>;
};


ClinicSelectField.propTypes = {
    name: PropTypes.string.isRequired,
};


const mapTranslationsToProps = ({labels}) => {
    return {label: labels.select_field.clinic};
};

export default translatable(mapTranslationsToProps)(ClinicSelectField);
