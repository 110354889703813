import React, {Component} from 'react';
import TextField from "../common/fields/TextField";
import {translatable} from "react-multilingual";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import {required} from "../common/fieldValidators";
import {reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {compose} from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import CountrySelectField from "../common/fields/selectFields/CountrySelectField";

const styles = () => ({
    expandForm: {
        width: "100%"
    },
    nextButtonContainer: {
        float: "right",
    },
    prevButtonContainer: {
        float: "left",
    },
    formRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignContent: 'flex-start'
    },
    city: {
        marginLeft: 32,
        flexGrow: 3
    },
    formContainer: {
        minHeight: "650px",
        maxHeight: "650px",
    },
});


class ClinicForm extends Component {
    render() {
        const labels = this.props.labels.signup_labels;
        const {handleSubmit, handleBack, classes} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={16} className={classes.formContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {labels.provide_clinic_info}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.expandForm}
                                   name="company"
                                   label={labels.company}
                                   validate={[required]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CountrySelectField name={"country"}
                                            className={classes.expandForm}
                                            validate={[required]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.expandForm}
                                   name="address1"
                                   label={labels.address1}
                                   validate={[required]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.expandForm}
                                   name="address2"
                                   label={labels.address2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <TextField
                                name={"postcode"}
                                label={labels.postcode}
                                validate={[required]}
                            />
                            <TextField
                                name={"city"}
                                label={labels.city}
                                className={classes.city}
                                validate={[required]}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                >
                    <Grid item xs={12} sm={6}>
                        <Button
                            onClick={handleBack}
                            variant="outlined"
                            color="primary"
                            className={classes.prevButtonContainer}
                        >
                            {this.props.labels.buttons.back}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.nextButtonContainer}
                        >
                            {this.props.labels.buttons.next}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(withStyles(styles),
    translatable(mapTranslationsToProps),
    reduxForm({
        form: 'UserSignupForm',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true
    })
)(ClinicForm);
