import React from "react";
import {withStyles} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import {UPDATE_RESULT_NOTES_SERVICE} from "../../../common/services/availableServices";
import toastr from "toastr";

const styles = theme => ({
    root: {
        display: 'flex',
        width: '60vh'
    },
    textField: {
        width: '100%',
    },
    submitRow: {
        marginTop: 20
    }
});

class EditNotesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: this.props.notes,
        };


    }

    changeHandler = event => {
        this.setState({
            notes: event.target.value,
        });
    };

    cancelButtonClickHandler = () => {
        this.props.onCancel();
    }

    updateButtonClickHandler = () => {

        UPDATE_RESULT_NOTES_SERVICE.put(this.state.notes, this.props.resultId).then(response => {
            toastr.success(this.props.labels.detailed_result_view.notesUpdatedSuccessfully);
            this.props.onSuccess();
        }).catch(error => {

            toastr.error(this.props.labels.detailed_result_view.notesUpdateFail);
        });


    }

    render() {
        const {classes, labels} = this.props;
        const {notes} = this.state;
        const specLabels = labels.detailed_result_view;

        return (
            <div className={classes.root}>
                <Grid container>

                    <Grid item md={12}>
                        <Typography variant="h5" color="primary">
                            {specLabels.updateResultNotes}
                        </Typography>
                        <Typography>
                            {specLabels.notesCharacterLimit}
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <TextField value={notes} className={classes.textField}
                                   multiline
                                   rows={2}
                                   variant="outlined"
                                   onChange={this.changeHandler}
                                   inputProps={{
                                       maxLength: 255,
                                   }}
                        />
                    </Grid>

                    <Grid container spacing={16} justify="flex-end" className={classes.submitRow}>
                        <Grid item>
                            <Button variant="outlined"
                                    onClick={this.cancelButtonClickHandler}>{labels.buttons.cancel}</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color={'primary'} type={'submit'}
                                    onClick={this.updateButtonClickHandler}>{labels.buttons.save}</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </div>
        )
    }
};

const mapTranslationsToProps = ({labels}) => ({labels});
export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles),
)(EditNotesComponent);