import React from "react";
import {compose} from "recompose";
import Typography from "@material-ui/core/Typography";
import {translatable} from "react-multilingual";


const PageNotFound = ({classes, label}) => (

    <Typography variant="h5" color="primary">
        {label}
    </Typography>
);

const mapTranslationsToProps = ({labels}) => {
    return {label: labels.page_not_found};
};

export default compose(
    translatable(mapTranslationsToProps),
)(PageNotFound);