import React, {Component} from 'react';
import bwipjs from 'bwip-js';

class Barcode extends Component {
    componentDidUpdate() {
        const {batchId} = this.props;

        bwipjs('mycanvas', {
            bcid: 'datamatrix',    // Barcode type
            text: batchId,         // Text to encode
            scale: 5,              // 5x scaling factor
            textxalign: 'center',  // Always good to set this
        }, function (err, cvs) {
            if (err) {
                console.log(err)
            } else {
                // Nothing else to do in this example...
            }
        });
    }

    render() {
        return (<canvas id="mycanvas">

        </canvas>)
    }
}

export default Barcode;
