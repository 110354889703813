import React, {Component} from 'react';
import {EMPLOYEE_SERVICE} from "../common/services/availableServices";
import {PagedTableContainer} from "../common/components/PagedTable/PagedTableContainer";
import {translatable} from "react-multilingual";
import {compose} from "recompose";
import {connect} from "react-redux";
import {columnType} from "../common/components/PagedTable/columnType";
import PermissionContainer from "../Auth/PermissionContainer";
import {permission} from "../Auth/permissions";
import Grid from "@material-ui/core/Grid";
import SearchTextField from "../common/fields/SearchTextField";
import Button from "@material-ui/core/Button";
import {reduxForm} from "redux-form";
import withStyles from "../../node_modules/@material-ui/core/styles/withStyles";
import {managePageStyles} from "../styles";
import Typography from "@material-ui/core/Typography";
import RoleSelectField from "../common/fields/selectFields/RoleSelectField";
import ClinicsSelectField from "../common/fields/selectFields/ClinicsSelectField";
import PopupWindow from "../common/components/PopupWindow";
import ActiveSelectField from "../common/fields/ActiveSelectField";
import StaffUserDetails from "./StaffUserDetails";

class ManageStaffUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            criteria: null,
            openModal: false,
            employeeId: NaN,
            loadingTableData: true,
        };
        this.tableColumnSetup();

    }


    tableColumnSetup() {
        this.columns = [
            {key: 'id', type: columnType.numeric},
            {key: 'firstName', type: columnType.text},
            {key: 'lastName', type: columnType.text},
            {key: 'email', type: columnType.text},
            {key: 'active', type: columnType.text, customCellRenderer: this.activeCellRenderer},
            {key: 'roles', type: columnType.text, sortable: false}
        ];

        if (PermissionContainer.checkAllowedPermission(permission.employee_view_all, this.props.permissions)) {
            let clinicColumn = {key: 'clinicName', type: columnType.text};
            this.columns.push(clinicColumn)
        }

        let detailsColumn = {
            key: 'details', type: columnType.text, customCellRenderer: this.detailsCellRenderer, sortable: false
        };
        this.columns.push(detailsColumn)

    }


    activeCellRenderer = (rowKey, columnValue) => {

        if (columnValue !== undefined)
            if (columnValue === true)
                return this.props.labels.statuses.active;
            else if (columnValue === false)
                return this.props.labels.statuses.inactive

    };

    detailsCellRenderer = (rowId) => {

        return <Button onClick={this.detailsButtonClickHandler(rowId)}>{this.props.labels.buttons.details}</Button>;
    };


    detailsButtonClickHandler = rowId => () => {
        this.setState({
            openModal: true,
            employeeId: rowId
        });
    };


    searchButtonClickHandler = values => {
        let queryParams = {
            search: values.search
        };
        if ('clinic' in values) {
            queryParams = {
                ...queryParams,
                'clinic.id': values.clinic
            };
        }
        if ('active' in values) {
            let isActiveSet = (values.active === this.props.labels.statuses.active.toLowerCase());
            queryParams = {
                ...queryParams,
                'active': isActiveSet
            };
        }
        if ('role' in values) {
            queryParams = {
                ...queryParams,
                'identities.identityRoles.role.name': values.role
            };
        }

        this.setState({
            criteria: queryParams,
            loadingTableData: true,
        });
    };

    createButtonClickHandler = () => {

        this.setState({
            openModal: true,
            employeeId: NaN
        });

    };

    loadingDataFinishHandler = () => {
        this.setState({
            loadingTableData: false,
        })
    };

    closeWindowHandler = () => {
        this.setState({employeeId: NaN, openModal: false});
    };

    successHandler = () => {
        this.setState({employeeId: NaN, openModal: false, loadingTableData: true});
    };


    render() {
        const {handleSubmit, labels, classes} = this.props;
        const specLabels = labels.staff_users;
        const {criteria, employeeId, openModal, loadingTableData} = this.state;

        return (
            <div>

                <PopupWindow open={openModal}>
                    <StaffUserDetails
                        employeeId={employeeId}
                        onCancel={this.closeWindowHandler}
                        onSuccess={this.successHandler}
                    />
                </PopupWindow>
                <Grid container className={classes.header}>
                    <Grid item xs={4}>
                        <Typography variant="h5" color="primary">
                            {specLabels.page_title}
                        </Typography>
                    </Grid>

                    <Grid item xs={8}>
                        <Grid container spacing={32} alignItems="center" justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={this.exportResultsButtonClickHandler}
                                >
                                    {labels.buttons.export_filtered_results}
                                </Button>
                            </Grid>
                            <Grid item>
                                <PermissionContainer
                                    allowedPermissions={[permission.patient_add_all, permission.patient_add_clinic]}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        onClick={this.createButtonClickHandler}>
                                        {specLabels.create}
                                    </Button>
                                </PermissionContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmit(this.searchButtonClickHandler)}>
                    <Grid spacing={24} container className={classes.criteria}>
                        <Grid item md={3}>
                            <SearchTextField
                                name="search"
                                className={classes.criteriaField}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}>
                                <ActiveSelectField
                                    name="active"
                                    className={classes.criteriaField}
                                />
                            </div>
                        </Grid>
                        <Grid item md={3}>
                            <div style={{marginTop: 11}}>
                                <RoleSelectField
                                    name="role"
                                    className={classes.criteriaField}
                                />
                            </div>
                        </Grid>
                        <Grid item md={2}>
                            <div style={{marginTop: 11}}>
                                <PermissionContainer allowedPermissions={[permission.clinic_view_all]}>
                                    <ClinicsSelectField
                                        name="clinic"
                                        className={classes.criteriaField}
                                    />
                                </PermissionContainer>
                            </div>
                        </Grid>
                        <Grid item container md={1} alignItems="center" justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit">
                                {labels.buttons.search}
                            </Button>

                        </Grid>
                    </Grid>
                </form>

                <PagedTableContainer rowKey="id" orderBy="lastName" order="asc" columns={this.columns}
                                     criteria={criteria} service={EMPLOYEE_SERVICE} loadingData={loadingTableData}
                                     onLoadingDataFinish={this.loadingDataFinishHandler}
                                     errorMessage={labels.table.error_message}
                                     emptyResultMessage={labels.table.empty_result}
                                     columnLabels={specLabels}
                />
            </div>
        );
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

function mapStateToProps({auth}) {
    return {permissions: auth.user.permissions}
}

export default compose(
    connect(mapStateToProps, null),
    reduxForm({form: 'PatientSearch'}),
    translatable(mapTranslationsToProps),
    withStyles(managePageStyles)
)(ManageStaffUsers);

