export default class Utils {
    static decimalSystem = 10;

    static Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static rangeOf(N) {
        return Array.apply(null, {length: N}).map(Number.call, Number)
    }

    static dateRenderer(timestamp) {
        if (timestamp === undefined || timestamp === null) {
            return "";
        }

        let utcTimestamp = timestamp + 'Z';
        return new Date(utcTimestamp).toLocaleString();
    }


    static shortDateRenderer(timestamp) {
        return (timestamp.getMonth() + 1) +
            "/" + timestamp.getDate() +
            "/" + timestamp.getFullYear();
    }


}