import React from 'react';
import {compose} from 'recompose';
import {ORGANIZATION_SERVICE} from '../common/services/availableServices';
import toastr from 'toastr';
import {initialize, reduxForm} from 'redux-form';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {translatable} from 'react-multilingual';
import TextField from "../common/fields/TextField";
import {number, required} from "../common/fieldValidators";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes from "prop-types";
import {constructErrorMessage, FormMode, initializeFormMode} from "../common/FormUtils";
import {permission as permissions} from "../Auth/permissions";
import PermissionContainer from "../Auth/PermissionContainer";
import LoadingIndicator from "../common/components/LoadingIndicator";
import FormButtons from "../common/components/FormButtons";
import {connect} from "react-redux";
import CheckBoxField from "../common/fields/CheckBoxField";
import PopupWindow from "../common/components/PopupWindow";
import DeactivateOrganizationView from "./DeactivateOrganizationView";

const reduxFormName = 'organizationDetails';

class OrganizationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationId: this.props.organizationId,
            organization: null,
            formMode: initializeFormMode(this.props.organizationId),
            loading: false,
            isModified: false,
            confirmationModal: false
        };
        this.editButtonClickHandler = this.editButtonClickHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.closeButtonClickHandler = this.closeButtonClickHandler.bind(this);
        this.editable = this.editable.bind(this);
        this.closeWindowHandler = this.closeWindowHandler.bind(this);
        this.confirmWindowHandler = this.confirmWindowHandler.bind(this);
        this.submitFormHandler = this.submitFormHandler.bind(this);


    }


    componentDidMount() {
        if (FormMode.VIEW === this.state.formMode) {
            this.fetchOrganization(this.state.organizationId);
        }
    }


    closeButtonClickHandler() {

        if (this.state.isModified) {
            this.props.onSuccess();
        } else {
            this.props.onCancel();
        }

    }

    cancelButtonClickHandler() {

        this.setState({
            formMode: FormMode.VIEW
        }, () => this.fetchOrganization(this.state.organizationId));
    }

    editButtonClickHandler() {

        this.setState({
            formMode: FormMode.EDIT
        })

    }


    submitFormHandler(values) {

        if (FormMode.CREATE === this.state.formMode) {
            this.createOrganization(values);
        } else if (FormMode.EDIT === this.state.formMode) {
            if (this.state.organization.active === true && values.active === false && this.state.confirmationModal === false) {
                this.setState({confirmationModal: true});
            } else {
                this.updateOrganization(values, this.state.organizationId);
            }
        }

    }

    createOrganization(values) {

        this.setState({
            loading: true,
        });

        ORGANIZATION_SERVICE.post(values).then(response => {

            this.setState({
                loading: false,
                organizationId: response.data.id,
                organization: response.data,
                isModified: true,
                formMode: FormMode.VIEW
            });

            this.props.dispatch(initialize(reduxFormName, response.data));

            toastr.success(this.props.labels.organization_details.organizationCreated);
        }).catch(error => {
            this.setState({
                loading: false,
            });

            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't create organization.", error));
        });
    }

    updateOrganization(values, organizationId) {
        this.setState({
            loading: true,
        });


        ORGANIZATION_SERVICE.put(values, organizationId).then(response => {

            this.setState({
                loading: false,
                isModified: true,
                organization: response.data,
                formMode: FormMode.VIEW,
            });

            this.props.dispatch(initialize(reduxFormName, response.data));

            toastr.success(this.props.labels.organization_details.organizationUpdated);

        }).catch(error => {

            this.setState({
                loading: false,
            });

            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't update organization.", error));
        });
    }

    fetchOrganization(organizationId) {

        this.setState({
            loading: true,
        });

        ORGANIZATION_SERVICE.get(organizationId).then(response => {
            this.props.dispatch(initialize(reduxFormName, response.data));

            this.setState({
                organization: response.data,
                loading: false,
            });

            this.setState({logo: response.data.logo});
        }).catch(error => {

            if (!error.handledGlobally)
                toastr.error(constructErrorMessage("Can't fetch patient.", error));
        })
    }

    editable() {

        return PermissionContainer.checkAllowedPermissions([permissions.organization_edit], this.props.user.permissions)

    }

    closeWindowHandler() {
        this.setState({
            confirmationModal: false
        });
    }

    confirmWindowHandler() {

        const submitter = this.props.handleSubmit(this.submitFormHandler);
        submitter();

        this.setState({
            confirmationModal: false
        });
    }

    render() {
        const {handleSubmit, labels} = this.props;
        const organizationDetailsLabels = labels.organization_details;

        const {formMode, loading} = this.state;

        const disabled = formMode === FormMode.VIEW;
        return (
            <div>
                {loading &&
                <LoadingIndicator/>
                }

                <PopupWindow open={this.state.confirmationModal} onClose={this.closeWindowHandler}>
                    <DeactivateOrganizationView onConfirm={this.confirmWindowHandler}
                                                onCancel={this.closeWindowHandler}
                    />
                </PopupWindow>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(this.submitFormHandler)}>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color={"primary"} gutterBottom>
                                {organizationDetailsLabels.page_title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <TextField
                                    name={"name"}
                                    label={organizationDetailsLabels.name}
                                    required
                                    validate={[required]}
                                    disabled={disabled}
                                />
                                <TextField
                                    name={"moderateThreshold"}
                                    label={organizationDetailsLabels.moderateThreshold}
                                    validate={[number]}
                                    disabled={disabled}
                                />
                                <TextField
                                    name={"severeThreshold"}
                                    label={organizationDetailsLabels.severeThreshold}
                                    validate={[number]}
                                    disabled={disabled}
                                />
                                <CheckBoxField name="thirdPartyConsentRequired"
                                               label={organizationDetailsLabels.thirdPartyConsentRequired}
                                               disabled={disabled}
                                />
                                <CheckBoxField name="fullPatientInformationRequired"
                                               label={organizationDetailsLabels.fullPatientInformationRequired}
                                               disabled={disabled}
                                />
                                <TextField name={"phoneRegexp"}
                                           label={organizationDetailsLabels.phoneRegexp}
                                           disabled={disabled}
                                />
                                <CheckBoxField name="active"
                                               label={organizationDetailsLabels.active}
                                               disabled={disabled}
                                />
                            </FormGroup>
                            <Grid container spacing={32} alignItems="center" justify="flex-end">

                                <FormButtons formMode={formMode}
                                             onCloseButtonClick={this.closeButtonClickHandler}
                                             onCancelButtonClick={this.cancelButtonClickHandler}
                                             onEditButtonClick={this.editButtonClickHandler}
                                             actionButtonDisabled={loading}
                                             editModeEnabled={this.editable()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }

}

OrganizationDetails.propTypes = {
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
};

const mapTranslationsToProps = ({labels}) => ({labels});


function mapStateToProps({auth}) {

    return {user: auth.user}

}

export default compose(
    connect(mapStateToProps, null),
    translatable(mapTranslationsToProps),
    reduxForm({
        form: reduxFormName,
        initialValues: {active: true}
    })
)(OrganizationDetails);

