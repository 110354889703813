import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import {columnType} from "../common/components/PagedTable/columnType";
import ClassificationComponent from "../Results/common/ClassificationComponent";
import PopupWindow from "../common/components/PopupWindow";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import PatientResultView from "../Results/PatientResult/PatientResultView";
import {compose} from "recompose";
import {translatable} from "react-multilingual";
import {PagedTableContainer} from "../common/components/PagedTable/PagedTableContainer";
import RestService from "../common/services/RestService";
import Utils from "../common/Utils";
import ExportExcelButton from "../common/components/ExportExcelButton";

const styles = theme => ({});

class BatchReviewResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            criteria: null,
            openModal: false,
            id: NaN,
            loadingTableData: true,
        };
        this.tableColumnSetup();

        const batchNumber = this.props.batchNumber;
        const BATCH_STATUS = 'QC_REVIEW';
        const BATCH_RESULTS_ENDPOINT = 'result/batch/';
        this.BATCH_RESULTS_SERVICE = new RestService(BATCH_RESULTS_ENDPOINT + batchNumber + '/' + BATCH_STATUS);
        this.EXPORT_REVIEW_RESULT_SERVICE = new RestService('export/results/review/' + batchNumber);
    }

    tableColumnSetup() {
        this.columns = [
            {key: 'id', type: columnType.text, sortable: false},
            {key: 'date', type: columnType.text, sortable: false, customCellRenderer: this.dateCellRenderer},
            {key: 'sampleId', type: columnType.text, sortable: false},
            {key: 'runNo', type: columnType.text, sortable: false},
            {key: 'tpeakPosition', type: columnType.numeric, sortable: false},
            {key: 'cpeakPosition', type: columnType.numeric, sortable: false},
            {key: 'finalScore', type: columnType.numeric, sortable: false},
            {key: 'tscore', type: columnType.numeric, sortable: false},
            {key: 'cscore', type: columnType.numeric, sortable: false},
            {key: 'tcRatio', type: columnType.numeric, sortable: false},
            {key: 'finalConcentration', type: columnType.numeric, sortable: false},
            {
                key: 'overallResult',
                type: columnType.text,
                customCellRenderer: this.overallResultCellRenderer,
                sortable: false
            },
            {key: 'details', type: columnType.text, customCellRenderer: this.detailsCellRenderer, sortable: false}
        ];
    }

    overallResultCellRenderer = (rowId, columnValue) => {
        return <ClassificationComponent classification={columnValue}/>;
    };
    detailsCellRenderer = (rowId, columnValue) => {
        return <Button onClick={this.detailsButtonClickHandler(rowId)}>{this.props.labels.buttons.details}</Button>;
    };

    dateCellRenderer = (rowId, columnValue) => {
        return Utils.dateRenderer(columnValue);
    };

    detailsButtonClickHandler = rowId => event => {

        this.setState({
            openModal: true,
            id: rowId
        });
    };

    closeWindowHandler = () => {
        this.setState({id: NaN, openModal: false});
    };

    loadingDataFinishHandler = () => {
        this.setState({
            loadingTableData: false,
        })
    };


    render() {
        const {classes, labels} = this.props;
        const specLabels = labels.batchResults_labels;
        const {criteria, id, openModal, loadingTableData} = this.state;

        return (
            <React.Fragment>
                <PopupWindow open={openModal} onClose={this.closeWindowHandler} disablePadding={true}>

                    <PatientResultView
                        resultId={id}
                        onClose={this.closeWindowHandler}
                    />
                </PopupWindow>
                < Grid container className={classes.header}>
                    <Grid item xs={8}>
                        <Typography variant="h5" color="primary">
                            {specLabels.review_page_title}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={32} alignItems="center" justify="flex-end">
                            <Grid item>
                                <ExportExcelButton service={this.EXPORT_REVIEW_RESULT_SERVICE}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <PagedTableContainer rowKey="id" orderBy="date" order="asc" columns={this.columns}
                                     criteria={criteria} service={this.BATCH_RESULTS_SERVICE}
                                     loadingData={loadingTableData}
                                     onLoadingDataFinish={this.loadingDataFinishHandler}
                                     errorMessage={labels.table.error_message}
                                     emptyResultMessage={labels.table.empty_result}
                                     columnLabels={specLabels.columns}
                />
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonMargin}
                        onClick={this.props.onCancel}>
                        {labels.buttons.close}
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

const mapTranslationsToProps = ({labels}) => ({labels});

export default compose(
    translatable(mapTranslationsToProps),
    withStyles(styles)
)(BatchReviewResults);
