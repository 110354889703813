import React from 'react';
import {formValueSelector, initialize, reduxForm} from 'redux-form'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {email, number, phoneNumber, required} from "../common/fieldValidators";
import TextField from "../common/fields/TextField";
import SelectField from "../common/fields/selectFields/SelectField";
import HelpIcon from '@material-ui/icons/HelpOutline';
import {
    ORGANIZATION_RULE_SERVICE,
    PATIENT_SERVICE,
    RESET_PASSWORD_SERVICE,
    USER_SERVICE
} from '../common/services/availableServices';
import toastr from 'toastr';
import LanguageSelectField from "../common/fields/selectFields/LanguageSelectField";
import CountrySelectField from "../common/fields/selectFields/CountrySelectField";
import FrequencySelectField from "../common/fields/selectFields/FrequencySelectField";
import PatientTypeSelectField from "../common/fields/selectFields/PatientTypeSelectField";
import CheckBoxField from "../common/fields/CheckBoxField";
import LoadingIndicator from "../common/components/LoadingIndicator";
import FormButtons from "../common/components/FormButtons";
import {constructErrorMessage, FormMode, initializeFormMode} from "../common/FormUtils";
import {permission as permissions} from "../Auth/permissions";
import PermissionContainer from "../Auth/PermissionContainer";
import AdminPhysiciansSelectField from "../common/fields/selectFields/AdminPhysiciansSelectField";
import PhysiciansSelectField from "../common/fields/selectFields/PhysiciansSelectField";
import {compose} from "redux";
import {connect} from "react-redux";
import {translatable} from "react-multilingual";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton/IconButton';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    inputField: {
        marginRight: 100,
        marginTop: 10,
        marginBottom: 10
    },
    buttonMargin: {
        marginRight: 32
    },
    formRow: {
        display: 'flex',
      alignItems : 'center'
    },
    city: {
        marginLeft: 32,
        marginRight: 100,
        flexGrow: 3,
        marginTop: 10,
        marginBottom: 10
    },
  frequencyType : {
    marginLeft : 32,
    flex : 3,
    marginTop : 0,
    marginBottom : 10,
    marginRight : 100
  },
  frequencyLabel : {
    flex : 5,
    marginLeft : 32
  },
    postcode: {
        marginTop: 10,
        marginBottom: 10
    },
  frequency : {
    flex : 2,
    marginTop : 0,
    marginBottom : 10
  },
    moderateThreshold: {
        flexGrow: 0.6
    },
    highThreshold: {
        marginLeft: 32,
        marginRight: 100,
        flexGrow: 0.6
    }
});


const reduxFormName = 'patientDetails';

class PatientDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            patientId: this.props.patientId,
            patient: null,
            formMode: initializeFormMode(this.props.patientId),
            loading: false,
            isModified: false
        };

        this.editButtonClickHandler = this.editButtonClickHandler.bind(this);
        this.cancelButtonClickHandler = this.cancelButtonClickHandler.bind(this);
        this.closeButtonClickHandler = this.closeButtonClickHandler.bind(this);
        this.editable = this.editable.bind(this);
        this.setRuleSet = this.setRuleSet.bind(this);
        this.physicianChangeHandler = this.physicianChangeHandler.bind(this);
    }


    componentDidUpdate(prevProps) {
        if (this.props.treatingPhysicianId !== prevProps.treatingPhysicianId) {
            this.physicianChangeHandler(this.props.treatingPhysicianId);
        }
    }

    componentDidMount() {
        if (FormMode.CREATE === this.state.formMode) {
            this.physicianChangeHandler(this.props.user.userId);
        }
        else if (FormMode.VIEW === this.state.formMode) {
            this.fetchPatient(this.state.patientId);
        }
    }

    setRuleSet(ruleSet) {
        this.setState({
            thirdPartyConsentRequired: ruleSet.thirdPartyConsentRequired,
            fullPatientInformationRequired: ruleSet.fullPatientInformationRequired,
            phoneRegexp: ruleSet.phoneRegexp,
        });
    }


    closeButtonClickHandler() {
        if (this.state.isModified) {
            this.props.onSuccess();
        }
        else {
            this.props.onCancel();
        }
    }

    cancelButtonClickHandler() {
        this.setState({
            formMode: FormMode.VIEW
        }, () => this.fetchPatient(this.state.patientId));
    }

    editButtonClickHandler() {
        this.setState({
            formMode: FormMode.EDIT
        })
    }

    submitFormHandler(values) {

        delete values.useMfa;

        this.transformValues(values);

        if (FormMode.CREATE === this.state.formMode) {
            this.createPatient(values);
        }
        else if (FormMode.EDIT === this.state.formMode) {
            this.updatePatient(values, this.state.patientId);
        }
    }

    createPatient(values) {
        this.setState({
            loading: true,
        });

        this.transformValues(values);

        PATIENT_SERVICE.post(values).then(response => {
            this.setState({
                loading: false,
                isModified: true,
                patient: response.data,
                formMode: FormMode.VIEW,
                patientId: response.data.id,
            });

            this.props.dispatch(initialize(reduxFormName, response.data));
            toastr.success(this.props.labels.patient_details.patientCreated);
        }).catch(error => {
            this.errorHandler("Can't create patient.", error);
        });
    }

    updatePatient(values, patientId) {
        this.setState({
            loading: true,
        });

        this.transformValues(values);

        PATIENT_SERVICE.put(values, patientId).then(response => {
            this.setState({
                loading: false,
                isModified: true,
                patient: response.data,
                formMode: FormMode.VIEW,
            });
            this.props.dispatch(initialize(reduxFormName, response.data));
            toastr.success(this.props.labels.patient_details.patientUpdated);
        }).catch(error => {
            this.errorHandler("Can't update patient.", error);
        })
    }

    errorHandler(message, error) {
        this.setState({
            loading: false,
        });
        if (!error.handledGlobally)
            toastr.error(constructErrorMessage(message, error));
    }

    transformValues(values) {

        if (values.primaryPhone === '') {
            values.primaryPhone = null;
        }

        if (values.secondaryPhone === '') {
            values.secondaryPhone = null;
        }

        if (this.state.thirdPartyConsentRequired) {
            if (!values.thirdPartyConsent) {
                values.thirdPartyConsent = false;
            }
        } else {
            values.thirdPartyConsent = null
        }
    }

    fetchPatient(patientId) {

        this.setState({
            loading: true,
        });

        PATIENT_SERVICE.get(patientId).then(patientResponse => {

            ORGANIZATION_RULE_SERVICE.get(patientResponse.data.treatingPhysicianId).then(response => {

                this.setState({
                    thirdPartyConsentRequired: response.data.thirdPartyConsentRequired,
                    fullPatientInformationRequired: response.data.fullPatientInformationRequired,
                    phoneRegexp: response.data.phoneRegexp,
                    patient: patientResponse.data,
                    loading: false,
                });
                this.props.dispatch(initialize(reduxFormName, patientResponse.data))
            }).catch(error => {
                this.errorHandler("Can't fetch configuration.", error);
            });
        }).catch(error => {
            this.errorHandler("Can't fetch patient.", error);
        })
    }

    editable() {
        return PermissionContainer.checkAllowedPermissions([permissions.patient_edit_all, permissions.patient_edit_clinic, permissions.patient_edit_your], this.props.user.permissions)
    }

    physicianChangeHandler(value) {
        if (!value)
            return;
        this.setState({
            loading: true,
        });


        ORGANIZATION_RULE_SERVICE.get(value).then(response => {
            this.setRuleSet(response.data);
            this.setState({
                loading: false,
            });
        }).catch(error => {
            this.errorHandler("Can't fetch configuration.", error);
        });
    }

    resetMfaForPatient() {
        this.setState({
            loading: true,
        });
        USER_SERVICE.putId(`${this.state.patientId}/reset-mfa`).then(() => {
            toastr.success("Reset mfa successful!");
        }).catch(error => {
            if (!error.handledGlobally) {
                toastr.error(this.props.labels.reset_password.error_message);
            }
        }).finally(() => {
            this.setState({loading: false});
        });
    }


    resetPassword(email) {
        RESET_PASSWORD_SERVICE.post(email).then(() => {
            toastr.success("Reset password email sent!");
        }).catch(error => {
            if (!error.handledGlobally) {
                toastr.error(this.props.labels.reset_password.error_message);
            }
        });
    }

    phoneNumberCustomValidator = value => {

        let phoneRegexp = this.state.phoneRegexp;

        const regExp = new RegExp(phoneRegexp);

        return value && !regExp.test(value) ?
            'Invalid phone number format' : undefined
    };

    render() {
        const {handleSubmit, classes, labels} = this.props;
        const patientDetailsLabels = labels.patient_details;
        const buttonLabels = labels.buttons;
        const {formMode, loading, patient, thirdPartyConsentRequired, fullPatientInformationRequired, phoneRegexp} = this.state;

        const disabled = (formMode === FormMode.VIEW || loading);

        const phoneNumberValidator = phoneRegexp ? this.phoneNumberCustomValidator : phoneNumber;
        const canResetMfa = PermissionContainer.checkAllowedPermissions([permissions.patient_edit_all,
                permissions.patient_edit_clinic],
            this.props.user.permissions);
        return (
            <React.Fragment>
                {loading &&
                <LoadingIndicator/>
                }
                <div style={{padding: 16}}>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit(this.submitFormHandler.bind(this))}
                          className={classes.container}>
                        <Grid container spacing={16} style={{height: '72vh', overflow: 'auto'}}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={"primary"} gutterBottom>
                                    {patientDetailsLabels.page_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup className={classes.container}>
                                    <TextField
                                        name={"firstName"}
                                        label={patientDetailsLabels.first_name}
                                        className={classes.inputField}
                                        disabled={disabled}
                                        validate={fullPatientInformationRequired ? [required] : []}
                                        required={fullPatientInformationRequired}
                                    />
                                    <TextField
                                        name={"lastName"}
                                        label={patientDetailsLabels.last_name}
                                        className={classes.inputField}
                                        disabled={disabled}
                                        validate={fullPatientInformationRequired ? [required] : []}
                                        required={fullPatientInformationRequired}
                                    />
                                    <TextField
                                        name={"email"}
                                        label={patientDetailsLabels.email}
                                        className={classes.inputField}
                                        validate={(this.props.isHomeTreated || fullPatientInformationRequired) ? [required, email] : [email]}
                                        required={this.props.isHomeTreated || fullPatientInformationRequired}
                                        disabled={disabled}
                                    />
                                    <CountrySelectField name={"countryCode"}
                                                        className={classes.inputField}
                                                        disabled={disabled}
                                                        validate={fullPatientInformationRequired ? [required] : []}
                                                        required={fullPatientInformationRequired}
                                    />
                                    <TextField
                                        name={"addressLine1"}
                                        label={patientDetailsLabels.address1}
                                        className={classes.inputField}
                                        disabled={disabled}
                                        validate={fullPatientInformationRequired ? [required] : []}
                                        required={fullPatientInformationRequired}
                                    />
                                    <TextField
                                        name={"addressLine2"}
                                        label={patientDetailsLabels.address2}
                                        className={classes.inputField}
                                        disabled={disabled}
                                    />
                                    <div className={classes.formRow}>
                                        <TextField
                                            name={"postalCode"}
                                            label={patientDetailsLabels.postcode}
                                            className={classes.postcode}
                                            disabled={disabled}
                                            validate={fullPatientInformationRequired ? [required] : []}
                                            required={fullPatientInformationRequired}
                                        />
                                        <TextField
                                            name={"city"}
                                            label={patientDetailsLabels.city}
                                            className={classes.city}
                                            disabled={disabled}
                                            validate={fullPatientInformationRequired ? [required] : []}
                                            required={fullPatientInformationRequired}
                                        />
                                    </div>

                                    <TextField
                                        name={"primaryPhone"}
                                        label={patientDetailsLabels.phone1}
                                        className={classes.inputField}
                                        disabled={disabled}
                                        validate={fullPatientInformationRequired ? [required, phoneNumberValidator] : [phoneNumberValidator]}
                                        required={fullPatientInformationRequired}
                                    />
                                    <TextField
                                        name={"secondaryPhone"}
                                        label={patientDetailsLabels.phone2}
                                        className={classes.inputField}
                                        validate={[phoneNumberValidator]}
                                        disabled={disabled}

                                    />
                                    <TextField
                                        name={"externalId"}
                                        label={patientDetailsLabels.patient_id}
                                        className={classes.inputField}
                                        required
                                        validate={[required]}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup>
                                    <SelectField
                                        name={"sex"}
                                        label={patientDetailsLabels.sex}
                                        className={classes.inputField}
                                        optionmap={{
                                            'MALE': patientDetailsLabels.sex_male,
                                            "FEMALE": patientDetailsLabels.sex_female
                                        }}
                                        disabled={disabled}
                                        validate={fullPatientInformationRequired ? [required] : []}
                                        required={fullPatientInformationRequired}
                                    />
                                    <TextField
                                        name={"birthDate"}
                                        label={patientDetailsLabels.date_of_birth}
                                        className={classes.inputField}
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={disabled}
                                        validate={fullPatientInformationRequired ? [required] : []}
                                        required={fullPatientInformationRequired}
                                    />

                                    <PermissionContainer
                                        allowedPermissions={[permissions.patient_add_all, permissions.patient_edit_all, permissions.patient_view_all]}>
                                        <AdminPhysiciansSelectField name={"treatingPhysicianId"}
                                                                    className={classes.inputField}
                                                                    required
                                                                    validate={[required]}
                                                                    disabled={disabled}
                                                                    criteria={this.getCriteria()}
                                        />

                                    </PermissionContainer>
                                    <PermissionContainer
                                        allowedPermissions={[permissions.patient_add_clinic,
                                            permissions.patient_edit_clinic,
                                            permissions.patient_view_clinic]}>
                                        <PhysiciansSelectField name={"treatingPhysicianId"}
                                                               className={classes.inputField}
                                                               required
                                                               validate={[required]}
                                                               disabled={disabled}
                                                               criteria={this.getCriteria()}
                                        />

                                    </PermissionContainer>

                                    <div className={classes.formRow}>
                                        <TextField
                                            name={"moderateThreshold"}
                                            label={patientDetailsLabels.moderate_threshold}
                                            className={classes.moderateThreshold}
                                            validate={fullPatientInformationRequired ? [required, number] : [number]}
                                            disabled={disabled}
                                            required={fullPatientInformationRequired}
                                        />
                                        <TextField
                                            name={"highThreshold"}
                                            label={patientDetailsLabels.high_threshold}
                                            className={classes.highThreshold}
                                            validate={fullPatientInformationRequired ? [required, number] : [number]}
                                            disabled={disabled}
                                            required={fullPatientInformationRequired}
                                        />
                                    </div>
                                  <div className={classes.formRow} style={{ marginTop : 10 }}>
                                    <Typography color={'textSecondary'} variant={"body1"}>
                                      {patientDetailsLabels.test_frequency_every}
                                    </Typography>
                                    <Tooltip title={patientDetailsLabels.help_message}>
                                      <IconButton aria-label={labels.help_message}
                                                  style={{ padding : 0, marginLeft : 12 }}>
                                        <HelpIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div className={classes.formRow} style={{ marginTop : 5 }}>
                                        <TextField
                                          name={"frequency"}
                                          label={patientDetailsLabels.frequency}
                                          className={classes.frequency}
                                          required
                                          validate={[required, number]}
                                          disabled={disabled}
                                        />
                                      <FrequencySelectField name={"period"} className={classes.frequencyType} required
                                                            validate={[required]}
                                                            disabled={disabled} />

                                    </div>
                                    <LanguageSelectField name={"languageCode"}
                                                         required
                                                         validate={[required]}
                                                         className={classes.inputField}
                                                         disabled={disabled}
                                    />

                                    <PatientTypeSelectField name={"treatmentType"}
                                                            className={classes.inputField}
                                                            required
                                                            validate={[required]}
                                                            disabled={disabled}
                                    />
                                    {thirdPartyConsentRequired &&
                                    <CheckBoxField name={"thirdPartyConsent"}
                                                   label={patientDetailsLabels.thirdPartyConsent}
                                                   className={classes.inputField}
                                                   disabled={disabled}
                                                   required
                                                   validate={[required]}
                                    />
                                    }
                                    <CheckBoxField name="active"
                                                   label={patientDetailsLabels.is_active}
                                                   className={classes.inputField}
                                                   disabled={disabled}
                                    />
                                    {formMode !== FormMode.CREATE &&
                                    <CheckBoxField name="useMfa"
                                                   label={patientDetailsLabels.mfa}
                                                   className={classes.inputField}
                                                   disabled={true}
                                    />
                                    }

                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="flex-end" spacing={8} style={{marginTop: 10}}>
                            {(patient !== null && patient.treatmentType === 'HOME') &&
                            <Button onClick={() => this.resetPassword(patient.email)}
                                    variant={"outlined"}
                                    color={"primary"}
                                    className={classes.buttonMargin}
                                    disabled={loading}
                            >
                                {buttonLabels.reset_password}
                            </Button>}

                            {canResetMfa && formMode !== FormMode.CREATE &&
                            <Button onClick={() => this.resetMfaForPatient()}
                                    variant={"outlined"}
                                    color={"secondary"}
                                    className={classes.buttonMargin}
                                    disabled={loading}>
                                {buttonLabels.reset_mfa}
                            </Button>
                            }

                            <FormButtons formMode={formMode}
                                         onCloseButtonClick={this.closeButtonClickHandler}
                                         onCancelButtonClick={this.cancelButtonClickHandler}
                                         onEditButtonClick={this.editButtonClickHandler}
                                         actionButtonDisabled={loading}
                                         editModeEnabled={this.editable()}
                            />
                        </Grid>
                    </form>
                </div>
            </React.Fragment>
        );
    }

    getCriteria = () => {
        return [
            {'identities.identityRoles.role.name': 'CLINIC_LAB'},
            {'identities.identityRoles.role.name': 'CLINIC_ADMINISTRATOR'},
            {'identities.identityRoles.role.name': 'CLINIC_USER'},
        ];
    }
}


PatientDetails.propTypes = {
    patientId: PropTypes.number,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
};


const mapTranslationsToProps = ({labels}) => ({labels});


const selector = formValueSelector(reduxFormName);


function mapStateToProps(state) {

    return {
        user: state.auth.user,
        isHomeTreated: selector(state, 'treatmentType') === 'HOME',
        treatingPhysicianId: selector(state, 'treatingPhysicianId')
    }

}

export default compose(
    connect(mapStateToProps, null),
    translatable(mapTranslationsToProps),
    withStyles(styles),
    reduxForm({
        form: reduxFormName,
        initialValues: {
            active: true,
            moderateThreshold: 200,
            highThreshold: 500
        }
    })
)(PatientDetails);