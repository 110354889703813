import React from 'react';
import { compose } from 'recompose';
import { translatable } from 'react-multilingual';
import { Close } from '@material-ui/icons';
import { SubmissionError } from 'redux-form';
import toastr from "toastr";
import { Dialog, IconButton, Typography, withStyles } from '@material-ui/core';
import { ADMIN_FAQ_QUESTIONS_SERVICE } from '../common/services/availableServices';
import FAQForm from './FAQForm';
import LoadingIndicator from '../common/components/LoadingIndicator';
import { languages } from './utils';

const styles = (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: theme.spacing.unit * 2,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
  dialogPaperClass: {
    padding: 0,
    width: '90%',
    height: '100%',
    maxHeight: 526,
    overflow: 'hidden',
  }
});

const createEmptyFaqItem = (languageCode) => ({
    answer: {
      text: '',
      photoUrl: '',
      videoUrl: '',
    },
    question: {
      text: '',
    },
    languageCode,
});

const createEmptyFaq = () => languages.map(language => createEmptyFaqItem(language));

const useFaq = (id, { enabled, initialData }) => {
  const [faq, setFaq] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const handleFetch = React.useCallback((id) => {
    if (id < 0 || initialData) {
      setLoading(false);
      setFaq(initialData || createEmptyFaq());
    } else {
      setLoading(true);
      ADMIN_FAQ_QUESTIONS_SERVICE.get(id)
        .then((response) => {
          const withBase64Promises = response.data.map(item => {
            if (item.answer.photoUrl) {
              return new Promise((resolve) => {
                const image = new Image();
                image.setAttribute('crossorigin', 'anonymous');
                image.onload = () => {
                  const canvas = document.createElement('CANVAS');
                  const ctx = canvas.getContext('2d');
                  canvas.height = image.height;
                  canvas.width = image.width;
                  ctx.drawImage(image, 0, 0);
                  const dataURL = canvas.toDataURL();
                  resolve({
                    ...item,
                    answer: {
                      ...item.answer,
                      base64EncodedPhoto: dataURL,
                    },
                  });
                }
                image.onerror = () => {
                  resolve(item);
                }
                image.src = item.answer.photoUrl;
              })
            }
            return Promise.resolve(item);
          });
          return Promise.all(withBase64Promises).then(setFaq);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [initialData]);
  
  React.useEffect(() => {
    if (enabled) {
      handleFetch(id);
    }
    return () => {
      setFaq(null);
    }
  }, [id, enabled]);

  return {
    data: faq,
    loading,
    refetch: () => handleFetch(id),
  };
};

const filterEmptyTranslations = (values) => {
  const questionsWithoutAnswers = [];
  const filteredTranslations = Object.entries(values).reduce((acc, translationEntry) => {
    const languageCode = translationEntry[0];
    const translation = translationEntry[1];
    if (translation.question.text && !translation.answer.text) {
      questionsWithoutAnswers.push({ translation, languageCode });
      return acc;
    }
    if (translation.question.text === '' && translation.answer.text === '') {
      return acc;
    }
    return {
      ...acc,
      [languageCode]: translation,
    };
  }, {});

  return { filteredTranslations, questionsWithoutAnswers };
}

const AddOrEditFAQ = ({ id, open, onClose, onSave, classes, labels, initialData }) => {
  const { data: faq, loading } = useFaq(id, { enabled: open && typeof id !== 'undefined', initialData });
  const mode = id === -1 ? 'add' : 'edit';

  const handleSubmit = React.useCallback((values) => {
    const { filteredTranslations, questionsWithoutAnswers } = filterEmptyTranslations(values);
    if (!filteredTranslations['EN']) {
      toastr.error(labels.required_translation);
      throw new SubmissionError({
        EN: labels.required_translation,
      })
    }
    if (questionsWithoutAnswers.length) {
      toastr.error(labels.lack_of_answers);
    }
    if (Object.entries(filteredTranslations).length === 0) {
      onClose();
    } else {
      onSave({ id, values: filteredTranslations });
    }
  }, [id, onClose, onSave]);

  return (
    <Dialog
      maxWidth="md"
      classes={{ paper: classes.dialogPaperClass }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h5" color="primary">
            {id < 0 ? labels.add_title : labels.edit_title}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          {loading && <LoadingIndicator />}
          {faq && <FAQForm faq={faq} mode={mode} id={id} onSubmit={handleSubmit} />}
        </div>
      </div>
    </Dialog>
  )
};

export default compose(
  translatable(({ labels }) => ({ labels: labels.add_edit_faq })),
  withStyles(styles)
)(AddOrEditFAQ);