export default {
    labels: {
        log: {
            login: 'S\'identifier',
            log_out: 'Se déconnecter',
        },
        landing_page: {
            login: 'S\'identifier',
            register_clinic: 'Enregistrer une nouvelle clinique',
            forget_password: 'Mot de passe oublié ?',
            see_api_docs: 'Voir la documentation de l\'API',

        },
        required: 'Obligatoire',
        delete_user: 'Supprimer l\'utilisateur',
        employee: 'Employé',
        delete_success: 'Supprimé avec succès',
        empty_result: 'Aucun résultat pour les critères de recherche sélectionnés',
        page_not_found: 'Page introuvable',
        welcome_message: 'Bienvenue sur le portail CalproSmart',
        roles: {
            ADMINISTRATOR: 'ADMINISTRATEUR',
            API_USER: 'UTILISATEUR API',
            CLINIC_ADMINISTRATOR: 'ADMINISTRATRICE DE LA CLINIQUE',
            CLINIC_LAB: 'LABORATOIRE CLINIQUE',
            CLINIC_USER: 'UTILISATEUR DE LA CLINIQUE',
            CLINIC_USER_FULL_INSIGHT: 'UTILISATEUR DE LA CLINIQUE - FULL INSIGHT',
            ORGANIZATION_ADMINISTRATOR: 'ADMINISTRATEUR DE L\'ORGANISATION',
            QUALITY_CONTROL: 'CONTRÔLE QUALITÉ',
        },
        select_field: {
            language: 'Langue',
            clinic: 'Clinique',
            country: 'Pays',
            physician: 'Médecin traitant',
            frequency_type: 'Type de fréquence',
            organization: 'Organisation',
            no_option: 'Non trouvé',
        },
        buttons: {
            create: 'Créer',
            cancel: 'Annuler',
            next: 'Suivant',
            back: 'Précédent',
            finish: 'Finir',
            home: 'Retour à la page d\'accueil',
            search: 'Rechercher',
            edit: 'Éditer',
            export_results: 'Exporter les résultats vers Excel',
            export_filtered_results: 'Exporter les résultats filtrés vers Excel',
            export_filtered_patients: 'Exporter les patients filtrés vers Excel',
            details: 'Détails',
            update: 'Mettre à jour',
            delete: 'Supprimer',
            close: 'Fermer',
            send: 'Envoyer',
            save: 'Sauvegarder',
            accept: 'Accepter',
            reject: 'Rejeter',
            reset_password: 'Réinitialiser le mot de passe',
            confirm: 'Confirmer',
            reset_mfa: 'Réinitialiser l\'authentification multifacteur pour l\'utilisateur',
            export_all_results_to_pdf: 'Exporter tous les résultats au format PDF',
            export_this_result_to_pdf: 'Exporter ce résultat au format PDF',
        },
        fields: {
            search: 'Rechercher',
            clinic: 'Clinique',
            status: 'Statut',
            role: 'Rôle',
            language: 'Langue',
        },
        table: {
            empty_result: 'Aucun résultat pour les critères de recherche sélectionnés',
            error_message: 'Une erreur s\'est produite. Pouvoir',
        },
        statuses: {
            active: 'Actif',
            inactive: 'Inactif',
            created: 'Créé',
        },
        menu_labels: {
            clinics: 'Cliniques',
            organizations: 'Organisations',
            staff_users: 'Personnel soignant',
            patients: 'Patients',
            manage_patients: 'Gérer les patients',
            results: 'Résultats',
            pending_results: 'Résultats en attente',
            batches: 'Lots',
            documents: 'Documents',
            faq: 'Gestion des FAQ',
            support: 'Obtenir de l\'aide',
        },
        reset_password: {
            title: 'Réinitialiser votre mot de passe',
            message: 'Mot de passe oublié ? Entrez votre adresse e-mail ci-dessous, nous vous enverrons par e-mail les instructions pour définir un nouveau mot de passe :',
            success_title: 'Succès',
            error_message: 'Nous rencontrons une erreur de serveur interne. Veuillez contacter l\'administrateur.',
            success: 'Si l\'adresse e-mail que vous avez fournie est correcte, le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail.\nVeuillez vérifier votre boîte de réception ainsi que vos SPAM.',
        },
        managePatients_labels: {
            create_patient_button: 'Créer un patient',
            page_title: 'Gérer les patients',
            patient_type: 'Type de patient',
            patient_types: {
                home: 'Suivi à domicile des patients',
                lab: 'Patient en laboratoire',
            },
            columns: {
                id: 'Identifiant Calpro',
                externalId: 'Identifiant',
                email: 'Email',
                firstName: 'Prénom',
                lastName: 'Nom',
                treatmentType: 'Type de patient',
                created: 'Créé',
                active: 'Statut',
                clinicName: 'Clinique',
                details: '',
            },
        },
        manageClinics_labels: {
            create_clinic_button: 'Créer une clinique',
            page_title: 'Gérer les cliniques',
            columns: {
                name: 'Nom',
                city: 'Ville',
                countryCode: 'Pays',
                status: 'Statut',
                organizationName: 'Organisation',
                created: 'Créé',
                details: '',
            },
        },
        manageOrganizations_labels: {
            create_organization_button: 'Créer une organisation',
            page_title: 'Gérer des organisations',
            columns: {
                name: 'Nom',
                moderateThreshold: 'Seuil modéré',
                severeThreshold: 'Seuil sévère',
                active: 'Statut',
            },
        },
        manageBatches_labels: {
            create_batch_button: 'Créer un lot',
            page_title: 'Gérer les lots',
            releaseStatus: 'Statut de la libération',
            columns: {
                number: 'N° de lot',
                expirationDate: 'Échéance',
                creationDate: 'Créé',
                status: 'Statut de la libération',
                details: '',
            },
        },
        manageResults_labels: {
            page_title: 'Gérer les résultats',
            classification: 'Classification',
            result_type: 'Type de résultat',
            pendingResultLabel: 'Résultats en attente',
            pendingResultTooltip: '"Accepté" est un résultat en attente accepté "Rejeté" est un résultat en attente rejeté',
            pendingResultTypes: {
                accepted: 'Accepté',
                rejected: 'Rejeté',
            },
            result_types: {
                home: 'Domicile',
                lab: 'Laboratoire',
            },
            columns: {
                date: 'Date',
                externalPatientId: 'Identifiant du patient',
                patientInfo: 'Patient',
                clinicName: 'Clinique',
                batchNumber: 'Lot',
                finalConcentration: 'Concentration',
                overallResult: 'Classification',
                archived: 'Archivés',
                labResult: 'Type de résultat',
                details: '',
            },
        },
        managePendingResults_labels: {
            page_title: 'Gérer les résultats en attente',
            classification: 'Classification',
            columns: {
                date: 'Date',
                externalPatientId: 'Identifiant du patient',
                patientInfo: 'Patient',
                runNo: 'Test numéro',
                finalConcentration: 'Concentration',
                overallResult: 'Classification',
                actions: 'Actions',
            },
        },
        signup_labels: {
            user_tab: 'Informations personnelles',
            clinic_tab: 'Renseignements sur la clinique',
            summary_tab: 'Résumé',
            first_name: 'Prénom',
            last_name: 'Nom',
            email: 'Email',
            password: 'Mot de passe',
            confirm_password: 'Confirmer le mot de passe',
            company: 'Société',
            address1: 'Adresse ligne 1',
            address2: 'Adresse ligne 2',
            city: 'Ville',
            postcode: 'Code postal',
            country: 'Pays',
            contact_name: 'Nom',
            contact_email: 'Adresse e-mail de contact',
            contact_tel: 'Numéro de téléphone',
            captcha: 'Captcha',
            provide_contact_info: 'Personne de contact pour la clinique',
            provide_contact_info_subtitle: 'Cette personne agira à titre d\'administrateur de la clinique.',
            provide_clinic_info: 'Fournir des informations sur la clinique',
            confirm_info: 'Vérifiez que les informations fournies sont correctes',
            done: 'Merci d\'avoir enregistré votre clinique. Un administrateur Calpro examinera votre inscription et activera votre compte et votre clinique dans les 24 heures. Vous recevrez un email lorsque votre compte et votre clinique seront prêts à être utilisés.',
            error_message: 'Il y a eu un problème lors de la création de votre compte - veuillez contacter support@calpro.no ',
        },
        patient_details: {
            page_title: 'Gérer le patient',
            first_name: 'Prénom',
            last_name: 'Nom',
            address1: 'Adresse ligne 1',
            address2: 'Adresse ligne 2',
            postcode: 'Code postal',
            city: 'Ville',
            country: 'Pays',
            email: 'Adresse email',
            phone1: 'Téléphone principal',
            phone2: 'Téléphone secondaire',
            patient_id: 'Patient ID',
            sex: 'Sexe',
            date_of_birth: 'Date de naissance',
            clinic: 'Clinique',
            physician: 'Médecin traitant',
            frequency: 'Fréquence',
            frequency_type: 'Type de fréquence',
            language: 'Langue',
            home_monitoring: 'Patient actif pour le suivi à domicile',
            interval_day: 'jour(s)',
            interval_week: 'semaine(s)',
            interval_month: 'mois',
            interval_year: 'année(s)',
            moderate_threshold: 'Seuil modéré',
            high_threshold: 'Seuil élevé',
            sex_male: 'Masculin',
            sex_female: 'Féminin',
            thirdPartyConsent: 'Consentement au partage des données par des tiers',
            is_active: 'Est actif',
            patientCreated: 'Patient créé',
            patientUpdated: 'Patient mis à jour',
            mfa: 'Authentification multifacteur (MFA)',
            test_frequency_every: 'Fréquence des tests tous les :',
            help_message: 'Indique la fréquence à laquelle le patient doit effectuer un test',
        },
        staff_users: {
            page_title: 'Gérer le personnel soignant',
            id: 'Identifiant Calpro',
            email: 'Email',
            firstName: 'Prénom',
            lastName: 'Nom',
            active: 'Actif',
            edit: 'Éditer',
            details: 'Détails',
            primaryPhone: 'Téléphone principal',
            secondaryPhone: 'Téléphone secondaire',
            roles: 'Rôle',
            clinicName: 'Clinique',
            create: 'Créer un personnel soignant',
        },
        user_details: {
            page_title: 'Créer un personnel soignant',
            first_name: 'Prénom',
            last_name: 'Nom',
            address1: 'Adresse ligne  1',
            address2: 'Adresse ligne  2',
            postcode: 'Code postal',
            city: 'Ville',
            email: 'Email',
            phone1: 'Téléphone principal',
            phone2: 'Téléphone secondaire',
            language: 'Langue',
            clinic: 'Clinique',
            is_active: 'Est actif',
            calpro_admin: 'Calpro_Admin',
            calpro_qc: 'IsCalpro_Qc',
            clinic_admin: 'Clinic_Admin',
            clinic_lab: 'Clinic_Lab',
            clinic_user: 'Clinic_User',
            clinic_user_all_results: 'Clinic_User_All_Results',
            roles: 'Rôles',
            no_roles: 'Aucun rôle n\'est sélectionné, veuillez sélectionner au moins un rôle',
            success: 'Utilisateur créé avec succès',
            employeeCreated: 'Employé créé',
            employeeUpdated: 'Employé mis à jour',
            warning_title: 'Avertissement',
            deactivation_warning: 'Vous avez désactivé un personnel soignant. S\'il avait des patients, n\'oubliez pas de les réaffecter !',
            mfa: 'Authentification multifacteur (MFA)',
        },
        clinic_details: {
            page_title: 'Créer ou modifier une clinique',
            name: 'Nom de la clinique',
            address1: 'Adresse ligne  1',
            address2: 'Adresse ligne  2',
            postcode: 'Code postal',
            city: 'Ville',
            contactName: 'Nom du contact',
            contactTel: 'Téléphone du contact',
            contactEmail: 'Adresse e-mail du contact',
            status: 'Statut',
            update_ok: 'Clinique mise à jour avec succès',
            clinicCreated: 'Félicitations, vous avez réussi à créer une nouvelle clinique ! Accédez à l\'onglet Gérer les utilisateurs pour y attribuer un nouvel administrateur.',
            clinicUpdated: 'Mise à jour de la clinique',
            mfa_patient: 'Authentification multifactorielle du patient (MFA)',
            mfa_staff: 'Authentification multifactorielle (MFA) de l\'utilisateur',
        },
        organization_details: {
            page_title: 'Détails de l\'organisation',
            name: 'Nom',
            logo: 'Logo',
            moderateThreshold: 'Seuil modéré',
            severeThreshold: 'Seuil sévère',
            update_success: 'Organisation mise à jour avec succès',
            create_success: 'Organisation créée avec succès',
            logo_placeholder: 'Insérez votre logo ici',
            thirdPartyConsentRequired: 'Exige que les patients consentent au partage des données par des tiers',
            fullPatientInformationRequired: 'Nécessite des informations complètes sur le patient (nom, adresse, etc.)',
            phoneRegexp: 'Numéro de téléphone',
            organizationCreated: 'Organisation créée',
            organizationUpdated: 'Organisation mise à jour ',
            active: 'Actif',
            deactivate_organization: {
                title: 'Désactiver la clinique',
                message:"En appuyant sur 'Confirmer', vous désactivez toutes les cliniques, utilisateurs et patients affiliés à cette organisation. Si vous souhaitez les garder actifs, vous devez affilier ces cliniques à une organisation active avant de désactiver cette organisation spécifique.",
                cancel: 'Annuler',
                confirm: 'Confirmer',
            }
        },
        result_basic_view: {
            concentration: 'Concentration',
            classification:
                'Classification',
            patient:
                'Patient',
            patientId:
                'Identifiant du patient',
            date:
                'Date',
        },
        pending_result_basic_view: {
            concentration: 'Concentration',
            classification:
                'Classification',
            patient:
                'Patient',
            patientId:
                'Identifiant du patient',
            date:
                'Date',
            searchPatientPlaceholder:
                'Rechercher un patient par nom, identifiant ou adresse e-mail',
            patientReassignmentSuccess:
                'Le patient a été réaffecté',
            patientReassignmentFail:
                'Échec de la réaffectation du patient',
        },
        detailed_result_view: {
            phoneBrand: 'Marque du téléphone',
            phoneModel: 'Modèle du téléphone',
            phoneName: 'Nom du téléphone',
            appVersion: 'Version de l\'application',
            readerVersion: 'Version du lecteur',
            batchNumber: 'Numéro de lot',
            batchExpDate: 'Date d\'expéritation du lot',
            cScore: 'C Score',
            tScore: 'T Score',
            tcRatio: 'Rapport T/C',
            cPeakPosition: 'Position du pic C',
            tPeakPosition: 'Position du pic T',
            cThresh: 'Seuil C',
            notes: 'Notes',
            image: 'Image',
            stripImage: 'Bande d\'image',
            updateResultNotes: 'Mettre à jour les notes de résultats',
            notesCharacterLimit: 'Limite de 255 caractères',
            notesUpdatedSuccessfully: 'Notes mises à jour avec succès',
            notesUpdateFail: 'Échec de la mise à jour des notes',
        },
        result_view: {
            result: 'Résultat',
            result_details: 'Détails du résultat',
        },
        create_batch: {
            create_batch: 'Créer un lot',
            batch_details: 'Détails du lot',
            batch_number: 'Numéro de lot',
            manufacture_date: 'Date de fabrication',
            expiry_date: 'Expiration',
            moderate_threshold: 'Seuil modéré',
            severe_threshold: 'Seuil sévère',
            created_by: 'Créé par',
            release_status: 'Statut de la libération',
            barcode: 'Code-barres',
            calibration_equation: 'Équation des paramètres d\'étalonnage',
            calibration_parameter_a: 'Paramètre d\'étalonnage A',
            calibration_parameter_b: 'Paramètre d\'étalonnage B',
            calibration_parameter_c: 'Paramètre d\'étalonnage C',
            qc_review: 'Examen du contrôle qualité',
            qc_approved: 'Approuvé par le contrôle qualité',
            released: 'Libéré',
            reject_batch: 'Rejeter le lot',
            reject_batch_line1: 'Cela rejettera le lot et alertera Calpro QC.',
            reject_batch_line2: 'Avant de rejeter ce lot, vous devez ajouter une note pour indiquer la raison.',
            reject_batch_line3: 'Si vous ne souhaitez pas continuer, cliquez sur \'Annuler\' ou cliquez sur \'Confirmer\' pour refuser le lot.',
            reason_label: 'Raison du rejet',
            reject: 'Rejeter',
            batch_created: 'Lot créé',
            batch_updated: 'Lot mis à jour',
            batch_failed: 'Échec de l\'opération sur le lot',
        },
        batchResults_labels: {
            sampling_page_title: 'Liste des résultats de l\'échantillonnage du lot',
            review_page_title: 'Liste des résultats de l\'examen du lot',
            columns: {
                resultId: 'ID',
                date: 'Date',
                sampleId: 'ID de l\'échantillon',
                runNo: 'Série No',
                tpeakPosition: 'Position du pic T',
                cpeakPosition: 'Position du pic C',
                finalScore: 'Score final',
                tscore: 'Score T',
                cscore: 'Score C',
                tcRatio: 'Rapport TC',
                finalConcentration: 'Concentration',
                overallResult: 'Classification',
                details: 'Détails',
            }
        },
        myPage_labels: {
            page_title: 'Ma page',
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Email',
            clinicName: 'Clinique',
            roles: 'Rôles',
            view_agreements: 'Afficher les autorisations que vous avez signés',
            apiVersion: 'Version de l\'API',
            releaseNotes: 'Notes de mise à jour',
        },
        myAgreements_labels: {
            title: 'Mes autorisations',
            version: 'Version',
        },
        active: {
            active: 'Actif',
            inactive: 'Inactif',
        },
        documents_section: {
            documents: {
                publish: "Publier version",
                cannot_publish: "Toute traduction doit être terminée",
            },
            versions: {
                title: 'Versions',
                add_tooltip: "Ajouter une nouvelle version",
                publish: 'Publier',
                release_date: 'Date de libération',
                creation_date: 'Date de création',
            },
            translations: {
                title: 'Traductions',
                add_tooltip: 'Ajouter une nouvelle traduction',
            },
            document_representation: {
                edit: 'Modifier le document',
            },
            edit_document_representation: {
                finish: 'Fini',
                in_progress: 'Enregistrer en tant que brouillon',
                cancel: 'Annuler',
            },
            add_version: {
                title: 'Ajouter une nouvelle version',
                sub_label: 'Nom de la version',
                save: 'Sauvegarder',
                cancel: 'Annuler',
                success: 'Ajout réussi d\'une nouvelle version',
            },
            add_translation: {
                title: 'Ajouter une nouvelle traduction',
                sub_label: 'Nom de langue',
                save: 'Sauvegarder',
                cancel: 'Annuler',
                success: 'Ajout réussi d\'une nouvelle traduction',
            },
            tooltips: {
                none: 'Le même que dans la version précédente',
                finish: 'Traduction terminée, prête à être publiée',
                in_progress: 'La traduction a été mise à jour, mais n\'est pas prête à être publiée',
            },
            publish: {
                title: 'Publier une nouvelle version du document',
                notify: 'Informer tous les utilisateurs de la nouvelle version ?',
                save: 'Sauvegarder',
                cancel: 'Annuler',
            },
        },
        support: {
            page_title: 'Obtenir de l\'aide',
            faq: 'FAQ',
            email: 'Assistance par e-mail',
        },
        support_faq: {
            page_title: 'FAQ',
        },
        faq: {
            page_title: 'Gestion des FAQ',
            edit: 'Éditer',
            publish: 'Publier',
            addNew: 'Ajouter un nouveau',
            selectOptions: {
                PATIENT: 'Patient',
                EMPLOYEE: 'Employé',
            },
        },
        faq_item: {
            show_more: 'Afficher plus',
        },
        add_edit_faq: {
            required_translation: 'Une traduction en anglais est requise',
            lack_of_answers: 'Certaines questions ne seront pas sauvegardées en raison d\'un manque de réponses',
            add_title: 'Ajouter une nouvelle question et une nouvelle réponse',
            edit_title: 'Modifier la question et la réponse',
            save: 'Sauvegarder',
            delete: 'Supprimer',
            question: {
                placeholder: 'Tapez la question...',
            },
            answer: {
                placeholder: 'Tapez la réponse...',
            },
            photo: {
                add: 'Ajouter une photo',
                added_suffix: 'a été ajouté en tant que pièce jointe.',
            },
            video: {
                label: 'Lien vers la vidéo (facultatif) :',
                placeholder: 'Coller un lien vers la vidéo',
            },
            language: {
                EN: 'Anglais',
                AR: 'Arabe',
                CA: 'Catalan',
                CS: 'Tchèque',
                DE: 'Allemand',
                EL: 'Grec',
                ES: 'Espagnol',
                FI: 'Finnois',
                HE: 'Hébreu',
                HU: 'Hongrois',
                IS: 'Islandais',
                IT: 'Italien',
                KO: 'Coréen',
                NL: 'Néerlandais',
                SK: 'Slovaque',
                SV: 'Suédois',
                NB: 'Norvégien',
                DA: 'Danois',
                PL: 'Polonais',
                FR: 'Français',
                PT: 'Portugais',
                SL: 'Slovène',
            },
        },
    },
}
